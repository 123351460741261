import { ref } from 'vue';
import axios from 'axios';
const getVehicleBodyStyle = () => {
    var vehicleBodyStyle = ref([]);
    const BodyStyleError = ref('');
    try {
        const loadVehicleBodyStyle = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleBodyStyle",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleBodyStyle.value = response.data;
                })
                .catch((BodyStyleError) => {
                    BodyStyleError.value = 'No Vehicle Body Style found';
                });
        }
        return { vehicleBodyStyle, BodyStyleError, loadVehicleBodyStyle };
    } catch (BodyStyleError) {
        BodyStyleError.value = 'No Vehicle Body Style found';
    }
}
export default getVehicleBodyStyle;