<template>
  <Dashboard>
    <!-- inventory-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input type="text" class="reportrange form-control" />
              <img
                class="img-fluid search-icon"
                src="@/assets/img/calender.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- inventory-header -->

    <div class="google-graph">
      <div class="row">
        <div class="col-md-12">
          <div class="mygraph">
            <h4>Sessions</h4>
            <apexchart
              ref="myChart"
              width="100%"
              height="350"
              type="area"
              :options="campaignChartOptions"
              :series="campaignSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <!-- Analytics-table -->
    <div class="table-area hide-select-search">
      <table class="display" id="googleadstable">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th>Status</th>
            <th>Impressions</th>
            <th>Interactions</th>
            <th>Interaction Rate</th>
            <th>Cost</th>
            <th>Phone Calls</th>
            <th>Clicks</th>
            <th>Conv. rate</th>
            <th>Conv. value</th>
            <th>Conv. value / cost</th>
            <th>Conversions</th>
            <th>Cost / conv.</th>
            <th class="all" style="width: 100px">action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="Campaign in allCampaigns" :key="Campaign">
            <td>{{ Campaign.name }}</td>
            <td>{{ Campaign.status }}</td>
            <td>{{ Campaign.impressions }}</td>
            <td>{{ Campaign.interactions }}</td>
            <td>{{ ctrPercentChange(Campaign.interactions, Campaign.impressions) }}</td>
            <td>{{ costMicrosCalculation(Campaign.cost_micros) }}</td>
            <td>{{ Campaign.phone_calls }}</td>
            <td>{{ Campaign.clicks }}</td>
            <td>{{ ctrPercentChange(Campaign.conversions_value, Campaign.interactions) }}</td>
            <td>{{ toFixedConverter(Campaign.conversions_value) }}</td>
            <td>{{ convByCost(Campaign.conversions_value, Campaign.cost_micros) }}</td>
            <td>{{ toFixedConverter(Campaign.conversions) }}</td>
            <td>{{ costByConv(Campaign.cost_micros, Campaign.conversions_value) }}</td>
            <td>
              <router-link
                class="cus-btn"
                :to="{ name: 'GoogleAdgroups', params: { id: Campaign.id } }"
                >Ad Groups</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import moment from "moment";
import "daterangepicker";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
import getDealerOtherInfo from "../../../composeables/getDealerOtherInfo";
import axios from "axios";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let allCampaigns = ref([]);
    let campaignSeries = ref([]);
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    const cb = (start, end) => {
      startDate.value = start;
      endDate.value = end;
      if (
        dealerInfo.value.google_ads_account_id != "" &&
        typeof dealerInfo.value.google_ads_account_id != "undefined"
      ) {
        getGoogleCampaigns();
      }
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const ctrPercentChange = (clicks, impressions) => {
      return ((clicks / impressions) * 100).toFixed(2) + "%";
    };

    const convByCost = (coversions, cost) => {
      cost = (cost / 1000000);
      return ((coversions / cost)).toFixed(2);
    };

    const toFixedConverter = (value) => {
      return value.toFixed(2);
    };

    const costByConv = (cost, coversions) => {
      cost = (cost / 1000000);
      return "$" + ((cost / coversions)).toFixed(2);
    };
    
    const costMicrosCalculation = (amount) => {
      return "$" + (amount / 1000000).toFixed(2);
    };

    let campaignChartOptions = ref({});

    // Get Dealer Other Info:
    const { dealer, dealerInfo, dealerInfoError, loadDealerInfo } =
      getDealerOtherInfo();
    loadDealerInfo().then(() => {
      if (
        dealerInfo.value.google_ads_account_id != "" &&
        dealerInfo.value.google_ads_account_id != "undefined"
      ) {
        getGoogleCampaigns();
      }
    });
    const getGoogleCampaigns = async () => {
      await axios
        .get(
          axios.defaults.baseUrl +
            "getGoogleCampaigns/" +
            dealerInfo.value.google_ads_account_id +
            "/api/" +
            startDate.value.format("YYYY-MM-DD") +
            "/" +
            endDate.value.format("YYYY-MM-DD"),
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.status) {
            if ($.fn.DataTable.isDataTable("#googleadstable")) {
              $("#googleadstable").DataTable().clear().destroy();
            }
            campaignChartOptions.value = {
              labels: response.data.data.dateLabels,
              chart: {
                type: "line",
                stacked: false,
                id: "sessions",
                height: 300,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: [2, 2, 2],
              },
              colors: ["#FD6D54", "#2A4385"],
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },
              fill: {
                opacity: [1, 1, 1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
              },
              yaxis: {
                min: 0,
              },
            };
            // campaignChartOptions.value.labels = response.data.data.dateLabels;

            allCampaigns.value = response.data.data.collectiveArray;
            campaignSeries.value = [
              {
                name: "Impressions",
                type: "line",
                data: response.data.data.impressionsArray.map(Number),
              },
              {
                name: "Click",
                type: "line",
                data: response.data.data.clicksArray.map(Number),
              },
            ];

            setTimeout(() => {
              datatableInit();
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    const datatableInit = async () => {
      $("#googleadstable").addClass("nowrap").dataTable({
        responsive: true,
        order: [],
      });
    };

    onMounted(() => {
      dateRangePickerInit();
      datatableInit();
    });

    return {
      campaignSeries,
      campaignChartOptions,
      dealer,
      dealerInfo,
      dealerInfoError,
      allCampaigns,
      dateRangePickerInit,
      ctrPercentChange,
      costByConv,
      convByCost,
      costMicrosCalculation,
      resetFilters,
      toFixedConverter,
    };
  },
};
</script>