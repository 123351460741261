<template>
  <Dashboard>
    <!-- InventoryPrice-Logs -->
    <div class="table-area">
      <table id="price_logs_table" class="display">
        <thead>
          <tr>
            <th class="all">User</th>
            <th style="width:160px">VIN</th>
            <th>Stock</th>
            <th style="width:130px">Selling Price</th>
            <th style="width:130px">Special Price</th>
            <th style="width:130px">MSRP</th>
            <th style="width:130px">Internet Price</th>
            <th>IP</th>
            <th style="width:160px">Date</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- InventoryPrice-Logs -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted} from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    const getPriceLogs = async () => {
      if ($.fn.DataTable.isDataTable("#price_logs_table")) {
        $("#price_logs_table").DataTable().clear().destroy();
      }
      await $("#price_logs_table")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            paging: true,
            ajax: {
              url: axios.defaults.baseUrl + "getPriceLogs",
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "user_name" },
              { data: "vin" },
              { data: "stock" },
              { data: "selling_price" },
              { data: "special_price" },
              { data: "msrp" },
              { data: "internet_price" },
              { data: "user_ip" },
              { data: "created_at" },
            ],
            order: []
          });
    }
    onMounted(() => {
      getPriceLogs();
    })
    return {getPriceLogs}
  }
};
</script>
