import { ref } from 'vue';
import axios from 'axios';
const getVehicleTransmission = () => {
    var vehicleTransmission = ref([]);
    const transmissionError = ref('');
    try {
        const loadVehicleTransmission = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleTransmission",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleTransmission.value = response.data;
                })
                .catch((transmissionError) => {
                    transmissionError.value = 'No Vehicle Transmission found';
                });
        }
        return { vehicleTransmission, transmissionError, loadVehicleTransmission };
    } catch (transmissionError) {
        transmissionError.value = 'No Vehicle Transmission found';
    }
}
export default getVehicleTransmission;