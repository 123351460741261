<template>
  <Dashboard>
    <!-- inventory-header -->
    <div class="inventory-header">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="vehicle-area">
            <div class="search-box">
              <input
                  type="search"
                  class="form-control"
                  placeholder="Search vehicles"
                  v-model="keyword"
                  @keyup="getCraigslistAds"
              />
              <img
                  class="img-fluid search-icon"
                  src="@/assets/img/search-solid.svg"
                  alt=""
              />
            </div>
            <div class="action-link">
              <ul>
                <li><a class="listingFilters all" @click="listingFilters('all')" href="javascript:;">All</a></li>
                <li><a class="listingFilters 1 active" @click="listingFilters(1)" href="javascript:;">Posted</a></li>
                <li><a class="listingFilters 0" @click="listingFilters(0)" href="javascript:;">Pending</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- inventory-header -->

    <!-- Analytics-table -->
    <div class="table-area hide-select-search">
      <table id="postingTable" class="display">
        <thead>
        <tr>
          <th style="width:60px">Image</th>
          <th style="max-width:250px" class="all">Vehicle</th>
          <th>Details</th>
          <th style="width:60px;">Status</th>
          <th style="width:150px;" class="all">Date</th>
          <th style="width:60px;" class="all">URL</th>
        </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted, ref} from "vue";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let keyword = ref("");
    let filterType = ref(1);

    const listingFilters = (type) => {
      $(".listingFilters").removeClass("active");
      $(".listingFilters."+type).addClass("active");
      filterType.value = type;
      getCraigslistAds();
    }

    const getCraigslistAds = async () => {
      if ($.fn.DataTable.isDataTable("#postingTable")) {
        $("#postingTable").DataTable().clear().destroy();
      }
      await $("#postingTable")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getFacebookPageAds",
              type: "post",
              data: function (d) {
                d.search["value"] = keyword.value;
                d.filterType = filterType.value;
              },
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "image" },
              { data: "vehicleName" },
              { data: "details" },
              { data: "adStatus" },
              { data: "date" },
              { data: "postUrl" }
            ],
            order: [],
            columnDefs: [
              {targets: [0, 1, 5], orderable: false }
            ]
          });
    }

    onMounted( () => {
      getCraigslistAds();
    })

    return {
      getCraigslistAds,
      keyword,
      listingFilters
    };
  }
};
</script>