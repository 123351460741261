<template>
  <Dashboard>
    <div class="table-area hide-select-search">
      <table id="locations_table" class="display">
        <thead>
        <tr>
          <th class="all">Review By</th>
          <th style="width:50px">Rating</th>
          <th style="width:100px">Date</th>
          <th>Comment</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="GMBReview in allGMBReviews" :key="GMBReview">
          <td>{{ GMBReview.reviewer.displayName }}</td>
          <td>{{ GMBReview.starRating }}</td>
          <td>{{ dateFormat(GMBReview.createTime) }}</td>
          <td>{{ GMBReview.comment }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </Dashboard>
</template>
<script>
import axios from "axios";
import { useRoute } from "vue-router";
import Dashboard from "./../../../layouts/Dashboard.vue";
import getGmbRefreshToken from "./../../../composeables/Google/getGmbRefreshToken";
import $ from "jquery";
import moment from "moment";
import {onMounted, ref} from "vue";
export default {
  components: {
    Dashboard
  },
  setup() {
    let allGMBReviews = ref([]);
    const route = useRoute();

    const dateFormat = (date) => {
      return moment(date).format("MM/DD/YYYY")
    }

    const { token, loadToken } = getGmbRefreshToken();
    loadToken().then(() => {
      if (!token.value) {
        let googleUrl = null;
        axios
          .post(
              axios.defaults.baseUrl + "googleAuthentication",
              {
                google_auth: "",
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            googleUrl = response.data.data;
            window.location.href = googleUrl;
          });
      }
    });

    const getGMBReviews = async () => {
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "getGMBLocationReviews",
              {
                dealer_id: localStorage.getItem("dealer_id"),
                gmb_location: route.params.id
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if(response.status) {
              if ($.fn.DataTable.isDataTable("#locations_table")) {
                $("#locations_table").DataTable().clear().destroy();
              }
              allGMBReviews.value = response.data.data.allMetrics.reviews;
              setTimeout(() => {
                window.showHideMainLoader(false);
                datatableInit();
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const datatableInit = async () => {
      $("#locations_table").addClass("nowrap").dataTable({
        responsive: true,
        order: []
      });
    }

    onMounted( () => {
      datatableInit();
      getGMBReviews();
    })

    return {allGMBReviews, dateFormat};
  },
};
</script>