import { ref } from 'vue';
import axios from 'axios';
const getVehicleExteriorColor = () => {
    var vehicleExteriorColor = ref([]);
    const ExteriorColorError = ref('');
    try {
        const loadVehicleExteriorColor = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleExteriorColor",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleExteriorColor.value = response.data;
                })
                .catch((ExteriorColorError) => {
                    ExteriorColorError.value = 'No Vehicle Exterior Color found';
                });
        }
        return { vehicleExteriorColor, ExteriorColorError, loadVehicleExteriorColor };
    } catch (ExteriorColorError) {
        ExteriorColorError.value = 'No Vehicle Exterior Color found';
    }
}
export default getVehicleExteriorColor;