<template>
  <Dashboard>
    <!-- Departments-table -->
    <div class="table-area department-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#departmentModal"
          >Add Department</a
        >
      </div>

      <table class="display" id="departments">
        <thead>
          <tr>
            <th class="all">Department Name</th>
            <th style="width:100px;">Display Order</th>
            <th class="all" style="width:70px;">Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Departments-table -->
    <!-- edit departments modal -->
    <div
      class="modal select-modal fade"
      id="departmentModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Edit Department
            </h5>
            <h5 v-else class="modal-title" id="staticBackdropLabel">
              Add Department
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <h4>Opening & Closing Time</h4>
              <div class="form-row">
                <!-- left -->
                <div class="col-md-6">
                  <div class="form-row">
                    <div class="col-md-12">
                      <label>Sunday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.sunday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.sunday_close">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>Monday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.monday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.monday_close">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>Tuesday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.tuesday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.tuesday_close">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>Wednesday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.wednesday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.wednesday_close">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>Thusday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.thursday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.thursday_close">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>Friday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.friday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.friday_close">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>Saturday</label>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.saturday_open">
                        </div>
                        <div class="form-group col-md-6">
                          <input type="text" class="form-control tags1" v-model="department.saturday_close">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- left -->
                <div class="col-md-1"></div>
                <!-- right -->
                <div class="col-md-5">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label>Status</label>
                      <select
                        class="form-control tags1"
                        v-model="department.status"
                      >
                        <option value="active">Active</option>
                        <option value="inactive">In Active</option>
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="department.name"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Phone</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="department.phone"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Display Order</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder=""
                        v-model="department.dep_display_order"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Department Type</label>
                      <select
                        class="form-control tags1"
                        v-model="department.dep_type"
                      >
                        <option
                          v-for="type in departmentTypes"
                          :key="type.id"
                          :value="type.name"
                        >
                          {{ type.name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-12">
                      <label>Show on</label>
                      <select
                        class="form-control tags1"
                        v-model="department.page_id"
                      >
                        <option
                          v-for="page in cmspages"
                          :key="page.id"
                          :value="page.id"
                        >
                          {{ page.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- right -->
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button
                v-if="editMode"
                type="button"
                class="send-btn"
                @click="updateDepartment"
              >
                Save
              </button>
              <button
                v-else
                type="button"
                class="send-btn"
                @click="addDepartment"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- edit departments modal -->
    <!-- delete departments Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div
              class="
                delete-modal
                d-flex
                flex-columns
                justify-content-center
                align-items-center
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
                class="d-block"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this User?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button type="button" class="send-btn" @click="deleteDepartment">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete departments Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import {onMounted, ref} from "vue";
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard";
import getDepartmentTypes from "./../../composeables/getDepartmentTypes";
import getCmsPages from "./../../composeables/getCmsPages";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let department = ref({});
    let editMode = ref(false);
    // Get Departments Type:
    const { departmentTypes, departmentTypesError, loadDepartmentTypes } =
      getDepartmentTypes();
    loadDepartmentTypes();
    // CMS pages:
    const { cmspages, cmsPagesError, loadCmsPages } = getCmsPages();
    loadCmsPages();
    // Add Department
    const addDepartment = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "addDepartment",
          {
            department: department.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Department added successfully...",
            });
            getDepartments();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };
    // Open Edit Modal
    $(document).on("click", ".editDepartment", function () {
      editMode.value = true;
      axios
        .get(axios.defaults.baseUrl + "getDepartment/" + $(this).data("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          department.value = response.data;
          $("#departmentModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    });
    // Close Modal
    const closeModal = () => {
      department.value = {};
      editMode.value = false;
      $("#departmentModal").modal("hide");
      $("#deleteModal").modal("hide");
    };
    // Update Department
    const updateDepartment = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "updateDepartment/" + department.value.id,
          {
            department: department.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Department updated successfully...",
            });
            getDepartments();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };
    // Open Delete Modal
    $(document).on("click", ".deleteDepartment", function () {
      axios
        .get(axios.defaults.baseUrl + "getDepartment/" + $(this).data("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          department.value = response.data;
          $("#deleteModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    });
    // Delete Department
    const deleteDepartment = async () => {
      axios
        .delete(
          axios.defaults.baseUrl + "deleteDepartment/" + department.value.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Department deleted successfully...",
            });
            getDepartments();
            closeModal();
          } else {
            console.log("error");
            window.toast.fire({
              icon: "error",
              title: "Error, Please try again.",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    const getDepartments = async () => {
      if ($.fn.DataTable.isDataTable("#departments")) {
        $("#departments").DataTable().clear().destroy();
      }
      await $("#departments")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getDepartments",
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "name" },
              { data: "dep_display_order" },
              { data: "action_links" },
            ],
            order: [],
            columnDefs: [
              {targets: [2], orderable: false }
            ]
          });
    }
    onMounted(() => {
      getDepartments();
    })
    return {
      department,
      editMode,
      departmentTypes,
      cmspages,
      departmentTypesError,
      cmsPagesError,
      addDepartment,
      closeModal,
      updateDepartment,
      deleteDepartment,
    };
  }
};
</script>
<style>
#departments tbody tr {
    position: relative;
}
</style>