<template>
  <Dashboard>
    <!-- CMS PAGES Table -->
    <div class="table-area addpage-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#cmsPageModal"
        >
          Add Page</a
        >
        &nbsp;
        <a class="action-btn" href="javascript:;" @click="createMissingPages">
          Create Missing Pages</a
        >
      </div>

      <table class="display" id="cmsPages">
        <thead>
          <tr>
            <th class="all">Title</th>
            <th>URL (Slug)</th>
            <th style="width: 60px">Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- CMS PAGES Table -->
    <!-- add CMS Page modal -->
    <div
      class="modal select-modal3 fade"
      id="cmsPageModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Edit Page
            </h5>
            <h5 v-else class="modal-title" id="staticBackdropLabel">
              Add Page
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform" @submit.prevent="submitForm">
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-8">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label>Page Name:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="page.label"
                        placeholder=""
                      />
                    </div>

                    <div class="form-group col-md-12">
                      <label>Page URL:</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="page.slug"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label>Featured Image</label>
                      <input type="file" @change="onFileChange" />
                      <img
                        v-if="page.featured_image"
                        :src="page.featured_image"
                        alt="Featured Image"
                        style="height: 75px; margin-top: 5px"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label>Treat as serving area page?</label>
                  <select
                    class="form-control tags3"
                    v-model="page.service_area_page"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <small>If yes, link will be displayed in footer</small>
                </div>

                <div class="form-group col-md-12">
                  <label>Page Details:</label>
                  <TinyMceEditor
                    v-model="page.details"
                    api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre"
                    :init="{
                      menubar: false,
                      branding: false,
                      extended_valid_elements: 'span[class|align|style]',
                      forced_root_block_attrs: { class: 'root_block_p' },
                      element_format: 'html',
                      relative_urls: false,
                      remove_script_host: false,
                      height: 250,
                      browser_spellcheck: true,
                      verify_html: false,
                      images_upload_handler: tinyMceUploader,
                      plugins:
                        'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                      toolbar:
                        'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code',
                    }"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>Seo Title: </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                    maxlength="70"
                    v-model="page.meta_title"
                  />
                  <small
                    >Maximum 70 characters. Please do not use any special
                    characters</small
                  >
                </div>
                <div class="form-group col-md-12">
                  <label>Seo Description: </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    required
                    maxlength="300"
                    placeholder=""
                    v-model="page.meta_description"
                  ></textarea>
                  <small
                    >Maximum 300 characters. Please do not use any special
                    characters</small
                  >
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closeModal" class="cancle-btn">
                  Cancel
                </button>
                <button type="submit" class="send-btn">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add CMS Page moda -->
    <!-- delete Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this CMS page?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button type="button" @click="deleteCmsPage" class="send-btn">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import { onMounted, ref } from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
import TinyMceEditor from "@tinymce/tinymce-vue";
export default {
  components: {
    Dashboard,
    TinyMceEditor,
  },
  setup() {
    let page = ref({});
    let allowSubmit = ref(true);
    let editMode = ref(false);
    let newImageForUpload = ref("no");
    let idForDelete = ref("");
    // Close Modal
    const closeModal = () => {
      page.value = {};
      editMode.value = false;
      $(".tox-tbtn").removeClass("tox-tbtn--enabled");
      $(".tox.tox-silver-sink.tox-tinymce-aux").html("");
      $("#cmsPageModal").modal("hide");
      $("#deleteModal").modal("hide");
    };
    const submitForm = () => {
      if (allowSubmit.value) {
        if (editMode.value) {
          updateCmsPage();
        } else {
          addCmsPage();
        }
      }
    };

    // File Change Method for Image upload field
    const onFileChange = async (element) => {
      let file = element.target.files[0];
      if (file["size"] > 1048576) {
        window.toast.fire({
          icon: "error",
          title: "Image size can not be greater than 1MB",
        });
        return false;
      }
      if (
        file["type"] != "image/png" &&
        file["type"] != "image/jpg" &&
        file["type"] != "image/jpeg"
      ) {
        window.toast.fire({
          icon: "error",
          title: "File format not supported",
        });
        allowSubmit.value = false;
        return false;
      }
      let reader = new FileReader();
      reader.onloadend = (file) => {
        if (file) {
          newImageForUpload.value = "yes";
          page.value.featured_image = reader.result;
        }
      };
      reader.readAsDataURL(file);
    };

    const addCmsPage = async () => {
      if (
        page.value.service_area_page == null ||
        page.value.service_area_page == "" ||
        page.value.service_area_page == "undefined"
      ) {
        window.toast.fire({
          icon: "error",
          title: "Serving area field is required",
        });
        return false;
      }
      window.showHideMainLoader(true);
      let data = new FormData();
      data.append("label", page.value.label);
      data.append("slug", page.value.slug);
      data.append("featured_image", page.value.featured_image);
      data.append("service_area_page", page.value.service_area_page);
      data.append("details", page.value.details);
      data.append("meta_title", page.value.meta_title);
      data.append("meta_description", page.value.meta_description);
      await axios
        .post(axios.defaults.baseUrl + "addCmsPage", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "CMS Page added successfully",
            });
            getCmsPages();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };
    // Open Edit Modal
    $(document).on("click", ".editPage", function () {
      editMode.value = true;
      axios
        .get(axios.defaults.baseUrl + "getCmsPage/" + $(this).data("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          page.value = response.data;
          editMode.value = true;
          newImageForUpload.value = "no";
          $("#cmsPageModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            console.log(error);
          }
        });
    });
    // Update CMS Page
    const updateCmsPage = async () => {
      window.showHideMainLoader(true);
      let data = new FormData();
      data.append("label", page.value.label);
      data.append("slug", page.value.slug);
      data.append("featured_image", page.value.featured_image);
      data.append("new_image_upload", newImageForUpload.value);
      data.append("service_area_page", page.value.service_area_page);
      data.append("details", page.value.details);
      data.append("meta_title", page.value.meta_title);
      data.append("meta_description", page.value.meta_description);
      await axios
        .post(axios.defaults.baseUrl + "updateCmsPage/" + page.value.id, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "CMS Page updated successfully",
            });
            getCmsPages();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };
    // Open Delete Modal
    $(document).on("click", ".deletePage", function () {
      idForDelete.value = $(this).data("id");
      $("#deleteModal").modal("show");
    });
    // Delete CMS Page:
    const deleteCmsPage = async () => {
      window.showHideMainLoader(true);
      await axios
        .delete(axios.defaults.baseUrl + "deleteCmsPage/" + idForDelete.value, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "CMS Page deleted successfully",
            });
            getCmsPages();
            closeModal();
          } else {
            console.log("error");
            window.toast.fire({
              icon: "error",
              title: "Error, Please try again.",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const getCmsPages = async () => {
      if ($.fn.DataTable.isDataTable("#cmsPages")) {
        $("#cmsPages").DataTable().clear().destroy();
      }
      await $("#cmsPages")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: axios.defaults.baseUrl + "getCmsPages",
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "label" },
            { data: "slug" },
            { data: "action_links" },
          ],
          order: [],
          columnDefs: [{ targets: [2], orderable: false }],
        });
    };

    const tinyMceUploader = async (blobInfo, success, failure) => {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", axios.defaults.baseUrl + "editorImageUpload");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + localStorage.getItem("auth_token")
      );
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failure("HTTP Error: " + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json) {
          failure("Invalid JSON: " + xhr.responseText);
          return;
        }
        if (json.status == "error") {
          failure(json.location);
          return;
        }
        success(json.location);
      };
      formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    };

    // Update CMS Page
    const createMissingPages = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "createMissingPages",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            getCmsPages();
          }
          window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    onMounted(() => {
      getCmsPages();
    });

    return {
      page,
      editMode,
      closeModal,
      submitForm,
      deleteCmsPage,
      onFileChange,
      tinyMceUploader,
      createMissingPages,
    };
  },
};
</script>
<style>
.delete-modal svg {
  display: inline-block;
  overflow: hidden;
}
#cmsPages tbody tr td {
  position: relative;
}
.addpage-table .dataTables_wrapper .dataTables_filter {
  margin-right: 280px;
}
</style>