<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Age</h4>
            <apexchart
              v-if="enableChart"
              id="chart3"
              ref="myChart"
              width="100%"
              height="380"
              type="bar"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mygraph">
            <h4>Gender</h4>
            <apexchart
              v-if="enableGenderChart"
              id="chart4"
              width="100%"
              height="350"
              type="donut"
              :options="donutChartOptions"
              :series="donutSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- google-graph -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let series = ref([]);
    let chartOptions = ref({});
    let donutSeries = ref([]);
    let donutChartOptions = ref({});
    let enableChart = ref("false");
    let enableGenderChart = ref("false");
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    let ageSessions = ref([]);
    let ageSessionsError = ref("");
    let genderSessions = ref([]);
    let genderSessionsError = ref("");

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getAgeSessions();
      getGenderSessions();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const getAgeSessions = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getAgeSessions",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          ageSessions.value = response.data;
          enableChart.value = true;
          series.value = [
            {
              name: "Sessions",
              data: ageSessions.value.sessions,
            },
          ];
          chartOptions.value = {
            chart: {
              type: "bar",
              id: "sessions",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            colors: ["#2A4385"],
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "50%",
                borderRadius: 17,
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return " " + val + " %";
                },
              },
            },
            xaxis: {
              type: "category",
              convertedCatToNumeric: false,
              categories: ageSessions.value.ages,
            },
          };
        })
        .catch((ageSessionsError) => {
          ageSessionsError.value = "No Sessions for Google Analytics found";
        });
    };

    const getGenderSessions = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getGenderSessions",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          genderSessions.value = response.data;
          enableGenderChart.value = true;
          let gender = [];
          gender[0] = parseInt(genderSessions.value.gender[0]);
          gender[1] = parseInt(genderSessions.value.gender[1]);
          donutSeries.value = gender;
          donutChartOptions.value = {
            chart: {
              type: "donut",
            },
            labels: ["Male", "Female"],
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((genderSessionsError) => {
          genderSessionsError.value = "No Sessions for Google Analytics found";
        });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      dateRangePickerInit();
    });

    return {
      ageSessions,
      genderSessions,
      series,
      donutSeries,
      chartOptions,
      enableChart,
      enableGenderChart,
      donutChartOptions,
      ageSessionsError,
      genderSessionsError,
      resetFilters,
    };
  },
};
</script>