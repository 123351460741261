<template>
  <Dashboard>
    <!-- InventoryExportSettings-Table -->
    <div class="table-area pricing-table positionedbutton">
      <div class="action-drop" v-if="user_type == 'admin'">
        <a class="action-btn" href='javascript:;' @click="openAddMarketPlaceModal">Add Marketplace</a>
      </div>
      <table class="display" id="exportSettings">
        <thead>
          <tr>
            <th class="all">Marketplace</th>
            <th class="all" style="width:70px;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="marketPlace in marketPlaces" :key="marketPlace">
            <td>{{ marketPlace.market_places.market_place_name }}</td>
            <td v-if="user_type == 'dealer'">
              <label class="switch">
                <input
                  v-if="marketPlace.status == 'active'"
                  type="checkbox"
                  :data-id="marketPlace.id"
                  checked="checked"
                  @change="toggleCheckbox(marketPlace.id, marketPlace.status)"
                />
                <input
                  v-else
                  type="checkbox"
                  @change="toggleCheckbox(marketPlace.id, marketPlace.status)"
                />
                <span class="slider round"></span>
              </label>
            </td>
            <td v-else>
              <a href="javascript:void(0);" class="editExportSettings" @click="editMarketPlace(marketPlace)"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 0.0234375C15.1758 0.0234375 14.6133 0.246094 14.1797 0.679688L6.75 8.08594L6.58594 8.25L6.53906 8.48438L6.02344 11.1094L5.78906 12.2109L6.89062 11.9766L9.51562 11.4609L9.75 11.4141L9.91406 11.25L17.3203 3.82031C18.1846 2.95605 18.1846 1.54395 17.3203 0.679688C16.8867 0.246094 16.3242 0.0234375 15.75 0.0234375ZM15.75 1.47656C15.9258 1.47656 16.0986 1.56738 16.2656 1.73438C16.5996 2.06836 16.5996 2.43164 16.2656 2.76562L9 10.0312L7.71094 10.2891L7.96875 9L15.2344 1.73438C15.4014 1.56738 15.5742 1.47656 15.75 1.47656ZM0 3V18H15V8.10938L13.5 9.60938V16.5H1.5V4.5H8.39062L9.89062 3H0Z" fill="#63729A"/>
              </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- add edit marketplace modal -->
    <div v-if="user_type == 'admin'"
        class="modal select-modal fade"
        id="addEditMarketplaceModal"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!editMode" class="modal-title" id="staticBackdropLabel">
              Add New Marketplace
            </h5>
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Update Marketplace
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <input type="hidden" v-model="id" />
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Marketplace</label>
                  <select
                  class="form-control tags2"
                  v-model="marketPlaceDetails.market_place_id_fk"
                  required
                >
                  <option v-for="marketPlace in allMarketPlaces" :key="marketPlace.market_place_id" :value="marketPlace.market_place_id">
                      {{ marketPlace.market_place_name }}
                    </option>
                </select>
                </div>
                <div class="form-group col-md-6">
                  <label>Status</label>
                  <select
                    class="form-control tags2"
                    v-model="marketPlaceDetails.status"
                    required
                  >
                    <option value="active">On</option>
                    <option value="inactive">Off</option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label>FTP Host</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="FTP Host"
                      v-model="marketPlaceDetails.ftp_host"
                      required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>FTP Username</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="FTP Username"
                      v-model="marketPlaceDetails.ftp_username"
                      required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>FTP Port</label>
                  <input
                      type="number"
                      class="form-control"
                      placeholder="FTP Port"
                      v-model="marketPlaceDetails.ftp_port"
                      required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>FTP Password</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="FTP Password"
                      v-model="marketPlaceDetails.ftp_password"
                      required
                  />
                </div>

              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Back
                </button>
                <button
                    v-if="editMode"
                    type="button"
                    @click="handleMarketplaceUpdateRequest"
                    class="send-btn"
                >
                  Submit
                </button>
                <button
                    v-else
                    type="button"
                    @click="handleMarketplaceAddRequest"
                    class="send-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add edit marketplace modal -->
    <!-- InventoryExportSettings-Table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted, ref} from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
import { useRoute } from "vue-router";
export default {
  components: {
    Dashboard,
  },
  setup() {
    const route = useRoute();
    let marketPlaces = ref([]);
    let marketPlaceDetails = ref({});
    let editMode = ref(false);
    let allMarketPlaces = ref({});
    const user_type = window.userRoleType;
    let dealerId = ref(localStorage.getItem('dealer_id'));
    if(user_type == 'admin') {
      dealerId.value = route.params.id;
    }

      const getAllMarketPlaces = async () => {
      await axios
          .get(axios.defaults.baseUrl + "getAllMarketPlaces",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem('auth_token'),
                },
              }
          )
          .then((response) => {
            allMarketPlaces.value = response.data;
          })
    }

    const editMarketPlace = async (marketPlace) => {
      editMode.value = true;
      marketPlaceDetails.value = marketPlace;
      $("#addEditMarketplaceModal").modal("show");
    }


    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#exportSettings")) {
        $("#exportSettings").DataTable().clear().destroy();
      }
      $("#exportSettings").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false }
        ]
      });
    }

    const loadMarketPlaces = async () => {
	let dealer_id = dealerId.value;
      await axios
          .get(axios.defaults.baseUrl + "getDealerMarketPlaces/"+dealer_id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem('auth_token'),
                },
              }
          )
          .then((response) => {
            if ($.fn.DataTable.isDataTable("#exportSettings")) {
              $("#exportSettings").DataTable().clear().destroy();
            }
            marketPlaces.value = response.data;
            if (marketPlaces.value.date_in_stock) {
              marketPlaces.value.date_in_stock = new Date(
                  marketPlaces.value.date_in_stock
              );
            }
            setTimeout(() => {
              datatableInit();
            });
          })
    }
    const toggleCheckbox = async (id, status) => {
      if (status == "active") {
        status = "inactive";
      } else {
        status = "active";
      }
      await axios
        .post(
          axios.defaults.baseUrl + "updateExportSettings",
          {
            status,
            id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Export Settings updated.",
            });
          }
        });
    }

    const handleMarketplaceUpdateRequest = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "updateDealerMarketPlace",
          {
            marketplaceData: marketPlaceDetails.value
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
          loadMarketPlaces();
          closePopup();
        });
    }

    const handleMarketplaceAddRequest = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "addDealerMarketPlace",
          {
             marketplaceData: marketPlaceDetails.value,
            dealerId: dealerId.value
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
           window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
          loadMarketPlaces();
          closePopup();
        });
    }

    const openAddMarketPlaceModal = () => {
      marketPlaceDetails.value.market_place_id_fk = '';
      marketPlaceDetails.value.status = '';
      marketPlaceDetails.value.ftp_host = '';
      marketPlaceDetails.value.ftp_username = '';
      marketPlaceDetails.value.ftp_port = '';
      marketPlaceDetails.value.ftp_password = '';
      $("#addEditMarketplaceModal").modal("show");
    };

    const closePopup = () => {
      marketPlaceDetails.value.market_place_id_fk = '';
      marketPlaceDetails.value.status = '';
      marketPlaceDetails.value.ftp_host = '';
      marketPlaceDetails.value.ftp_username = '';
      marketPlaceDetails.value.ftp_port = '';
      marketPlaceDetails.value.ftp_password = '';
      $("#addEditMarketplaceModal").modal("hide");
    };

    onMounted(() => {
      datatableInit();
      getAllMarketPlaces();
      loadMarketPlaces();
      if(user_type == 'admin') {
        $('.table-area').addClass('pricing-table');
      }else {
        $('.table-area').removeClass('pricing-table');
      }
    })
    return { marketPlaces, loadMarketPlaces, toggleCheckbox, user_type, closePopup, marketPlaceDetails, editMarketPlace, editMode, allMarketPlaces, handleMarketplaceUpdateRequest, handleMarketplaceAddRequest, openAddMarketPlaceModal };
  }
};
</script>
