<template>
  <Dashboard>
    <!-- Analytics-table -->
    <div class="table-area catalog-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#catalogModal"
          >Add Vehicle Set</a
        >
      </div>

      <table class="display" id="catalog">
        <thead>
          <tr>
            <th>Name</th>
            <th>Make</th>
            <th>Model</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vehicleSet in allVehicleSets" :key="vehicleSet">
            <td>{{ vehicleSet.name }}</td>
            <td>{{ vehicleSet.make }}</td>
            <td>{{ vehicleSet.model }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->

    <!-- ads-Modal -->
    <div
      class="modal select-modal fade"
      id="catalogModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Create Vehicle Set
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="">Make</label>
                  <select
                    class="form-control tags1 select2"
                    id="selectMake"
                    v-model="selectedMake"
                  >
                    <option value="">Select make</option>
                    <option v-for="make in makes" :key="make" :value="make">
                      {{ make }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label for="">Model</label>
                  <select
                    class="form-control tags1 select2"
                    v-model="selectedModel"
                    id="selectModel"
                  >
                    <option selected value="">All</option>
                    <option v-for="model in models" :key="model" :value="model">
                      {{ model }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" data-dismiss="modal" class="cancle-btn">
                Back
              </button>
              <button
                type="submit"
                class="send-btn"
                @click="createFacebookCatalogVehicleSet"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ads-Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let makes = ref([]);
    let models = ref([]);
    let allVehicleSets = ref([]);
    let selectedMake = ref("");
    let selectedModel = ref("");

    const closeModal = () => {
      $("#catalogModal").modal("hide");
    }

    const getFacebookCatalog = async () => {
      window.showHideMainLoader(true);
      await axios
        .get(axios.defaults.baseUrl + "getFacebookCatalog", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            if ($.fn.DataTable.isDataTable("#catalog")) {
              $("#catalog").DataTable().clear().destroy();
            }
            makes.value = response.data.data.makes;
            models.value = response.data.data.models;
            allVehicleSets.value = response.data.data.vehicleSets;
            setTimeout(() => {
              datatableInit();
              $(".select2").select2();
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const createFacebookCatalogVehicleSet = async () => {
      window.showHideMainLoader(true);
      let make = selectedMake.value;
      let model = selectedModel.value;
      await axios
        .post(
          axios.defaults.baseUrl + "createFacebookCatalogVehicleSet",
          {
            make,
            model,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.toast.fire({
            icon: response.data.status,
            title: response.data.data,
          });
          if (response.data.status == "success") {
            closeModal();
            getFacebookCatalog();
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    }

    const datatableInit = async () => {
      $("#catalog").addClass("nowrap").dataTable({
        responsive: true,
        order: []
      });
    }

    onMounted(() => {
      getFacebookCatalog();
      datatableInit();
      $(".select2").select2();
    });

    return {
      makes,
      models,
      selectedMake,
      selectedModel,
      createFacebookCatalogVehicleSet,
      allVehicleSets
    };
  },
};
</script>