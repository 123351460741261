import { ref } from 'vue';
import axios from 'axios';
const getVehicleDriveTypes = () => {
    var vehicleDriveTypes = ref([]);
    const DriveTypesError = ref('');
    try {
        const loadVehicleDriveTypes = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleDriveTypes",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleDriveTypes.value = response.data;
                })
                .catch((DriveTypesError) => {
                    DriveTypesError.value = 'No Vehicle Derive Types found';
                });
        }
        return { vehicleDriveTypes, DriveTypesError, loadVehicleDriveTypes };
    } catch (DriveTypesError) {
        DriveTypesError.value = 'No Vehicle Derive Types found';
    }
}
export default getVehicleDriveTypes;