import { ref } from 'vue';
import axios from 'axios';
const getVehicleFuelTypes = () => {
    const vehicleFuelTypes = ref([]);
    const fuelTypeError = ref('');
    try {
        const loadVehicleFuelTypes = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleFuelTypes",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleFuelTypes.value = response.data;
                })
                .catch((fuelTypeError) => {
                    fuelTypeError.value = 'No Vehicle Make data found';
                });
        }
        return { vehicleFuelTypes, fuelTypeError, loadVehicleFuelTypes };
    } catch (fuelTypeError) {
        fuelTypeError.value = 'No Vehicle Make data found';
    }
}
export default getVehicleFuelTypes;