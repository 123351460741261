<template>
  <!-- tabs-area -->
  <div class="tabs-area">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
            class="nav-link active"
            id="nav-Reach-tab"
            data-toggle="tab"
            href="#nav-Reach"
            role="tab"
            aria-controls="nav-Reach"
            aria-selected="true"
        >
          Reach
          <h3>{{ reachCount }}</h3>
          <p><span v-html="reachChange"></span></p>
        </a>
        <a
            class="nav-link"
            id="nav-Amount-tab"
            data-toggle="tab"
            href="#nav-Amount"
            role="tab"
            aria-controls="nav-Amount"
            aria-selected="false"
        >
          Amount Spent
          <h3>{{ spendCount }}</h3>
          <p><span v-html="spendChange"></span></p>
        </a>
        <a
            class="nav-link"
            id="nav-Impressions-tab"
            data-toggle="tab"
            href="#nav-Impressions"
            role="tab"
            aria-controls="nav-Impressions"
            aria-selected="false"
        >
          Impressions
          <h3>{{ impressionCount }}</h3>
          <p><span v-html="impressionChange"></span></p>
        </a>

        <a
            class="nav-link"
            id="nav-Click-tab"
            data-toggle="tab"
            href="#nav-Click"
            role="tab"
            aria-controls="nav-Click"
            aria-selected="false"
        >
          Click
          <h3>{{ clickCount }}</h3>
          <p><span v-html="clickChange"></span></p>
        </a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
          class="tab-pane fade show active"
          id="nav-Reach"
          role="tabpanel"
          aria-labelledby="nav-Reach-tab"
      >
        <div class="graph-tab">
          <apexchart
              ref="myChart"
              width="100%"
              height="300"
              type="area"
              :options="reachOptions"
              :series="reach"
          ></apexchart>
        </div>
      </div>
      <div
          class="tab-pane fade"
          id="nav-Amount"
          role="tabpanel"
          aria-labelledby="nav-Amount-tab"
      >
        <div class="graph-tab">
          <apexchart
              ref="myChart"
              width="100%"
              height="300"
              type="area"
              :options="reachOptions"
              :series="spend"
          ></apexchart>
        </div>
      </div>
      <div
          class="tab-pane fade"
          id="nav-Impressions"
          role="tabpanel"
          aria-labelledby="nav-Impressions-tab"
      >
        <div class="graph-tab">
          <apexchart
              ref="myChart"
              width="100%"
              height="300"
              type="area"
              :options="reachOptions"
              :series="impression"
          ></apexchart>
        </div>
      </div>
      <div
          class="tab-pane fade"
          id="nav-Click"
          role="tabpanel"
          aria-labelledby="nav-Click-tab"
      >
        <div class="graph-tab">
          <apexchart
              ref="myChart"
              width="100%"
              height="300"
              type="area"
              :options="reachOptions"
              :series="click"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
  <!-- tabs-area -->
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import axios from "axios";
import {onMounted, ref} from "vue";
//import $ from "jquery";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    let reachCount = ref("");
    let spendCount = ref("");
    let impressionCount = ref("");
    let clickCount = ref("");

    let reachChange = ref("");
    let spendChange = ref("");
    let impressionChange = ref("");
    let clickChange = ref("");
    let chartLabels = ref([]);
    let reachOptions = ref([]);
    let reach = ref({});
    let spend = ref({});
    let impression = ref({});
    let click = ref({});

    reachOptions.value = {
      labels: chartLabels.value,
      chart: {
        type: "line",
        stacked: false,
        height: 300,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: [2, 2],
      },
      colors: ["#FD6D54", "#FCD932"],
      plotOptions: {
        bar: {
          columnWidth: "30%",
        },
      },
      fill: {
        opacity: [1, 1],
        gradient: {
          inverseColors: false,
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "text",
      },
      yaxis: {
        min: 0,
      },
    }

    const getFacebookInsights = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getFacebookInsights",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if(response.status) {
              reachCount.value = response.data.data.reachCount;
              spendCount.value = response.data.data.spendCount;
              impressionCount.value = response.data.data.impressionsCount;
              clickCount.value = response.data.data.clicksCount;
              reachChange.value = response.data.data.reachPercentageChange;
              spendChange.value = response.data.data.spendPercentageChange;
              impressionChange.value = response.data.data.impressionsPercentageChange;
              clickChange.value = response.data.data.clicksPercentageChange;


              chartLabels.value = response.data.data.dateLabels;
              reach.value = [
                {
                  name: "This Month",
                  type: "line",
                  data: response.data.data.reachArray.map(Number),
                },
                {
                  name: "Last Month",
                  type: "line",
                  data: response.data.data.reachArray2.map(Number),
                }
              ];
              spend.value = [
                {
                  name: "This Month",
                  type: "line",
                  data: response.data.data.spendArray.map(Number),
                },
                {
                  name: "Last Month",
                  type: "line",
                  data: response.data.data.spendArray2.map(Number),
                }
              ];
              impression.value = [
                {
                  name: "This Month",
                  type: "line",
                  data: response.data.data.impressionsArray.map(Number),
                },
                {
                  name: "Last Month",
                  type: "line",
                  data: response.data.data.impressionsArray2.map(Number),
                }
              ];
              click.value = [
                {
                  name: "This Month",
                  type: "line",
                  data: response.data.data.clicksArray.map(Number),
                },
                {
                  name: "Last Month",
                  type: "line",
                  data: response.data.data.clicksArray.map(Number),
                }
              ];
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    onMounted( () => {
      getFacebookInsights();
    })

    return { reachOptions, reach, spend, impression, click, reachChange, spendChange, impressionChange, clickChange, reachCount, spendCount, impressionCount, clickCount }
  }
}
</script>