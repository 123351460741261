<template>
  <div class="inventory-header">
  <div class="row">
    <div class="col-md-12 col-lg-4">
      <div class="leads-btn">
        <div class="btns-group">
          <router-link class="cus-btn" :to="{ name: 'AddVehicle' }"
          >Add Vehicle
            <img class="img-fluid" src="@/assets/img/plus-solid.svg" alt=""
            /></router-link>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-8">
      <div class="vehicle-area">
        <div class="search-box">
          <input
              type="search"
              class="form-control"
              placeholder="Search Inventory"
              v-model="keyword"
              v-on:keyup.enter="getInventory"
          />
          <img
              class="img-fluid search-icon"
              src="@/assets/img/search-solid.svg"
              alt=""
              @click.prevent="getInventory"
          />
        </div>
        <div class="action-link">
          <ul>
            <li>
              <router-link :to="{ name: 'NewInventory' }">New</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'UsedInventory' }"
              >Used</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'RemovedInventory' }"
              >Removed</router-link
              >
            </li>
          </ul>
        </div>
        <div class="export">
          <a class="export-btn" @click="exportInventory" href="javascript:;"
          ><img
              class="img-fluid"
              src="@/assets/img/file-excel-solid.svg"
              alt=""
          />
            Export</a
          >
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="table-area hide-select-search">
    <table id="inventory_table" class="display inventoryTableCustomStyle">
      <thead>
      <tr>
        <th style="width: 105px">Image</th>
        <th>Years</th>
        <th>Make</th>
        <th>Model</th>
        <th>Engine</th>
        <th>Stock</th>
        <th style="width: 70px;">Description</th>
        <th>MSRP</th>
        <th>Price</th>
        <th>Special Price</th>
      </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onBeforeUnmount, onMounted, ref} from "vue";
import routes from "./../../routes";
import { useRoute } from "vue-router";
export default {
  props: {
    inventoryTypeText: String
  },
  setup(props) {
    const route = useRoute();
    const searchParam = route.params.search;
    let keyword = ref("");
    // Edit Inventory Redirection
    $(document).on("click", ".editInventory", function () {
      let id = $(this).data("id");
      routes.push({ name: "EditVehicle", params: { id } });
    });

    const getInventory = async () => {
      if ($.fn.DataTable.isDataTable("#inventory_table")) {
        console.log("destroying table")
        $("#inventory_table").DataTable().clear().destroy();
      }
      await $("#inventory_table")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getInventory/" + props.inventoryTypeText,
              data: function (d) {
                d.search['value'] = keyword.value;
                d.searchParam = searchParam;
              },
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "image" },
              { data: "year" },
              { data: "make" },
              { data: "model" },
              { data: "engine" },
              { data: "stock_no" },
              { data: "description" },
              { data: "msrp" },
              { data: "price" },
              { data: "special_price" },
            ],
            order: [],
            columnDefs: [
              {targets: [0], orderable: false }
            ]
          });
    }

    const exportInventory = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "exportInventory/" + props.inventoryTypeText,
          { responseType: "arraybuffer" },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if(response.data.status != 'error') {
            window.showHideMainLoader(false);
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "inventory.csv");
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
        window.showHideMainLoader(false);
    }
    
    onMounted(() => {
      getInventory();
    })

    onBeforeUnmount(() => {
      console.log("destroying table")
      $.fn.DataTable.isDataTable("#inventory_table") && $("#inventory_table").DataTable().clear().destroy();
    });
    return { keyword, getInventory, exportInventory };
  }
};
</script>
<style>
.inventoryTableCustomStyle tbody tr {
  position:relative;
}
</style>
<style scoped>
.router-link-active, .router-link-exact-active {
  background: #fff;
  border: 1px solid #eef0f5;
  border-radius: 6px;
}
</style>