<template>
  <Dashboard>
    <section>
      <!-- inventory-header -->
      <div class="inventory-header lead-header">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="leads-btn">
              <div class="btns-group">
                <a class="cus-btn" href="javascript:;" @click="resetFilters">Reset</a>
              </div>
              <div class="search-box">
                <input type="text" class="reportrange form-control daterangebackground" />
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-6">
            <div class="vehicle-area">
              <div class="search-box">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Leads"
                  v-model="keyword"
                  @keyup="getLeads"
                />
                <img
                  class="img-fluid search-icon"
                  src="@/assets/img/search.svg"
                  alt=""
                />
              </div>
              <div class="export">
                <a
                  class="export-btn"
                  @click="exportLeads"
                  href="javascript:;"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/file-excel-solid.svg"
                    alt=""
                  />
                  Export</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- inventory-header -->

      <!-- tabs-area -->
      <div class="leads-tabs">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <router-link
                :to="{ name: 'WebsiteFormLeads' }"
                class="nav-link"
            >
              Website Leads
            </router-link>
            <router-link
                :to="{ name: 'WebsitePhoneLeads' }"
                class="nav-link"
            >
              Phone Leads
            </router-link>
            <router-link
                :to="{ name: 'WebsiteTextLeads' }"
                class="nav-link active"
            >
              Text Track Leads
            </router-link>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            role="tabpanel"
          >
            <!-- leads-table -->
            <div class="table-area">
              <table class="display bottommargin5" id="leadsTable">
                <thead>
                  <tr>
                    <th style="width:100px">Date / Time</th>
                    <th class="all" style="width:100px">Phone</th>
                    <th style="white-space: pre-wrap;">Message</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <!-- leads-table -->
          </div>
        </div>
      </div>
      <!-- tabs-area -->
    </section>
  </Dashboard>
</template>
<script>
import $ from "jquery";
import {onMounted, ref} from "vue";
import axios from "axios";
import "daterangepicker";
import Dashboard from "./../../../layouts/Dashboard.vue";
import moment from "moment";
export default {
  components: {
    Dashboard
  },
  setup() {
    let keyword = ref("");
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getLeads();
      $(".reportrange span").html(
          start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    }

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    }

    const getLeads = async () => {
      if ($.fn.DataTable.isDataTable("#leadsTable")) {
        $("#leadsTable").DataTable().clear().destroy();
      }
      await $("#leadsTable")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            dom: "rtiplf",
            searching: false,
            bInfo: false,
            pageLength: 50,
            ajax: {
              url: axios.defaults.baseUrl + "getWebsiteTextLeads",
              data: function (d) {
                d.search["value"] = keyword.value;
                d.fromDate = startDate.value;
                d.toDate = endDate.value;
              },
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "created_date" },
              { data: "sms_from" },
              { data: "sms_body" }
            ],
            order: []
          });
    }

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
          {
            startDate: startDate.value,
            endDate: endDate.value,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          cb
      );
      cb(startDate.value, endDate.value);
    }

const exportLeads = async () => {
  window.showHideMainLoader(true);
  await axios
    .post(
      axios.defaults.baseUrl + "exportWebsiteTextLeads",
      {
        responseType: "arraybuffer",
        fromDate: startDate.value,
        toDate: endDate.value,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      }
    )
    .then((response) => {
      if (response.data.status != "error") {
        window.showHideMainLoader(false);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "leads.csv");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    });
  window.showHideMainLoader(false);
}

    onMounted( () => {
      dateRangePickerInit();
    })

    return {
      keyword,
      getLeads,
      resetFilters,
      exportLeads
    };
  }
};
</script>