<template>
  <div>
    <!-- loader -->
    <div class="loaders">
      <div class="loader"></div>
    </div>
    <!-- loader -->
    <!-- main-area -->
    <section class="main-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <LeftNav :Logout="logout" />
            <!-- main-contents -->
            <div class="main-contents">
              <TopHeader :Logout="logout" :RouteObject="Route" />
              <!-- main-contents -->
              <slot />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import LeftNav from "./../common/LeftNav.vue";
import TopHeader from "./../common/TopHeader.vue";
import "select2";
// import moment from "moment";
// import "daterangepicker";
import "datatables.net";
import "datatables.net-responsive";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import {onMounted} from "vue";
export default {
  components: {
    LeftNav,
    TopHeader,
  },
  setup() {
    const Router = useRouter()
    const Route = useRoute()

    // Read if localstorage changed for logged in user session and logout the tabs
    const oldDealerID = localStorage.getItem('dealer_id')

    window.addEventListener('storage', () => {
      if(oldDealerID != localStorage.getItem('dealer_id')) {
        window.location.reload();
        // window.location.href = '/?'+ new Date().getTime();
      }
    });
    // Read if localstorage changed for logged in user session and logout the tabs

    const logout = async () => {
      axios
          .post(axios.defaults.baseUrl + "logout")
          .then((response) => {
            if (response) {
              localStorage.removeItem("auth_token");
              localStorage.removeItem("user");
              localStorage.removeItem("user_id");
              localStorage.removeItem("dealer");
              localStorage.removeItem("dealer_id");
              localStorage.removeItem("user_type");
              localStorage.removeItem("first_name");
              localStorage.removeItem("last_name");
              localStorage.removeItem("permissions");
              window.toast.fire({
                icon: "success",
                title: "User is logout successfully",
              });
              window.location.href = '/?'+ new Date().getTime();
            }
          })
          .catch(() => {
              localStorage.removeItem("auth_token");
              localStorage.removeItem("user");
              localStorage.removeItem("user_id");
              localStorage.removeItem("dealer");
              localStorage.removeItem("dealer_id");
              localStorage.removeItem("user_type");
            window.location.href = '/?'+ new Date().getTime();
          });
    }
    if (!localStorage.getItem("auth_token")) {
      window.location.href = '/?'+ new Date().getTime();
    }
    onMounted(() => {
      // Loader Fadeout
      $(".loaders").fadeOut();
      // Collapse Menu
      $(".menuwidth").click(function () {
        $(".menu-area").toggleClass("menu-full");
        $(".hide-item").toggleClass("show-item");
        $(".small-logo").toggleClass("show-logo");
        $(".main-contents").toggleClass("menu-full-body");
      });
      // select 2
      $(".select2").select2();
      //tabs responsive
      $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        console.log(e);
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
      });
      $(".display :checkbox").change(function () {
        $(this).parent().parent().toggleClass("selecet-bg");
      });
      $(".display :checkbox").change(function () {
        $(this).parent().parent().parent().toggleClass("selecet-bg");
      });
      $(".display :checkbox").change(function () {
        if ($(this).is(":checked")) {
          $(".carpost-area").addClass("show-car");
        } else {
          $(".carpost-area").removeClass("show-car");
        }
      });
      // Select 2 Two Way binding
      $(".select2")
          .select2()
          .on("select2:select", (e) => {
            const event = new Event("change", { bubbles: true, cancelable: true });
            e.params.data.element.parentElement.dispatchEvent(event);
          })
          .on("select2:unselect", (e) => {
            const event = new Event("change", { bubbles: true, cancelable: true });
            e.params.data.element.parentElement.dispatchEvent(event);
          });
    })
    document.title=window.AppName+' - '+Route.name
    Router.beforeEach((to, from, next) => {
      document.title=window.AppName+' - '+to.name
      next()
      $('.modal').modal('hide');
      // $(".modal-backdrop").remove();
    })
    return {logout, Route, Router}
  }
};
</script>
