import { createWebHistory, createRouter } from "vue-router"
import Login from "@/layouts/Login.vue"
import Dashboard from "@/views/Dashboard/Dashboard.vue"
import Account from "@/views/Account/Account.vue"
import ManageUsers from "@/views/ManageUsers/ManageUsers.vue"
import Inventory from "@/views/Inventory/Inventory.vue"
import NewInventory from "@/views/Inventory/NewInventory.vue"
import UsedInventory from "@/views/Inventory/UsedInventory.vue"
import RemovedInventory from "@/views/Inventory/RemovedInventory.vue"
import AddVehicle from "@/views/Inventory/AddVehicle.vue"
import EditVehicle from "@/views/Inventory/EditVehicle.vue"
import InventoryPricing from "@/views/Inventory/InventoryPricing.vue"
import InventoryPriceLogs from "@/views/Inventory/InventoryPriceLogs.vue"
import InventoryExportSettings from "@/views/Inventory/InventoryExportSettings.vue"
import InventoryOverlaySettings from "@/views/Inventory/InventoryOverlaySettings.vue"
import Rebates from "@/views/Inventory/Rebates.vue"
import CraigslistPostAds from "@/views/Posting/Craigslist/PostAds.vue"
import CraigslistViewAds from "@/views/Posting/Craigslist/ViewAds.vue"
import CraigslistSettings from "@/views/Posting/Craigslist/Settings.vue"
import FacebookPostAds from "@/views/Posting/Facebook/PostAds.vue"
import FacebookViewAds from "@/views/Posting/Facebook/ViewAds.vue"
import WebsiteFormLeads from "@/views/Leads/Website/WebsiteFormLeads.vue"
import WebsitePhoneLeads from "@/views/Leads/Website/WebsitePhoneLeads.vue"
import WebsiteTextLeads from "@/views/Leads/Website/WebsiteTextLeads.vue"
import WebsiteLeadReport from "@/views/Leads/Website/WebsiteLeadReport.vue"
import CraigslistPhoneLeads from "@/views/Leads/Craigslist/CraigslistPhoneLeads.vue"
import CraigslistTextLeads from "@/views/Leads/Craigslist/CraigslistTextLeads.vue"
import CraigslistClickLeads from "@/views/Leads/Craigslist/CraigslistClickLeads.vue"
import CraigslistOldClickLeads from "@/views/Leads/Craigslist/CraigslistOldClickLeads.vue"
import FacebookLeads from "@/views/Leads/Facebook/FacebookLeads.vue"
import TikTokLeads from "@/views/Leads/TikTok/TikTokLeads.vue"
import DealerSettings from "@/views/Website/DealerSettings.vue"
import Departments from "@/views/Website/Departments.vue"
import CustomersFeedback from "@/views/Website/CustomersFeedback.vue"
import Blog from "@/views/Website/Blog.vue"
import CmsPage from "@/views/Website/CmsPage.vue"
import MenuManager from "@/views/Website/MenuManager.vue"
import GeneralSettings from "@/views/Website/GeneralSettings.vue"
import Specials from "@/views/Website/Specials.vue"
import SpecialsImages from "@/views/Website/SpecialsImages.vue"
import Analytics from "@/views/Google/GoogleAnalytics/Analytics.vue"
import Channels from "@/views/Google/GoogleAnalytics/Channels.vue"
import Demographics from "@/views/Google/GoogleAnalytics/Demographics.vue"
import Referrals from "@/views/Google/GoogleAnalytics/Referrals.vue"
import Locations from "@/views/Google/GoogleAnalytics/Locations.vue"
import Search from "@/views/Google/GoogleAnalytics/Search.vue"
import Social from "@/views/Google/GoogleAnalytics/Social.vue"
import Device from "@/views/Google/GoogleAnalytics/Device.vue"
import GoogleMyBusiness from "@/views/Google/GoogleMyBusiness/GoogleMyBusiness.vue"
import GMBInsights from "@/views/Google/GoogleMyBusiness/Insights.vue"
import GMBReviews from "@/views/Google/GoogleMyBusiness/Reviews.vue"
import GMBPostAds from "@/views/Posting/GoogleMyBusiness/PostAds.vue"
import GMBViewAds from "@/views/Posting/GoogleMyBusiness/ViewAds.vue"
import GoogleCampaigns from "@/views/Google/GoogleAds/Campaigns.vue"
import GoogleAdgroups from "@/views/Google/GoogleAds/Adgroups.vue"
import GoogleAds from "@/views/Google/GoogleAds/Ads.vue"
import GoogleAutomatedAdGroups from "@/views/Google/GoogleAds/AutomatedAds/Adgroups.vue"
import GoogleAutomatedAds from "@/views/Google/GoogleAds/AutomatedAds/Ads.vue"
// import Automation from "@/views/Google/GoogleAds/Automation.vue"
import FacebookCampaigns from "@/views/Facebook/Campaigns.vue"
import FacebookAdSets from "@/views/Facebook/AdSets.vue"
import FacebookAds from "@/views/Facebook/Ads.vue"
import FacebookCatalog from "@/views/Facebook/Catalog.vue"
import FacebookSettings from "@/views/Facebook/Settings.vue"
import Support from "@/views/Support/Support.vue"

// Admin Routes


import ManageDealers from "@/views/Admin/ManageDealers.vue"
import AddDealer from "@/views/Admin/AddEditDealer.vue"
import EditDealer from "@/views/Admin/AddEditDealer.vue"
import TrackingNumber from "@/views/Admin/TrackingNumber.vue"
import MarketPlaces from "@/views/Admin/MarketPlaces.vue"

// Admin Routes
let routes = [];
if (localStorage.getItem("user_type") == 'dealer') {
  routes = [
    {
      path: "/",
      name: "Login",
      component: Login
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        pageTitle: "Dashboard",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/account",
      name: "Account",
      component: Account,
      meta: {
        pageTitle: "Account",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Account',
          url: '/dashboard/account',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/manage-users",
      name: "ManageUsers",
      component: ManageUsers,
      meta: {
        pageTitle: "Manage Users",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Manage Users',
          url: '/dashboard/manage-users',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/:search?",
      name: "Inventory",
      component: Inventory,
      meta: {
        pageTitle: "Inventory",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory/:search?',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/used-inventory/:search?",
      name: "UsedInventory",
      component: UsedInventory,
      meta: {
        pageTitle: "Used Inventory",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Used Inventory',
          url: '/dashboard/inventory/used-inventory',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/new-inventory/:search?",
      name: "NewInventory",
      component: NewInventory,
      meta: {
        pageTitle: "New Inventory",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'New Inventory',
          url: '/dashboard/inventory/new-inventory',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/removed-inventory",
      name: "RemovedInventory",
      component: RemovedInventory,
      meta: {
        pageTitle: "Removed Inventory",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Removed Inventory',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/add-vehicle",
      name: "AddVehicle",
      component: AddVehicle,
      meta: {
        pageTitle: "Add Vehicle",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'AddVehicle',
          url: '/dashboard/inventory/add-vehicle',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/edit-vehicle/:id",
      name: "EditVehicle",
      component: EditVehicle,
      meta: {
        pageTitle: "Edit Vehicle",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'EditVehicle',
          url: '/dashboard/inventory/edit-vehicle',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/pricing",
      name: "InventoryPricing",
      component: InventoryPricing,
      meta: {
        pageTitle: "Inventory Pricing",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Inventory Pricing',
          url: '/dashboard/inventory/pricing',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/price-logs",
      name: "InventoryPriceLogs",
      component: InventoryPriceLogs,
      meta: {
        pageTitle: "Inventory Price Logs",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Price Logs',
          url: '/dashboard/inventory/price-logs',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/export-settings/:id?",
      name: "InventoryExportSettings",
      component: InventoryExportSettings,
      meta: {
        pageTitle: "Inventory Export Settings",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Export Settings',
          url: '/dashboard/inventory/export-settings',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/overlay-settings",
      name: "InventoryOverlaySettings",
      component: InventoryOverlaySettings,
      meta: {
        pageTitle: "Inventory Overlay Settings",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Overlay Settings',
          url: '/dashboard/inventory/overlay-settings',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/rebates",
      name: "Rebates",
      component: Rebates,
      meta: {
        pageTitle: "Rebates",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Inventory',
          url: '/dashboard/inventory',
          class: ''
        },
        {
          name: 'Rebates',
          url: '/dashboard/inventory/rebates',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/craigslist/post-ads",
      name: "CraigslistPostAds",
      component: CraigslistPostAds,
      meta: {
        pageTitle: "Post Craigslist Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Post Craigslist Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/craigslist/view-ads",
      name: "CraigslistViewAds",
      component: CraigslistViewAds,
      meta: {
        pageTitle: "Craigslist Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Craigslist Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/craigslist/settings",
      name: "CraigslistSettings",
      component: CraigslistSettings,
      meta: {
        pageTitle: "Craigslist Settings",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Craigslist Settings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/facebook/post-ads",
      name: "FacebookPostAds",
      component: FacebookPostAds,
      meta: {
        pageTitle: "Post Facebook Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Post Facebook Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/facebook/view-ads",
      name: "FacebookViewAds",
      component: FacebookViewAds,
      meta: {
        pageTitle: "Facebook Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/website-leads/form",
      name: "WebsiteFormLeads",
      component: WebsiteFormLeads,
      meta: {
        pageTitle: "Website Form Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website Form Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/website-leads/phone",
      name: "WebsitePhoneLeads",
      component: WebsitePhoneLeads,
      meta: {
        pageTitle: "Website Phone Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website Phone Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/website-leads/text",
      name: "WebsiteTextLeads",
      component: WebsiteTextLeads,
      meta: {
        pageTitle: "Website Text Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website Text Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/website-leads/report",
      name: "WebsiteLeadReport",
      component: WebsiteLeadReport,
      meta: {
        pageTitle: "Website Lead Report",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website Lead Report',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/craigslist-leads/phone",
      name: "CraigslistPhoneLeads",
      component: CraigslistPhoneLeads,
      meta: {
        pageTitle: "Craigslist Phone Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Craigslist Phone Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/craigslist-leads/text",
      name: "CraigslistTextLeads",
      component: CraigslistTextLeads,
      meta: {
        pageTitle: "Craigslist Text Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Craigslist Text Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/craigslist-leads/clicks",
      name: "CraigslistClickLeads",
      component: CraigslistClickLeads,
      meta: {
        pageTitle: "Craigslist Click Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Craigslist Click Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/craigslist-leads/clicks",
      name: "CraigslistOldClickLeads",
      component: CraigslistOldClickLeads,
      meta: {
        pageTitle: "Craigslist Old Click Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Craigslist Old Click Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/facebook-leads",
      name: "FacebookLeads",
      component: FacebookLeads,
      meta: {
        pageTitle: "Facebook Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/tiktok-leads",
      name: "TikTokLeads",
      component: TikTokLeads,
      meta: {
        pageTitle: "TikTok Leads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'TikTok Leads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/dealer-settings",
      name: "DealerSettings",
      component: DealerSettings,
      meta: {
        pageTitle: "Dealer Settings",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Dealer Settings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/departments",
      name: "Departments",
      component: Departments,
      meta: {
        pageTitle: "Department Hours",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website',
          url: '/dashboard/departments',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/customers-feedback",
      name: "CustomersFeedback",
      component: CustomersFeedback,
      meta: {
        pageTitle: "Customers Feedback",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website',
          url: '/dashboard/customers-feedback',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/blog",
      name: "Blog",
      component: Blog,
      meta: {
        pageTitle: "Blog",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website Blogs',
          url: '/dashboard/blog',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/cms-page",
      name: "CmsPage",
      component: CmsPage,
      meta: {
        pageTitle: "Cms Pages",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Website Pages',
          url: '/dashboard/cms-page',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/menu-manager",
      name: "MenuManager",
      component: MenuManager,
      meta: {
        pageTitle: "Menu Manager",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Menu Manager',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/general-settings",
      name: "GeneralSettings",
      component: GeneralSettings,
      meta: {
        pageTitle: "General Settings",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'General Settings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/specials",
      name: "Specials",
      component: Specials,
      meta: {
        pageTitle: "Specials",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Specials',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/specials/:id",
      name: "SpecialsImages",
      component: SpecialsImages,
      meta: {
        pageTitle: "Specials Images",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'SpecialsImages',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics,
      meta: {
        pageTitle: "Analytics",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Analytics',
          url: '/dashboard/analytics',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/channels",
      name: "Channels",
      component: Channels,
      meta: {
        pageTitle: "Channels",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Channels',
          url: '/dashboard/channels',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/demographics",
      name: "Demographics",
      component: Demographics,
      meta: {
        pageTitle: "Demographics",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Demographics',
          url: '/dashboard/demographics',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/referrals",
      name: "Referrals",
      component: Referrals,
      meta: {
        pageTitle: "Referrals",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Referrals',
          url: '/dashboard/referrals',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/locations",
      name: "Locations",
      component: Locations,
      meta: {
        pageTitle: "Locations",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Locations',
          url: '/dashboard/locations',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/search",
      name: "Search",
      component: Search,
      meta: {
        pageTitle: "Search",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Search',
          url: '/dashboard/search',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/social",
      name: "Social",
      component: Social,
      meta: {
        pageTitle: "Social",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Social',
          url: '/dashboard/social',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/device",
      name: "Device",
      component: Device,
      meta: {
        pageTitle: "Device",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Device',
          url: '/dashboard/device',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/google-my-business",
      name: "GoogleMyBusiness",
      component: GoogleMyBusiness,
      meta: {
        pageTitle: "Google My Business",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google My Business',
          url: '/dashboard/google-my-business',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/google-my-business/insights/:id",
      name: "GMBInsights",
      component: GMBInsights,
      meta: {
        pageTitle: "Google My Business Insights",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google My Business',
          url: '/dashboard/google-my-business',
          class: ''
        },
        {
          name: 'Insights',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/google-my-business/reviews/:id",
      name: "GMBReviews",
      component: GMBReviews,
      meta: {
        pageTitle: "Google My Business Reviews",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google My Business',
          url: '/dashboard/google-my-business',
          class: ''
        },
        {
          name: 'Reviews',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/google-my-business/post-ads",
      name: "GMBPostAds",
      component: GMBPostAds,
      meta: {
        pageTitle: "Google My Business Posting",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google My Business Posting',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/posting/google-my-business/view-ads",
      name: "GMBViewAds",
      component: GMBViewAds,
      meta: {
        pageTitle: "Google My Business Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google My Business Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/google-ads/campaigns",
      name: "GoogleCampaigns",
      component: GoogleCampaigns,
      meta: {
        pageTitle: "Google Campaigns",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google Campaigns',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/google-ads/adgroups/:id",
      name: "GoogleAdgroups",
      component: GoogleAdgroups,
      meta: {
        pageTitle: "Google Adgroups",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google Adgroups',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/google-ads/ads/:id",
      name: "GoogleAds",
      component: GoogleAds,
      meta: {
        pageTitle: "Google Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/automated-ads/campaign",
      name: "GoogleAutomatedAdGroups",
      component: GoogleAutomatedAdGroups,
      meta: {
        pageTitle: "Google Automated Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google Automated Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/automated-ads/ads/:id",
      name: "GoogleAutomatedAds",
      component: GoogleAutomatedAds,
      meta: {
        pageTitle: "Google Automated Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google Automated Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    // {
    //   path: "/dashboard/automation",
    //   name: "Automation",
    //   component: Automation,
    //   meta: {
    //     pageTitle: "Automation",
    //     breadCrumbs: [{
    //       name: 'Dashboard',
    //       url: '/dashboard',
    //       class: ''
    //     },
    //     {
    //       name: 'Google',
    //       url: '/dashboard/automation',
    //       class: 'active'
    //     }]
    //   }
    // },
    {
      path: "/dashboard/facebook/catalog",
      name: "FacebookCatalog",
      component: FacebookCatalog,
      meta: {
        pageTitle: "Facebook Catalog",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Catalog',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/facebook/campaigns",
      name: "FacebookCampaigns",
      component: FacebookCampaigns,
      meta: {
        pageTitle: "Facebook Campaigns",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Campaigns',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/facebook/adsets/:id",
      name: "FacebookAdSets",
      component: FacebookAdSets,
      meta: {
        pageTitle: "Facebook AdSets",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Adsets',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/facebook/ads/:id",
      name: "FacebookAds",
      component: FacebookAds,
      meta: {
        pageTitle: "Facebook Ads",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Ads',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/facebook/settings",
      name: "FacebookSettings",
      component: FacebookSettings,
      meta: {
        pageTitle: "Facebook Settings",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Facebook Settings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/support",
      name: "Support",
      component: Support,
      meta: {
        pageTitle: "Support",
        breadCrumbs: [{
          name: 'Dashboard',
          url: '/dashboard',
          class: ''
        },
        {
          name: 'Google',
          url: '/dashboard/support',
          class: 'active'
        }]
      }
    },
    {
      path: '/:pathMatch(.*)*',
      component: Login
    }
  ];
} else {
  routes = [
    {
      path: "/",
      name: "Login",
      component: Login
    },
    {
      path: "/dashboard/manage-dealers",
      name: "ManageDealers",
      component: ManageDealers,
      meta: {
        pageTitle: "Manage Dealers",
        breadCrumbs: [
        {
          name: 'Manage Dealers',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/add-dealer",
      name: "AddDealer",
      component: AddDealer,
      meta: {
        pageTitle: "Add Dealer",
        breadCrumbs: [
        {
          name: 'Manage Dealers',
          url: '/dashboard/manage-dealers',
          class: ''
        },
        {
          name: 'Add Dealer',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/edit-dealer/:id",
      name: "EditDealer",
      component: EditDealer,
      meta: {
        pageTitle: "Edit Dealer",
        breadCrumbs: [
        {
          name: 'Manage Dealers',
          url: '/dashboard/manage-dealers',
          class: ''
        },
        {
          name: 'Update Dealer',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/account",
      name: "Account",
      component: Account,
      meta: {
        pageTitle: "Account",
        breadCrumbs: [{
          name: 'Manage Dealers',
          url: '/dashboard/manage-dealers',
          class: ''
        },
        {
          name: 'Account',
          url: '/dashboard/account',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/inventory/export-settings/:id?",
      name: "InventoryExportSettings",
      component: InventoryExportSettings,
      meta: {
        pageTitle: "Inventory Export Settings",
        breadCrumbs: [{
          name: 'Manage Dealers',
          url: '/manage-dealers',
          class: ''
        },
        {
          name: 'Export Settings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/manage-marketplaces",
      name: "MarketPlaces",
      component: MarketPlaces,
      meta: {
        pageTitle: "MarketPlaces",
        breadCrumbs: [{
          name: 'Manage Dealers',
          url: '/manage-dealers',
          class: ''
        },
        {
          name: 'Marketplaces',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard/tracking-number/:type/:id",
      name: "TrackingNumber",
      component: TrackingNumber,
      meta: {
        pageTitle: "Tracking Number",
        breadCrumbs: [{
          name: 'Manage Dealers',
          url: '/manage-dealers',
          class: ''
        },
        {
          name: 'Tracking Number',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: '/:pathMatch(.*)*',
      component: Login
    }
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router