<template>
  <Dashboard>
    <div class="table-area addpage-table">
      <!-- action-btn -->
        <div class="d-flex justify-content-start">
          <div class="dropdown navbar action-drop">
            <a
                class="action-btn dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Bulk Action
            </a>

            <div
                class="dropdown-menu dropdown-menu-sm-right"
                aria-labelledby="dropdownMenuLink"
            >
              <a class="dropdown-item" href="#" @click.prevent="openPriceModal"
              ><span class="iconimg"
              ><img
                  class="img-fluid"
                  src="@/assets/img/money-bill-solid.svg"
                  alt=""
              /></span>
                Adjust Prices</a
              >

              <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="openRemoveSpecialPricesModal"
              ><span class="iconimg"
              ><img
                  class="img-fluid"
                  src="@/assets/img/dollar-sign-solid.svg"
                  alt=""
              /></span>
                Remove Special Prices</a
              >

              <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="openRemoveSelectedVehiclesModal"
              ><span class="iconimg"
              ><img
                  class="img-fluid"
                  src="@/assets/img/trash-alt-solid.svg"
                  alt=""
              /></span>
                Delete Selected</a
              >
            </div>
          </div>
        </div>
      <!-- action-btn -->
      <!-- InventoryPricing-Table -->
      <table id="inventory_table" class="display">
        <thead>
        <tr>
          <th style="width:50px"></th>
          <th>Year</th>
          <th>Make</th>
          <th>Model</th>
          <th>Engine</th>
          <th>Stock</th>
          <th>MSRP</th>
          <th>Price</th>
          <th>Special Price</th>
        </tr>
        </thead>
        <tbody></tbody>
      </table>
      <!-- Update Prices Modal -->
      <div
          class="modal select-modal fade"
          id="updatePriceModal"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Adjust Prices
              </h5>
              <button
                  type="button"
                  class="close"
                  @click="closePriceModal"
                  aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form class="myform">
              <input type="hidden" />
              <div class="modal-body">
                <div class="form-row">
                  <div class="form-group col-md-12" id="userType">
                    <label for="inputEmail4">Price type</label>
                    <select class="form-control" v-model="price_type">
                      <option value="">Select</option>
                      <option value="msrp">MSRP</option>
                      <option value="price">Price</option>
                      <option value="special_price">Special Price</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12" id="userType">
                    <label for="inputEmail4">Addjustment type</label>
                    <select class="form-control" v-model="adjustment_type">
                      <option value="">Select</option>
                      <option value="plus">Add</option>
                      <option value="minu">Subtract</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Amount to adjust</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Amount to adjust"
                        v-model="amount_to_adjust"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="cus-btn text-right">
                  <button
                      type="button"
                      @click="closePriceModal"
                      class="cancle-btn"
                  >
                    Back
                  </button>
                  <button
                      type="button"
                      class="send-btn"
                      @click.prevent="updatePricesInBulk"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Update Prices Modal -->
      <!-- Remove Special Prices Modal -->
      <div
          class="modal fade"
          id="removeSpecialPrices"
          tabindex="-1"
          aria-labelledby="removeSpecialPricesLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="delete-modal">
                <h2>Are you sure, you want to Remove Special Prices?</h2>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" data-dismiss="modal" class="cancle-btn">
                  Cancel
                </button>
                <button
                    type="button"
                    class="send-btn"
                    @click="removeSpecialPrices"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Remove Special Prices Modal -->
      <!-- Remove Vehicle Modal -->
      <div
          class="modal fade"
          id="removeVehicleModal"
          tabindex="-1"
          aria-labelledby="removeVehicleModal"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="delete-modal">
                <h2>Are you sure, you want to delete selected vehicles?</h2>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" data-dismiss="modal" class="cancle-btn">
                  Cancel
                </button>
                <button
                    type="button"
                    class="send-btn"
                    @click="removeVehicleInBulk"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Remove Vehicle Modal -->
    </div>
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted, ref} from "vue";
import Dashboard from "../../layouts/Dashboard.vue";
import updateSingleVehiclePrice from "./../../composeables/updateSingleVehiclePrice";
import updateSingleVehicleSpecialPrice from "../../composeables/updateSingleVehicleSpecialPrice";

export default {
  components: {
    Dashboard
  },
  setup() {
    let price_type = ref("");
    let adjustment_type = ref("");
    let amount_to_adjust = ref("");
    let pricingtableObject = ref("");
    var vehicle_to_update_price = [];

    $(document).on('click', 'input.updatepricecheckbox', function() {
      if($(this).is(':checked')) {
        vehicle_to_update_price.push($(this).data('id'));
      }else {
        var index = vehicle_to_update_price.indexOf($(this).data('id'));
        if (index !== -1) {
          vehicle_to_update_price.splice(index, 1);
        }
      }
    });

    $(document).on('blur', 'input.updatesinglespecialprice', function(){
      handleUpdateSpecialPriceRequest($(this).data("id"), $(this).val());
    });

    $(document).on('blur', 'input.updatesingleprice', function(){
      handleUpdatePriceRequest($(this).data("id"), $(this).val());
    });

    const getInventory = async () => {
      if ($.fn.DataTable.isDataTable("#inventory_table")) {
        $("#inventory_table").DataTable().clear().destroy();
      }
      await $("#inventory_table")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getInventoryPricing",
              data: function (d) {
                d.vehicleToUpdatePrice = vehicle_to_update_price.join(",");
              },
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "checkbox" },
              { data: "year" },
              { data: "make" },
              { data: "model" },
              { data: "engine" },
              { data: "stock_no" },
              { data: "msrp" },
              { data: "price" },
              { data: "special_price" },
            ],
            order: [],
            columnDefs: [
              {targets: [0], orderable: false }
            ]
          });
    }

    const handleUpdatePriceRequest = async (inventory_id, inventory_price) => {
      const { status, loadUpdateSingVehiclePriceRequest } = updateSingleVehiclePrice(inventory_id, inventory_price);
      loadUpdateSingVehiclePriceRequest();
      if (status) {
        window.toast.fire({
          icon: "success",
          title: "Price Updated.",
        });
        //getInventory();
      } else {
        window.toast.fire({
          icon: "error",
          title: "Something went wrong, Please try again.",
        });
      }
    }

    const handleUpdateSpecialPriceRequest = async (inventory_id, inventory_special_price) => {
      const { status, loadUpdateSingVehicleSpeicalPriceRequest } = updateSingleVehicleSpecialPrice(inventory_id, inventory_special_price);
      loadUpdateSingVehicleSpeicalPriceRequest();
      if (status) {
        window.toast.fire({
          icon: "success",
          title: "Special Price Updated.",
        });
      } else {
        window.toast.fire({
          icon: "error",
          title: "Something went wrong, Please try again.",
        });
      }
    }

    // Open Price Modal
    const openPriceModal = () => {
      if (vehicle_to_update_price.length > 0) {
        $("#updatePriceModal").modal("show");
      } else {
        window.toast.fire({
          icon: "error",
          title: "No vehicle is checked to update price, Please try again.",
        });
      }
    }
    // Close Price Modal
    const closePriceModal = () => {
      price_type.value = "";
      adjustment_type.value = "";
      amount_to_adjust.value = "";
      $("#updatePriceModal").modal("hide");
    }
    // Update Prices in Bulk
    const updatePricesInBulk = async () => {
      if (price_type.value && adjustment_type.value && amount_to_adjust.value) {
        await axios
            .post(
                axios.defaults.baseUrl + "updatePricesInBulk",
                {
                  ids: vehicle_to_update_price,
                  price_type: price_type.value,
                  adjustment_type: adjustment_type.value,
                  amount_to_adjust: amount_to_adjust.value,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                  },
                }
            )
            .then((response) => {
              if (response.data.status == "success") {
                window.toast.fire({
                  icon: "success",
                  title: response.data.message,
                });
                vehicle_to_update_price = [];
                price_type.value = "";
                adjustment_type.value = "";
                amount_to_adjust.value = "";
                getInventory().then(() => {
                  $("#updatePriceModal").modal("hide");
                });
              }
            });
      } else {
        window.toast.fire({
          icon: "error",
          title: "Field values are missing, Please try again.",
        });
      }
    }
    // Open Remove Special Prices Modal
    const openRemoveSpecialPricesModal = () => {
      if (vehicle_to_update_price.length > 0) {
        $("#removeSpecialPrices").modal("show");
      } else {
        window.toast.fire({
          icon: "error",
          title:
              "No vehicle is checked to remove special price, Please try again.",
        });
      }
    }
    // Remove Special Prices in Bulk
    const removeSpecialPrices = async () => {
      await axios
          .post(
              axios.defaults.baseUrl + "removeSpecialPricesInBulk",
              {
                ids: vehicle_to_update_price,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if (response.data.status == "success") {
              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });
              vehicle_to_update_price = [];
              getInventory().then(() => {
                $("#removeSpecialPrices").modal("hide");
              });
            }
          });
    };
    // Open Remove Vehicle Modal
    const openRemoveSelectedVehiclesModal = () => {
      if (vehicle_to_update_price.length > 0) {
        $("#removeVehicleModal").modal("show");
      } else {
        window.toast.fire({
          icon: "error",
          title: "No vehicle is checked to update price, Please try again.",
        });
      }
    }
    // Remove Vehicle Request in Bulk
    const removeVehicleInBulk = async () => {
      await axios
          .post(
              axios.defaults.baseUrl + "removeVehicleInBulk",
              {
                ids: vehicle_to_update_price,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if (response.data.status == "success") {
              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });
              vehicle_to_update_price = [];
              getInventory().then(() => {
                $("#removeVehicleModal").modal("hide");
              });
            }
          });
    }
    onMounted(() => {
      getInventory();
    })
    return {
      price_type,
      adjustment_type,
      amount_to_adjust,
      pricingtableObject,
      vehicle_to_update_price,
      getInventory,
      handleUpdatePriceRequest,
      handleUpdateSpecialPriceRequest,
      openPriceModal,
      closePriceModal,
      updatePricesInBulk,
      openRemoveSpecialPricesModal,
      removeSpecialPrices,
      openRemoveSelectedVehiclesModal,
      removeVehicleInBulk
    };
  }
}
</script>
<style scope>
.addpage-table .dataTables_wrapper .dataTables_filter {
  margin-right: 125px;
}
.pricing_field {
  border: none;
  width: 100%;
}
.pagesSelect {
  width: auto !important;
}
.pricing-table .action-drop {
  position: relative;
}
.table_label {
  font-size: 14px;
  line-height: 1.5;
  color: #485563;
}
</style>