<template>
  <Dashboard>
    <!-- Tracking Number Form -->
    <TwilioTrackingForm></TwilioTrackingForm>
    <!-- Tracking Number Form -->
  </Dashboard>
</template>
<script>
// import $ from "jquery";

import Dashboard from "./../../layouts/Dashboard.vue";
import TwilioTrackingForm from "./../../components/TwilioTrackingNumbers/TwilioTrackingForm.vue";
export default {
  components: {
    Dashboard,
    TwilioTrackingForm,
  },
  setup() {
  },
};
</script>