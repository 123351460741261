<template>
  <Dashboard>
    <!-- Customers Feeback table -->
    <div class="table-area feedback-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#feedbackModal"
        >
          Add Feedback</a
        >
      </div>

      <table class="display" id="customersFeedback">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th style="width:200px">Vehicle</th>
            <th class="all" style="width:70px;">Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Customers Feeback table -->
    <!-- add Customer Feedback modal -->
    <div
      class="modal fade"
      id="feedbackModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Edit Feedback
            </h5>
            <h5 v-else class="modal-title" id="staticBackdropLabel">
              Add Feedback
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Customer Name:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="feedback.name"
                    placeholder=""
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Vehicle Model:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="feedback.car_model"
                    placeholder=""
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder=""
                    v-model="feedback.message"
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button
                v-if="editMode"
                type="button"
                @click="updateCustomerFeedback"
                class="send-btn"
              >
                Save
              </button>
              <button
                v-else
                type="button"
                @click="addFeedback"
                class="send-btn"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add Customer Feedback modal -->
    <!-- delete Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this User?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" data-dismiss="modal" class="cancle-btn">
                Cancel
              </button>
              <button
                type="button"
                @click="deleteCustomerFeedback"
                class="send-btn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted, ref} from "vue";
import Dashboard from "./../../layouts/Dashboard";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let feedback = ref({});
    let editMode = ref(false);
    // Close Modal
    const closeModal = () => {
      feedback.value = {};
      editMode.value = false;
      $("#feedbackModal").modal("hide");
      $("#deleteModal").modal("hide");
    };
    // Add Feedback
    const addFeedback = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "addCustomerFeedback",
          {
            feedback: feedback.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Customer Feedback added successfully...",
            });
            getCustomersFeedback();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };
    // Open Edit Modal
    $(document).on("click", ".editFeedback", function () {
      editMode.value = true;
      axios
        .get(
          axios.defaults.baseUrl + "getCustomerFeedback/" + $(this).data("id"),
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          feedback.value = response.data;
          $("#feedbackModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    });
    // Update Department
    const updateCustomerFeedback = async () => {
      await axios
        .post(
          axios.defaults.baseUrl +
            "updateCustomerFeedback/" +
            feedback.value.id,
          {
            feedback: feedback.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Customer Feedback updated successfully...",
            });
            getCustomersFeedback();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };
    // Open Delete Modal
    $(document).on("click", ".deleteFeedback", function () {
      axios
        .get(
          axios.defaults.baseUrl + "getCustomerFeedback/" + $(this).data("id"),
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          feedback.value = response.data;
          $("#deleteModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    });
    const deleteCustomerFeedback = async () => {
      axios
        .delete(
          axios.defaults.baseUrl +
            "deleteCustomerFeedback/" +
            feedback.value.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Customer Feedback deleted successfully...",
            });
            getCustomersFeedback();
            closeModal();
          } else {
            console.log("error");
            window.toast.fire({
              icon: "error",
              title: "Error, Please try again.",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    const getCustomersFeedback = async () => {
      if ($.fn.DataTable.isDataTable("#customersFeedback")) {
        $("#customersFeedback").DataTable().clear().destroy();
      }
      await $("#customersFeedback")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getCustomersFeedback",
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "name" },
              { data: "car_model" },
              { data: "action_links" },
            ],
            order: [],
            columnDefs: [
              {targets: [2], orderable: false }
            ]
          });
    }
    onMounted(() => {
      getCustomersFeedback();
    })
    return {
      feedback,
      editMode,
      closeModal,
      addFeedback,
      updateCustomerFeedback,
      deleteCustomerFeedback,
    };
  }
};
</script>
<style>
#customersFeedback tbody tr {
    position: relative;
}
</style>