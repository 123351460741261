<template>
  <Dashboard>

    <InsightCharts />

    <!-- Analytics-table -->
    <div class="table-area">
      <table class="display" id="facebookTable">
        <thead>
        <tr>
          <th class="all">Name</th>
          <th class="all">Preview</th>
          <th class="all" style="width: 100px;">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="Ad in allAds" :key="Ad">
          <td>{{ Ad.name }}</td>
          <td><a target="_blank" :href="Ad.preview_shareable_link">{{ Ad.preview_shareable_link }}</a></td>
          <td>{{ Ad.status }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { useRoute } from "vue-router";
import {onMounted, ref} from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
import InsightCharts from "./../../components/Facebook/InsightCharts.vue";
import axios from "axios";
export default {
  components: {
    Dashboard,
    InsightCharts
  },
  setup() {
    const route = useRoute();
    let allAds = ref([]);
    const getFacebookAds = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getFacebookAds/"+route.params.id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if(response.status) {
              if ($.fn.DataTable.isDataTable("#facebookTable")) {
                $("#facebookTable").DataTable().clear().destroy();
              }
              allAds.value = response.data.data.ads;
              setTimeout(() => {
                datatableInit();
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const datatableInit = async () => {
      $("#facebookTable").addClass("nowrap").dataTable({
        responsive: true,
        order: []
      });
    }

    onMounted( () => {
      datatableInit();
      getFacebookAds();
    })

    return { allAds }
  }
};
</script>