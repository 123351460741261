<template>
  <Dashboard>
    <!-- inventory-header -->
    <div class="inventory-header">
      <div class="row">
        <div id="postingHeaderFlex" class="col-md-12 col-lg-12">
          <div>Available Ads: {{ availableAds }} | Active Ads: {{activeAds}} | Expired Ads {{expiredAds}}</div>
          <div class="vehicle-area">
            <div class="search-box">
              <input
                type="search"
                class="form-control"
                placeholder="Search vehicles"
                v-model="keyword"
                @keyup="getInventoryForCraigslistPosting"
              />
              <img
                class="img-fluid search-icon"
                src="@/assets/img/search-solid.svg"
                alt=""
              />
            </div>
            <div class="action-link">
              <ul>
                <li>
                  <a
                    class="listingFilters all active"
                    @click="listingFilters('all')"
                    href="javascript:;"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="listingFilters price"
                    @click="listingFilters('price')"
                    href="javascript:;"
                    >No Price</a
                  >
                </li>
                <li>
                  <a
                    class="listingFilters imagelist"
                    @click="listingFilters('imagelist')"
                    href="javascript:;"
                    >No Images</a
                  >
                </li>
                <li>
                  <a
                    class="listingFilters posted"
                    @click="listingFilters('posted')"
                    href="javascript:;"
                    >Posted</a
                  >
                </li>
                <li>
                  <a
                    class="listingFilters unposted"
                    @click="listingFilters('unposted')"
                    href="javascript:;"
                    >Unposted</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- inventory-header -->

    <!-- Analytics-table -->
    <div class="table-area hide-select-search">
      <table id="postingTable" class="display">
        <thead>
          <tr>
            <th class="all" style="width: 40px"></th>
            <th style="width: 60px">Image</th>
            <th>Vehicle</th>
            <th style="width: 50px">Ads</th>
            <th class="all">Stock Number</th>
            <th>Last Posted</th>
            <th>Age</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Analytics-table -->

    <!-- carpost-area -->
    <div
      class="carpost-area"
      :class="vehiclesToPost.length > 0 ? 'show-car' : ''"
    >
      <div class="post-area">
        <ul>
          <li>
            <a href="#"
              ><img class="img-fluid" src="@/assets/img/car-icon.svg" alt="" />
              Vehicles : {{ vehiclesToPost.length }}</a
            >
          </li>
          <li>
            <a href="#"
              ><img class="img-fluid" src="@/assets/img/tag.svg" alt="" /> With
              Price
              <label class="switch">
                <input
                  v-if="withPrice == 'yes'"
                  type="checkbox"
                  checked="checked"
                  @change="togglePriceCheckbox('no')"
                />
                <input
                  v-else
                  type="checkbox"
                  @change="togglePriceCheckbox('yes')"
                />
                <span class="slider round"></span>
              </label>
            </a>
          </li>
          <li>
            <a href="#"
              ><img class="img-fluid" src="@/assets/img/location.svg" alt="" />
              Location : {{ locationsToPost.length }}</a
            >
          </li>
        </ul>
      </div>
      <div class="car-location">
        <ul>
          <li
            v-for="(selectedArea, index) in locationsToPost"
            :key="selectedArea"
          >
            <a href="javascript:;" @click="removeLocation(index)">{{
              selectedArea.text
            }}</a>
          </li>
        </ul>
      </div>
      <div class="select-area">
        <div class="form-group">
          <select
            class="form-control tags2 select2"
            v-model="selectedArea"
            @change="onChangeArea"
          >
            <option selected="selected" value="">Select Area</option>
            <option
              v-for="area in areas"
              :key="area"
              v-bind:value="{
                short: area.abbreviation,
                long: area.description,
              }"
            >
              {{ area.description }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <select class="form-control tags2 select2" v-model="selectedSubArea">
            <option selected="selected" value="">Select Sub Area</option>
            <option
              v-for="subArea in subAreas"
              :key="subArea"
              v-bind:value="{
                short: subArea.subareaabbreviation,
                long: subArea.subareadescription,
              }"
              class="hide hide_subareas"
              :class="subArea.abbreviation"
            >
              {{ subArea.subareadescription }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <input
            type="text"
            readonly
            placeholder="Please select date and time"
            id="postScheduleDate"
            class="form-control datesinglebackground"
            v-model="postScheduleDate"
          />
        </div>
        <div class="btns-group">
          <a class="cus-btn" href="javascript:;" @click="addNewLocation"
            >Add Location
            <img class="img-fluid" src="@/assets/img/plus-solid.svg" alt=""
          /></a>
        </div>

        <a class="cus-btn post" href="javascript:;" @click="postToCraigslist"
          >post</a
        >
      </div>
    </div>
    <!-- carpost-area -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import { onMounted, ref } from "vue";
import Dashboard from "./../../../layouts/Dashboard.vue";
import "daterangepicker";
import moment from "moment";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let keyword = ref("");
    let selectedArea = ref("");
    let selectedSubArea = ref("");
    let postScheduleDate = ref(moment().format("MM/DD/YYYY hh:mm A"));
    let areas = ref({});
    let subAreas = ref({});
    let filterType = ref("all");
    let locationsToPost = ref([]);
    let vehiclesToPost = ref([]);
    let withPrice = ref("yes");
    let availableAds = ref(0);
    let activeAds = ref(0);
    let expiredAds = ref(0);

    const datePickerInit = async () => {
      $("#postScheduleDate").daterangepicker(
        {
          timePicker: true,
          singleDatePicker: true,
          showDropdowns: true,
          autoApply: true,
          startDate: moment().format("MM/DD/YYYY hh:mm A"),
          endDate: moment().format("MM/DD/YYYY hh:mm A"),
          minDate: moment().format("MM/DD/YYYY hh:mm A"),
          minYear: parseInt(moment().year()),
          maxYear: parseInt(moment().add(10, "year").format("YYYY"), 10),
          locale: {
            format: "MM/DD/YYYY hh:mm A",
          },
        },
        function (start) {
          postScheduleDate.value = moment(start).format("MM/DD/YYYY hh:mm A");
        }
      );
    };

    const onChangeArea = () => {
      selectedSubArea.value = "";
      $(".hide_subareas").addClass("hide");
      if (selectedArea.value.short != "") {
        $(".hide_subareas." + selectedArea.value.short).removeClass("hide");
      }
      select2Init();
    };

    const removeLocation = (location) => {
      locationsToPost.value.splice(location, 1);
    };

    const togglePriceCheckbox = (price) => {
      withPrice.value = price;
    };
    const select2Init = () => {
      $(".select2").select2({
        templateResult: function (state) {
          let $opt = $(state.element);
          if ($opt.hasClass("hide")) {
            return null;
          }
          return state.text;
        },
      });
    };

    const listingFilters = (type) => {
      $(".listingFilters").removeClass("active");
      $(".listingFilters." + type).addClass("active");
      filterType.value = type;
      vehiclesToPost.value = [];
      getInventoryForCraigslistPosting();
    };

    const addNewLocation = () => {
      if (
        selectedArea.value.short == "" ||
        typeof selectedArea.value.short == "undefined"
      ) {
        window.toast.fire({
          icon: "error",
          title: "Please select an area",
        });
      } else {
        let subAreaText = "";
        let subAreaText2 = "blankempty";
        if (
          selectedSubArea.value.short != "" &&
          typeof selectedSubArea.value.short != "undefined"
        ) {
          subAreaText = ", " + selectedSubArea.value.long;
          subAreaText2 = selectedSubArea.value.short;
        }

        locationsToPost.value.push({
          area: selectedArea.value.short,
          subArea: subAreaText2,
          text: selectedArea.value.long + subAreaText,
        });
      }
    };

    $(document).on("click", "input.updatevehiclecheckbox", function () {
      if ($(this).is(":checked")) {
        vehiclesToPost.value.push($(this).data("id"));
      } else {
        var index = vehiclesToPost.value.indexOf($(this).data("id"));
        if (index !== -1) {
          vehiclesToPost.value.splice(index, 1);
        }
      }
    });

    const getInventoryForCraigslistPosting = async () => {
      if ($.fn.DataTable.isDataTable("#postingTable")) {
        $("#postingTable").DataTable().clear().destroy();
      }
      await $("#postingTable")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: axios.defaults.baseUrl + "getInventoryForCraigslistPosting",
            type: "post",
            data: function (d) {
              d.search["value"] = keyword.value;
              d.vehiclesToPost = vehiclesToPost.value.join(",");
              d.filterType = filterType.value;
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "checkbox" },
            { data: "image" },
            { data: "vehicle" },
            { data: "ads_count" },
            { data: "stock_no" },
            { data: "last_posted" },
            { data: "age" },
            { data: "price" },
          ],
          order: [],
          columnDefs: [{ targets: [0, 1, 3, 5], orderable: false }],
        });
    };

    const getCraigslistAreasSubareas = async () => {
      await axios
        .get(axios.defaults.baseUrl + "getCraigslistLocations", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          console.log(response.data);
          areas.value = response.data.areas;
          subAreas.value = response.data.subAreas;
          activeAds.value = response.data.activeAds;
          expiredAds.value = response.data.expiredAds;
          availableAds.value = response.data.availableAds;
        })
        .catch(function (error) {
          if (error) {
            console.log(error);
          }
        });
    };

    const postToCraigslist = async () => {
      if (locationsToPost.value.length < 1) {
        window.toast.fire({
          icon: "error",
          title: "Please select an area",
        });
        return false;
      }
      if (vehiclesToPost.value.length < 1) {
        window.toast.fire({
          icon: "error",
          title: "Please select a vehicle",
        });
        return false;
      }
      if (postScheduleDate.value == "") {
        window.toast.fire({
          icon: "error",
          title: "Please select a date",
        });
        return false;
      }
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "postToCraigslist",
          {
            vehicles: vehiclesToPost.value,
            locations: locationsToPost.value,
            with_price: withPrice.value,
            postScheduleDate: postScheduleDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          window.toast.fire({
            icon: response.data.status,
            title: response.data.data,
          });
          $("input.updatevehiclecheckbox").prop("checked", false);
          vehiclesToPost.value = [];
          locationsToPost.value = [];
          withPrice.value = "yes";
        });
      //window.showHideMainLoader(false);
    };

    onMounted(() => {
      getInventoryForCraigslistPosting();
      getCraigslistAreasSubareas();
      select2Init();
      datePickerInit();
    });

    return {
      getInventoryForCraigslistPosting,
      keyword,
      areas,
      subAreas,
      selectedArea,
      selectedSubArea,
      onChangeArea,
      listingFilters,
      addNewLocation,
      locationsToPost,
      vehiclesToPost,
      withPrice,
      togglePriceCheckbox,
      removeLocation,
      postToCraigslist,
      postScheduleDate,
      availableAds,
      activeAds,
      expiredAds
    };
  },
};
</script>
<style scoped>
#postingHeaderFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  #postingHeaderFlex {
    display: block;
  }
}
</style>