<template>
  <Dashboard>
    <!-- inventory-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input type="text" class="reportrange form-control" />
              <img
                class="img-fluid search-icon"
                src="@/assets/img/calender.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- inventory-header -->

    <!-- Overview -->
    <div class="overview-box">
      <div class="overviews">
          <p>Search Listing</p>
          <h3>{{ allMetrics.search }}</h3>
      </div>
      
      <div class="overviews">
          <p>Map Listing</p>
          <h3>{{ allMetrics.map }}</h3>
      </div>
      
      <div class="overviews">
          <p>Website Visits</p>
          <h3>{{ allMetrics.visit }}</h3>
      </div>
      
      <div class="overviews">
          <p>Directions</p>
          <h3>{{ allMetrics.direction }}</h3>
      </div>
      
      <div class="overviews">
          <p>Calls</p>
          <h3>{{ allMetrics.call }}</h3>
      </div>
    </div>
    <!-- Overview -->

    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-12">
          <div class="mygraph">
            <h4>Where customers view your business on Google</h4>
            <p>The Google services that customers use to find your business</p>
            <listingMetricsChart
              id="chart11"
              ref="myChart"
              width="100%"
              height="320"
              type="line"
              :options="listingMetricsChartOptions"
              :series="listingMetricsChartSeries"
            ></listingMetricsChart>
          </div>
        </div>

        <div class="col-md-12">
          <div class="mygraph">
            <h4>Customer actions</h4>
            <p>The most common actions that customers take on your listing</p>
            <customerActionsMetricsChart
              id="chart12"
              ref="myChart"
              width="100%"
              height="320"
              type="line"
              :options="customerActionsMetricsChartOptions"
              :series="customerActionsMetricsChartSeries"
            ></customerActionsMetricsChart>
          </div>
        </div>

        <div class="col-md-12">
          <div class="mygraph">
            <h4>Customer actions</h4>
            <p>The most common actions that customers take on your listing</p>
            <callsMetricsChart
              id="chart13"
              ref="myChart"
              width="100%"
              height="350"
              type="bar"
              :options="callsMetricsChartOptions"
              :series="callsMetricsChartSeries"
            ></callsMetricsChart>
          </div>
        </div>
      </div>
    </div>
    <!-- google-graph -->
  </Dashboard>
</template>
<script>
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import { useRoute } from "vue-router";
import Dashboard from "./../../../layouts/Dashboard.vue";
import getGmbRefreshToken from "./../../../composeables/Google/getGmbRefreshToken";
import $ from "jquery";
import moment from "moment";
import "daterangepicker";
import { onMounted, ref } from "vue";
export default {
  components: {
    Dashboard,
    listingMetricsChart: VueApexCharts,
    customerActionsMetricsChart: VueApexCharts,
    callsMetricsChart: VueApexCharts,
  },
  setup() {
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());


    let listingMetricsChartSeries = ref([]);
    let listingMetricsChartOptions = ref({});

    let customerActionsMetricsChartSeries = ref([]);
    let customerActionsMetricsChartOptions = ref({});

    let callsMetricsChartSeries = ref([]);
    let callsMetricsChartOptions = ref({});

    let allMetrics = ref({});

    const route = useRoute();
    const { token, loadToken } = getGmbRefreshToken();
    loadToken().then(() => {
      if (!token.value) {
        let googleUrl = null;
        axios
          .post(
            axios.defaults.baseUrl + "googleAuthentication",
            {
              google_auth: "",
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            }
          )
          .then((response) => {
            googleUrl = response.data.data;
            window.location.href = googleUrl;
          });
      }
    });

    const getGMBInsights = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "getGMBLocationInsights",
          {
            dealer_id: localStorage.getItem("dealer_id"),
            gmb_location: route.params.id,
            daterangestart: startDate.value,
            daterangeend: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.status) {

            allMetrics.value = response.data.data.totalMetrics;

            listingMetricsChartOptions.value = {
              labels: response.data.data.dateLabels,
              colors: ["#FD6D54", "#FCD932", "#46D9AF", "#6497EB"],
              chart: {
                height: 300,
                type: "line",
                stacked: false,
              },
              stroke: {
                width: [2, 2, 2, 2],
                curve: "smooth",
              },
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },

              fill: {
                opacity: [1, 1, 1, 1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
              },
              yaxis: {
                min: 0,
              },
              tooltip: {
                shared: true,
                intersect: false,
                y: {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(0) + "";
                    }
                    return y;
                  },
                },
              },
            };

            customerActionsMetricsChartOptions.value = {
              labels: response.data.data.dateLabels,
              colors: ["#FD6D54", "#FCD932", "#46D9AF"],
              chart: {
                height: 300,
                type: "line",
                stacked: false,
              },
              stroke: {
                width: [2, 2, 2],
                curve: "smooth",
              },
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },

              fill: {
                opacity: [1, 1, 1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
              },
              yaxis: {
                min: 0,
              },
              tooltip: {
                shared: true,
                intersect: false,
                y: {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(0) + "";
                    }
                    return y;
                  },
                },
              },
            };

            callsMetricsChartOptions.value = {
              chart: {
                type: "bar",
                height: 350,
              },
              colors: ["#46D9AF"],
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "25%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: response.data.data.dateLabels,
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return " " + val + "";
                  },
                },
              },
            };

            listingMetricsChartSeries.value = [
              {
                name: "Listing on Map Desktop",
                type: "line",
                data: response.data.data.mapListingMetricsDesktop.map(Number),
              },
              {
                name: "Listing on Map Mobile",
                type: "line",
                data: response.data.data.mapListingMetricsMobile.map(Number),
              },
              {
                name: "Listing on Search Desktop",
                type: "line",
                data: response.data.data.searchListingMetricsDesktop.map(Number),
              },
              {
                name: "Listing on Search Mobile",
                type: "line",
                data: response.data.data.searchListingMetricsMobile.map(Number),
              },
            ];

            customerActionsMetricsChartSeries.value = [
              {
                name: "Visit your website",
                type: "line",
                data: response.data.data.websiteActionsMetrics.map(Number),
              },
              {
                name: "Request directions",
                type: "line",
                data: response.data.data.drivingActionsMetrics.map(Number),
              },
              {
                name: "Call you",
                type: "line",
                data: response.data.data.callActionsMetrics.map(Number),
              },
            ];

            callsMetricsChartSeries.value = [
              {
                name: "Calls",
                data: response.data.data.callActionsMetrics.map(Number),
              },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });
      window.showHideMainLoader(false);
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const dateRangePickerInit = async () => {
      function cb(start, end) {
        startDate.value = start.format("YYYY-MM-DD");
        endDate.value = end.format("YYYY-MM-DD");
        $(".reportrange span").html(
          start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
        );
        getGMBInsights();
      }
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      dateRangePickerInit();
      /*setTimeout(() => {
        getGMBInsights();
      });*/
    });

    return {
      listingMetricsChartSeries,
      listingMetricsChartOptions,
      customerActionsMetricsChartSeries,
      customerActionsMetricsChartOptions,
      callsMetricsChartSeries,
      callsMetricsChartOptions,
      dateRangePickerInit,
      resetFilters,
      allMetrics
    };
  },
};
</script>