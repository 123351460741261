<template>
  <div>
    <!-- add user modal -->
    <div
        class="modal select-modal fade"
        id="addNewUserModal"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!editMode" class="modal-title" id="staticBackdropLabel">
              Add New User
            </h5>
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Edit User
            </h5>
            <button
                type="button"
                class="close"
                @click="closePopup()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <input type="hidden" v-model="id" />
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>First Name</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      v-model="first_name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Last Name</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      v-model="last_name"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label> Email</label>
                  <input
                      type="email"
                      class="form-control"
                      placeholder="Email Address"
                      v-model="email"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Password</label>
                  <input
                      type="password"
                      class="form-control"
                      placeholder="********"
                      v-model="password"
                  />
                </div>

                <div class="form-group col-md-12" id="userType">
                  <label>User type</label>
                  <select
                      class="form-control select2 user-type"
                      multiple
                      v-model="user_type"
                  >
                    <option
                        v-for="type in user_types"
                        :key="type.id"
                        :value="type.id"
                    >
                      {{ type.user_type }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-12">
                  <label>Permissions</label>
                  <select
                      class="form-control select2 user-permissions"
                      v-model="userPermission"
                      multiple
                  >
                    <option
                        v-for="permission in user_permissions"
                        :key="permission.id"
                        :value="permission.id"
                    >
                      {{ permission.user_permission }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Back
                </button>
                <button
                    v-if="editMode"
                    type="button"
                    @click="handleUserUpdateRequest"
                    class="send-btn"
                >
                  Submit
                </button>
                <button
                    v-else
                    type="button"
                    @click="handleUserCreateRequest"
                    class="send-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add user modal -->
    <!-- delete Modal -->
    <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <h2></h2>
              <h2>Are you sure you want to delete this User?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <form @submit.prevent="handleUserDeleteRequest">
                <input type="hidden" v-model="id" />
                <button type="button" data-dismiss="modal" class="cancle-btn">
                  Cancel
                </button>
                <button type="submit" class="send-btn">Delete</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete user modal -->
  </div>
</template>
<script>
import {ref} from "vue";
import $ from "jquery";
import axios from "axios";
import getUserTypes from "./../../composeables/getUserTypes";
import getUserPermissions from "./../../composeables/getUserPermissions";
export default {
  props: {
    getUsersRequest: Function
  },
  setup(props) {
    const first_name = ref("");
    const last_name = ref("");
    const email = ref("");
    const password = ref("");
    let user_type = ref([]);
    let userPermission = ref([]);
    let editMode = ref(false);
    let id = ref("");
    // Load User Types
    const { user_types, loadUserTypes } = getUserTypes();
    loadUserTypes();
    // Load User Permissions
    const { user_permissions, laodUserPermissions } = getUserPermissions();
    laodUserPermissions();
    const closePopup = () => {
      first_name.value = "";
      last_name.value = "";
      email.value = "";
      user_type.value = [];
      userPermission.value = [];
      id.value = "";
      $("#addNewUserModal").modal("hide");
      // Unselect all options
      $(".user-type").val(null).trigger("change");
      $(".user-permissions").val(null).trigger("change");
      editMode.value = false;
    };
    // Create User Request
    const handleUserCreateRequest = async () => {
      window.showHideMainLoader(true);
      
      await axios
          .post(
              axios.defaults.baseUrl + "user",
              {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                user_type: user_type.value,
                password: password.value,
                permissions: userPermission.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            if (!response.data.success) {
              if (response.data.message.user_permission) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.user_permission[0],
                });
              }
              if (response.data.message.user_type) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.user_type[0],
                });
              }
              if (response.data.message.password) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.password[0],
                });
              }
              if (response.data.message.email) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.email[0],
                });
              }
              if (response.data.message.last_name) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.last_name[0],
                });
              }
              if (response.data.message.first_name) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.first_name[0],
                });
              }
            } else {
              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });
              closePopup();
              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });
              props.getUsersRequest();
            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again",
              });
            }
          });
    };
    // Update User Request
    const handleUserUpdateRequest = async () => {
      window.showHideMainLoader(true);
      await axios
          .patch(
              axios.defaults.baseUrl + "user/" + id.value,
              {
                dealer_id: localStorage.getItem("dealer_id"),
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                user_type: user_type.value,
                password: password.value,
                confirm_password: password.value,
                permissions: userPermission.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            if (response) {
              if (!response.data.success) {
                if (response.data.message.password) {
                  window.toast.fire({
                    icon: "error",
                    title: response.data.message.password[0],
                  });
                }
                if (response.data.message.confirm_password) {
                  window.toast.fire({
                    icon: "error",
                    title: response.data.message.confirm_password[0],
                  });
                }
              }else {
                closePopup();
                window.toast.fire({
                  icon: "success",
                  title: response.data.message,
                });
                editMode.value = false;
                props.getUsersRequest();
              }
            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            console.log(error);
          });
    };
    // Delete User Request
    const handleUserDeleteRequest = async () => {
      window.showHideMainLoader(true);
      await axios
          .delete(
              axios.defaults.baseUrl + "user/" + id.value,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            if (response) {
              closePopup();
              editMode.value = false;
              props.getUsersRequest();
            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            console.log(error);
          });
    };
    // Open Edit Modal
    $(document).on("click", ".editMode", function () {
      id.value = $(this).data("id");
      editMode.value = true;
      axios
          .get(axios.defaults.baseUrl + "getUser/" + id.value, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          })
          .then(function (response) {
            first_name.value = response.data.first_name;
            last_name.value = response.data.last_name;
            email.value = response.data.email;
            user_type.value = response.data.user_type;
            userPermission.value = response.data.user_permissions;
            let userTypeSelect = $(".user-type").select2({
              closeOnSelect : false,
              allowHtml: true,
              allowClear: true,
              tags: true
              });
            userTypeSelect.val(user_type.value).trigger("change");
            let userPermissionsSelect = $(".user-permissions").select2({
              closeOnSelect : false,
              allowHtml: true,
              allowClear: true,
              tags: true
              });
            userPermissionsSelect.val(userPermission.value).trigger("change");
            $("#addNewUserModal").modal("show");
          })
          .catch(function (error) {
            console.log(error);
          });
    })
    // Open Delete Modal
    $(document).on("click", ".deleteModal", function () {
      id.value = $(this).data("id");
      $("#deleteModal").modal("show");
    })
    //this.$emit("getUsers")
    return {
      first_name,
      last_name,
      email,
      password,
      user_type,
      userPermission,
      user_types,
      user_permissions,
      id,
      editMode,
      closePopup,
      handleUserCreateRequest,
      handleUserUpdateRequest,
      handleUserDeleteRequest,
    };
  },
};
</script>
<style>
.select2-results__option:before {
  content: "";
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 20px;
  vertical-align: middle;
}
.select2-results__option.select2-results__option--selected:before {
  font-family:fontAwesome;
  content: "\f00c";
  color: #fff;
  background-color: #2A4385;
  border: 0;
  display: inline-block;
  padding-left: 3px;
}
.select2-container--default .select2-results__option.select2-results__option--selected {
	background-color: #fff;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #eaeaeb;
	color: #272727;
}
.select2-container--default .select2-selection--multiple {
	margin-bottom: 10px;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	border-radius: 4px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #2A4385;
	border-width: 2px;
}
.select2-container--default .select2-selection--multiple {
	border-width: 2px;
}
.select2-container--open .select2-dropdown--below {
	
	border-radius: 6px;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);

}
.select2-selection .select2-selection--multiple:after {
	content: 'hhghgh';
}
/* select with icons badges single*/
.select-icon .select2-selection__placeholder .badge {
	display: none;
}
.select-icon .placeholder {
	display: none;
}
.select-icon .select2-results__option:before,
.select-icon .select2-results__option[aria-selected=true]:before {
	display: none !important;
	/* content: "" !important; */
}
.select-icon  .select2-search--dropdown {
	display: none;
}
</style>