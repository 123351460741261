<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Device Category</h4>
            <apexchart
              width="100%"
              height="300"
              type="donut"
              :options="deviceCategoryChartOptions"
              :series="deviceCategorySeries"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Mobile Device</h4>
            <apexchart
              width="100%"
              height="300"
              type="donut"
              :options="deviceNameChartOptions"
              :series="deviceNamesSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- google-graph -->
    <!-- channel-table -->
    <div class="channel-tabel">
      <div class="row">
        <div class="col-lg-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="deviceCategoryTable">
              <thead>
                <tr>
                  <th>Device Category</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th>New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in deviceCategoryTable" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>

        <div class="col-lg-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="deviceNameTable">
              <thead>
                <tr>
                  <th>Device Model</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th>New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in deviceNameTable" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- channel-table -->
    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Browser</h4>
            <apexchart
              width="100%"
              height="300"
              type="donut"
              :options="browserChartOptions"
              :series="browserSeries"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Operating System (OS)</h4>
            <apexchart
              width="100%"
              height="300"
              type="donut"
              :options="osChartOptions"
              :series="osSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- google-graph -->
    <!-- channel-table -->
    <div class="channel-tabel">
      <div class="row">
        <div class="col-lg-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="browserTable">
              <thead>
                <tr>
                  <th>Device Category</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th>New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in browserTable" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>

        <div class="col-lg-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="osTable">
              <thead>
                <tr>
                  <th>Device Model</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th>New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in osTable" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- channel-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    let deviceCategorySeries = ref([]);
    let deviceCategoryChartOptions = ref({});
    let deviceNamesSeries = ref([]);
    let deviceNameChartOptions = ref({});
    let browserSeries = ref([]);
    let browserChartOptions = ref({});
    let osSeries = ref([]);
    let osChartOptions = ref({});

    let deviceCategory = ref([]);
    let deviceCategoryError = ref("");
    let deviceCategoryTable = ref([]);
    let deviceCategoryTableError = ref("");
    let deviceName = ref([]);
    let deviceNameError = ref("");
    let deviceNameTable = ref([]);
    let deviceNameTableError = ref("");
    let browser = ref([]);
    let browserError = ref("");
    let browserTable = ref([]);
    let browserTableError = ref("");
    let os = ref([]);
    let osError = ref("");
    let osTable = ref([]);
    let osTableError = ref("");

    const getDeviceCategory = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getDeviceCategory",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          deviceCategory.value = response.data;

          deviceCategorySeries.value = deviceCategory.value.sessions;
          deviceCategoryChartOptions.value = {
            chart: {
              type: "donut",
            },
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            labels: deviceCategory.value.labels,
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((deviceCategoryError) => {
          deviceCategoryError.value = "No Sessions for Google Analytics found";
        });
    };

    const getDeviceCategoryTable = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getDeviceCategoryTable",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          deviceCategoryTable.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#deviceCategoryTable")) {
            $("#deviceCategoryTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#deviceCategoryTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((deviceCategoryTableError) => {
          deviceCategoryTableError.value =
            "No Sessions for Google Analytics found";
        });
    };

    const getDeviceName = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getDeviceName",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          deviceName.value = response.data;

          deviceNamesSeries.value = deviceName.value.sessions;
          deviceNameChartOptions.value = {
            chart: {
              type: "donut",
            },
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            labels: deviceName.value.labels,
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((deviceNameError) => {
          deviceNameError.value = "No Sessions for Google Analytics found";
        });
    };

    const getDeviceNameTable = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getDeviceNameTable",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          deviceNameTable.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#deviceNameTable")) {
            $("#deviceNameTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#deviceNameTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((deviceNameTableError) => {
          deviceNameTableError.value = "No Sessions for Google Analytics found";
        });
    };

    const getBrowser = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getBrowser",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          browser.value = response.data;

          browserSeries.value = browser.value.sessions;
          browserChartOptions.value = {
            chart: {
              type: "donut",
            },
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            labels: browser.value.labels,
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((browserError) => {
          browserError.value = "No Sessions for Google Analytics found";
        });
    };

    const getBrowserTable = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getBrowserTable",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          browserTable.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#browserTable")) {
            $("#browserTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#browserTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((browserTableError) => {
          browserTableError.value = "No Sessions for Google Analytics found";
        });
    };

    const getOs = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getOs",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          os.value = response.data;

          osSeries.value = os.value.sessions;
          osChartOptions.value = {
            chart: {
              type: "donut",
            },
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            labels: os.value.labels,
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((osError) => {
          osError.value = "No Sessions for Google Analytics found";
        });
    };

    const getOsTable = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getOsTable",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          osTable.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#osTable")) {
            $("#osTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#osTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((osTableError) => {
          osTableError.value = "No Sessions for Google Analytics found";
        });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getDeviceCategory();
      getDeviceCategoryTable();
      getDeviceName();
      getDeviceNameTable();
      getBrowser();
      getBrowserTable();
      getOs();
      getOsTable();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      $("#deviceCategoryTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      $("#deviceNameTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      $("#browserTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      $("#osTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      dateRangePickerInit();
    });

    return {
      deviceCategorySeries,
      deviceCategoryChartOptions,
      deviceCategory,
      deviceCategoryTable,
      deviceCategoryError,
      deviceCategoryTableError,
      deviceNamesSeries,
      deviceNameChartOptions,
      deviceName,
      deviceNameError,
      deviceNameTable,
      deviceNameTableError,
      browserSeries,
      browserChartOptions,
      browser,
      browserError,
      browserTable,
      browserTableError,
      osSeries,
      osChartOptions,
      os,
      osError,
      osTable,
      osTableError,
      resetFilters,
    };
  },
};
</script>
<style>
#deviceCategoryTable_wrapper,
#deviceNameTable_wrapper,
#browserTable_wrapper,
#osTable_wrapper {
  min-height: auto !important;
}
</style>