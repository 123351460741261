import { ref } from 'vue';
import axios from 'axios';
const getDealer = (id) => {
    const dealer = ref([]);
    const err = ref('');
    try {
        const loadDealer = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getDealer/" + id,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    dealer.value = response.data;
                })
                .catch((err) => {
                    err.value = 'No dealer Data found';
                });
        }
        return { dealer, err, loadDealer,  };
    } catch (err) {
        err.value = 'No dealer Data found';
    }
}
export default getDealer;