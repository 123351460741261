<template>
  <Dashboard>
    <!-- InventoryOverlaySettings -->
    <div class="craigslist-area">
      <form class="myform">
        <input type="file" style="display:none" id="craigslistOverlay" @change="onFileChangeCraigslistOverlay" />
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <h4>Craigslist Overlay</h4>
            <br />
            <div v-if="craigslistOverlayImage" class="craigslist-logo">
              <a href="#"
              ><img class="img-fluid" :src="craigslistOverlayImage" alt=""
              /></a>
            </div>
            <div class="alert-text">
              <small>
                <img class="img-fluid" src="@/assets/img/warning.svg" alt="" />Only png
                images allowed and required dimensions are 912x682</small
              >
            </div>
            <a class="uploadtn" href="javascript:;" @click="triggerFileSelect">Upload New Overlay Image</a>
            <button type="submit" class="send-btn" style="width: 100%">Update Craigslist Overlay</button>
          </div>
        </div>
      </form>
    </div>
    <!-- InventoryOverlaySettings -->
  </Dashboard>
</template>
<script>
import Dashboard from "./../../layouts/Dashboard.vue";
import $ from "jquery";
import axios from "axios";
import getDealer from "../../composeables/getDealer";
import {ref} from "vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let craigslistOverlayImage = ref('');
    // Get Dealer Data
    let { dealer, err, loadDealer } = getDealer(
        localStorage.getItem("dealer_id")
    );
    loadDealer().then(() => {
      craigslistOverlayImage.value = dealer.value.craigslist_overlay;
    });
    //console.log(dealer);
    //craigslistOverlayImage = dealer.craigslist_overlay;
    const triggerFileSelect = () => {
      $("#craigslistOverlay").trigger("click");
    }
    const onFileChangeCraigslistOverlay = async (element) => {
      // dealerInfo.value.dealer_logo_header = element.target.files[0];
      let file = element.target.files[0];
      if(file['size'] > 1048576) {
        window.toast.fire({
          icon: "error",
          title: 'Image size can not be greater than 1MB',
        });
        return false;
      }
      if(file['type'] != 'image/png') {
        window.toast.fire({
          icon: "error",
          title: 'Only png image allowed',
        });
        return false;
      }
      let reader = new FileReader();
      reader.onloadend = (file) => {
        if (file) {
          var image = new Image();

          //Set the Base64 string return from FileReader as source.
          image.src = file.target.result;

          //Validate the File Height and Width.
          image.onload = function () {
            if(this.width != 912 && this.width != 682) {
              window.toast.fire({
                icon: "error",
                title: 'Image should have 912x682 dimension',
              });
              return false;
            }
          };
          craigslistOverlayImage.value = file.target.result;
          let craigslist_overlay = reader.result;
          $(".loaders").show();
          axios
              .post(
                  axios.defaults.baseUrl + "updateCraigslistOverlay",
                  {
                    craigslist_overlay,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("auth_token"),
                    },
                  }
              )
              .then((response) => {
                $(".loaders").hide();
                if (!response.data.success) {
                  if (response.data.message.craigslist_overlay) {
                    window.toast.fire({
                      icon: "error",
                      title: response.data.message.craigslist_overlay[0],
                    });
                  }
                } else {
                  window.toast.fire({
                    icon: "success",
                    title: response.data.message,
                  });
                }
              });
        }
      };
      reader.readAsDataURL(file);
    }
    return { craigslistOverlayImage, err, triggerFileSelect, onFileChangeCraigslistOverlay }
  }
};
</script>
