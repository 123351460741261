<template>
  <Dashboard>
    <section>
      <!-- inventory-header -->
      <div class="inventory-header lead-header">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="leads-btn">
              <div class="btns-group">
                <a class="cus-btn" href="javascript:;" @click="resetFilters"
                  >Reset</a
                >
              </div>
              <div class="search-box">
                <input
                  type="text"
                  class="reportrange form-control daterangebackground"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-6">
            <div class="vehicle-area">
              <div class="search-box">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Leads"
                  v-model="keyword"
                  @keyup="getLeads"
                />
                <img
                  class="img-fluid search-icon"
                  src="@/assets/img/search.svg"
                  alt=""
                />
              </div>
              <div class="export">
                <a class="export-btn" @click="exportLeads" href="javascript:;"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/file-excel-solid.svg"
                    alt=""
                  />
                  Export</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- inventory-header -->

      <!-- leads-table -->
      <div class="table-area">
        <table class="display bottommargin5" id="leadsTable">
          <thead>
            <tr>
              <th style="width: 200px">Form Name</th>
              <th class="all">Date / Time</th>
              <th class="all" style="width: 80px">action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <!-- leads-table -->
      <!-- Webiste Leads details modal -->
      <div
        v-if="showPopup"
        class="modal fade"
        id="websiteLeadsModal"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Lead Details</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mytable" id="leadDetailsContainer">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Lead Date</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.createdTime }}
                      </td>
                    </tr>
                    <tr>
                      <td>Form Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.form_name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Campaign Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.campaign_name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ad Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.ad_name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Page Id</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.page_id }}
                      </td>
                    </tr>
                    <tr
                      v-for="(value, name, index) in LeadDetail.otherData"
                      :key="index"
                    >
                      <td>{{ name }}</td>
                      <td>
                        <span class="dot"></span>
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="submit" class="cancle-btn" @click="printLead">
                  Print
                </button>
                <button type="submit" data-dismiss="modal" class="send-btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Website Leads details modal -->
    </section>
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import axios from "axios";
import "daterangepicker";
import Dashboard from "./../../../layouts/Dashboard.vue";
import moment from "moment";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let keyword = ref("");
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    $(document).on("click", ".viewLeadDetails", function () {
      openLeadDetailsModal($(this).data("id"));
    });

    const printLead = () => {
      $("#printLead").remove();
      const modal = document.getElementById("leadDetailsContainer");
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("printLead");

      if (!section) {
        section = document.createElement("div");
        section.id = "printLead";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      // Small delay of 0.5s
      window.moveTo(0, 0);
      window.resizeTo(640, 480);
      setTimeout(() => {
        window.print();
      });
    };

    // Website Leads Details Modal Open
    const openLeadDetailsModal = (id) => {
      $(".loaders").show();
      axios
        .post(
          axios.defaults.baseUrl + "getTikTokLeadDetail",
          {
            id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          $(".loaders").hide();
          LeadDetail.value = response.data;
          showPopup.value = true;
          setTimeout(() => {
            $("#websiteLeadsModal").modal("show");
          });
        })
        .catch((error) => {
          error.value = "No Lead Data found";
        });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getLeads();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };
    let LeadDetail = ref(null);
    let showPopup = ref(false);

    const getLeads = async () => {
      if ($.fn.DataTable.isDataTable("#leadsTable")) {
        $("#leadsTable").DataTable().clear().destroy();
      }
      await $("#leadsTable")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          dom: "rtiplf",
          searching: false,
          bInfo: false,
          pageLength: 50,
          ajax: {
            url: axios.defaults.baseUrl + "getTikTokLeads",
            data: function (d) {
              d.search["value"] = keyword.value;
              d.fromDate = startDate.value;
              d.toDate = endDate.value;
            },
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "page_name" },
            { data: "created_date" },
            { data: "actions" },
          ],
          order: [],
          columnDefs: [{ targets: [2], orderable: false }],
        });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    const exportLeads = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "exportTikTokLeads",
          {
            responseType: "arraybuffer",
            fromDate: startDate.value,
            toDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status != "error") {
            window.showHideMainLoader(false);
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "leads.csv");
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
      window.showHideMainLoader(false);
    };

    onMounted(() => {
      dateRangePickerInit();
    });

    return {
      keyword,
      LeadDetail,
      getLeads,
      showPopup,
      resetFilters,
      printLead,
      exportLeads,
    };
  },
};
</script>