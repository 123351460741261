<template>
  <Dashboard>
    <!-- ManageDealers-Table -->
    <div class="table-area pricing-table positionedbutton">
      <!-- action-btn -->
      <div class="action-drop">
        <router-link class="action-btn" :to="{ name: 'AddDealer' }"
          >Add New Dealer</router-link
        >
      </div>

      <table id="dealers_data_table" class="display">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th>Database ID</th>
            <th>Name ID</th>
            <th style="width:150px">CRM Token</th>
            <th class="all" style="width: 50px">Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- add more ads -->
    <div
      class="modal select-modal fade"
      id="addMoreAdsModal"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Add More Ads</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Number of Ads</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="First Name"
                    v-model="addMoreAdsCount"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Back
                </button>
                <button
                  type="button"
                  @click="handleAddMoreAds"
                  class="send-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add more ads -->

    <!-- apiTokenModal -->
    <div
      class="modal select-modal fade"
      id="apiTokenModal"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">API Token</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <p id="APiTokenContainer" style="word-break:break-all;"></p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- apiTokenModal -->

    <!-- add card -->
    <div
      class="modal select-modal fade"
      id="addCardModal"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel1">Add Card</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-12 text-center">
                  <div id="card-container"></div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Back
                </button>
                <button
                  type="button"
                  id="submit_square_card"
                  class="send-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add card -->

    <!-- ManageDealers-Table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import routes from "./../../routes";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let dealers = ref([]);
    let addMoreAdsCount = ref(0);
    let addMoreAdsId = ref(0);
    let addCardId = ref(0);


    async function squareInit() {
      const payments = window.Square.payments(
        process.env.VUE_APP_SQUARE_APPLICATION_ID,
        process.env.VUE_APP_SQUARE_LOCATION
      );
      const card = await payments.card();
      await card.attach("#card-container");
      async function eventHandler(event) {
        event.preventDefault();
        window.showHideMainLoader(true);
        try {
          const result = await card.tokenize();
          if (result.status === "OK") {
            handleAdCard(result.token);
          }
        } catch (e) {
          window.showHideMainLoader(false);
          window.toast.fire({
            icon: "error",
            title: e,
          });
        }
      }
      const cardButton = document.getElementById("submit_square_card");
      cardButton.addEventListener("click", eventHandler);
    }

    $(document).on("click", ".viewToken", function () {
      let token = $(this).data("token");
      $("#APiTokenContainer").html(token);
      $("#apiTokenModal").modal("show");
    });

    $(document).on("click", ".manageTrackingNumber", function () {
      let id = $(this).data("id");
      let type = $(this).data("type");
      routes.push({ name: "TrackingNumber", params: { type, id } });
    });


    $(document).on("click", ".editDealer", function () {
      let id = $(this).data("id");
      routes.push({ name: "EditDealer", params: { id } });
    });

    $(document).on("click", ".addMoreAds", function () {
      addMoreAdsId.value = $(this).data("id");
      addMoreAdsCount.value = 0;
      $("#addMoreAdsModal").modal("show");
    });

    $(document).on("click", ".addCard", function () {
      addCardId.value = $(this).data("id");
      $("#card-container").html('');
      squareInit();
      $("#addCardModal").modal("show");
    });

    $(document).on("click", ".manageExports", function () {
      let id = $(this).data("id");
      routes.push({ name: "InventoryExportSettings", params: { id } });
    });

    $(document).on("click", ".activate_deactivate_automated_ads", function () {
      let id = $(this).data("id");
      let status = $(this).data("status");
      if (status == 0) {
        status = 1;
      } else {
        status = 0;
      }
      activateDeactivateAutomatedAds(id, status);
    });

    const handleAdCard = async (nonce) => {
      window.showHideMainLoader(true);
      let id = addCardId.value;
      await axios
        .post(
          axios.defaults.baseUrl + "addDealerCard",
          {
            id,
            nonce,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          closePopup();
          window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again...",
            });
          }
        });
    };

    const activateDeactivateAutomatedAds = async (id, status) => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "activateDeactivateAutomatedAds",
          {
            id,
            status,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
          getDealers();
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again...",
            });
          }
        });
    };

    $(document).on(
      "click",
      ".activate_deactivate_facebook_catalog",
      function () {
        let id = $(this).data("id");
        let status = $(this).data("status");
        if (status == 1) {
          status = 2;
        } else {
          status = 1;
        }
        activateDeactivateFacebookCatalog(id, status);
      }
    );

    const activateDeactivateFacebookCatalog = async (id, status) => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "activateDeactivateFacebookCatalog",
          {
            id,
            status,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
          getDealers();
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again...",
            });
          }
        });
    };

    const getDealers = async () => {
      if ($.fn.DataTable.isDataTable("#dealers_data_table")) {
        $("#dealers_data_table").DataTable().clear().destroy();
      }
      await $("#dealers_data_table")
        .addClass("nowrap positionedfilters")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: axios.defaults.baseUrl + "getDealers",
            async: true,
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "name" },
            { data: "id" },
            { data: "dealer_id" },
            { data: "crm_api_token" },
            { data: "actions" },
          ],
          order: [],
          columnDefs: [{ targets: [3], orderable: false }],
        });
    };

    const handleAddMoreAds = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "addMoreAds",
          {
            id: addMoreAdsId.value,
            ads: addMoreAdsCount.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          if (response.data.success) {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            closePopup();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again...",
            });
          }
        });
    };

    const closePopup = () => {
      addMoreAdsCount.value = 0;
      addMoreAdsId.value = 0;
      $("#addMoreAdsModal").modal("hide");
      $("#addCardModal").modal("hide");
      $("#apiTokenModal").modal("hide");
    };
    onMounted(() => {
      getDealers();
    });
    return {
      dealers,
      getDealers,
      closePopup,
      addMoreAdsCount,
      handleAddMoreAds,
    };
  },
};
</script>
<style>
.dataTables_wrapper {
  min-height: 100vh !important;
}
.pricing-table .dataTables_wrapper .dataTables_filter {
  margin-right: 145px;
}
</style>