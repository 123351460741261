<template>
  <Dashboard>

    <InsightCharts />

    <!-- Analytics-table -->
    <div class="table-area">
      <table class="display" id="facebookTable">
        <thead>
        <tr>
          <th class="all">Name</th>
          <th style="width: 100px;">Status</th>
          <th class="all" style="width:100px">action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="Adset in allAdsets" :key="Adset">
          <td>{{ Adset.name }}</td>
          <td>{{ Adset.status }}</td>
          <td><router-link class="cus-btn" :to="{ name: 'FacebookAds', params: {id: Adset.id}}">Ads</router-link></td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { useRoute } from "vue-router";
import {onMounted, ref} from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
import InsightCharts from "./../../components/Facebook/InsightCharts.vue";
import axios from "axios";
export default {
  components: {
    Dashboard,
    InsightCharts
  },
  setup() {
    const route = useRoute();
    let allAdsets = ref([]);
    const getFacebookAdsets = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getFacebookAdsets/"+route.params.id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if(response.status) {
              if ($.fn.DataTable.isDataTable("#facebookTable")) {
                $("#facebookTable").DataTable().clear().destroy();
              }
              allAdsets.value = response.data.data.adsets;
              setTimeout(() => {
                datatableInit();
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const datatableInit = async () => {
      $("#facebookTable").addClass("nowrap").dataTable({
        responsive: true,
        order: []
      });
    }

    onMounted( () => {
      datatableInit();
      getFacebookAdsets();
    })

    return { allAdsets }
  }
};
</script>