import { ref } from 'vue';
import axios from 'axios';
const getUserPermissions = () => {
    const user_permissions = ref([]);
    const error = ref('')
    try {
        const laodUserPermissions = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getUserPermissions",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    user_permissions.value = response.data;
                })
                .catch((error) => {
                    error.value = 'No User Type Found';
                });
        }
        return { user_permissions, error, laodUserPermissions };
    } catch (error) {
        error.value = 'No User Type Found';
    }
}
export default getUserPermissions;