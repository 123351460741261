<template>
  <Dashboard>
    <!-- Analytics-table -->
    <div class="table-area hide-select-search">
      <h4 v-if="campaignData.name">
        {{campaignData.name}}
        <label class="switch" v-if="campaignData.name != ''">
          <input type="checkbox" :checked="campaignData.status == 'ENABLED'" @change="campaignStatusUpdate()" />
          <span class="slider round"></span>
        </label>
      </h4>
      <table class="display" id="googleadstable">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th>Status</th>
            <th class="all" style="width: 70px">action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="Adgroup in allAdgroups" :key="Adgroup">
            <td>{{ Adgroup.name }}</td>
            <td>{{ Adgroup.status }}</td>
            <td>
              <router-link
                class="cus-btn"
                :to="{ name: 'GoogleAutomatedAds', params: { id: Adgroup.id } }"
                >Ads</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
// import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import "daterangepicker";
import Dashboard from "./../../../../layouts/Dashboard.vue";
import getDealerOtherInfo from "../../../../composeables/getDealerOtherInfo";
import axios from "axios";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let campaignData = ref({});
    let allAdgroups = ref([]);

    // Get Dealer Other Info:
    const { dealer, dealerInfo, dealerInfoError, loadDealerInfo } =
      getDealerOtherInfo();
    loadDealerInfo().then(() => {
      if (
        dealerInfo.value.google_ads_account_id != "" &&
        dealerInfo.value.google_ads_account_id != "undefined" &&
        dealerInfo.value.automatic_google_ads_campaign_id != "" &&
        dealerInfo.value.automatic_google_ads_campaign_id != "undefined"
      ) {
        getGoogleCampaigns();
        getGoogleAdgroups();
      }
    });
    const getGoogleAdgroups = async () => {
      await axios
        .get(
          axios.defaults.baseUrl +
            "getGoogleAdgroups/" +
            dealerInfo.value.google_ads_account_id +
            "/" +
            dealerInfo.value.automatic_google_ads_campaign_id +
            "/automatedAds/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.status) {
            if ($.fn.DataTable.isDataTable("#googleadstable")) {
              $("#googleadstable").DataTable().clear().destroy();
            }

            allAdgroups.value = response.data.data;

            setTimeout(() => {
              datatableInit();
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const campaignStatusUpdate = async () => {
      window.showHideMainLoader(true);
      let url = axios.defaults.baseUrl + "updateGoogleAdCampaignStatus/"+
            dealerInfo.value.google_ads_account_id +
            "/" +
            dealerInfo.value.automatic_google_ads_campaign_id +
            "/ENABLED";
      if(campaignData.value.status == 'ENABLED') {
        url = axios.defaults.baseUrl + "updateGoogleAdCampaignStatus/"+
            dealerInfo.value.google_ads_account_id +
            "/" +
            dealerInfo.value.automatic_google_ads_campaign_id +
            "/PAUSED";
        campaignData.value.status == 'PAUSED';
      }else {
        campaignData.value.status == 'ENABLED';
      }

     

      window.showHideMainLoader(true);
      await axios
        .post(url, {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          window.toast.fire({
            icon: response.data.status,
            title: response.data.data,
          });
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    }

    const getGoogleCampaigns = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getGoogleCampaigns/"+dealerInfo.value.google_ads_account_id+"/automatedAds/null/null/"+dealerInfo.value.automatic_google_ads_campaign_id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            campaignData.value = response.data.data.singleCampaignData;
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const datatableInit = async () => {
      $("#googleadstable").addClass("nowrap").dataTable({
        responsive: true,
        order: [],
      });
    };

    onMounted(() => {
      datatableInit();
    });

    return {
      dealer,
      dealerInfo,
      dealerInfoError,
      allAdgroups,
      campaignData,
      campaignStatusUpdate
    };
  },
};
</script>