<template>
  <Dashboard>
    <!-- dealership-form -->
    <div class="dealership-form">
      <form class="myform">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>First Name</label>
            <input v-model="user.first_name" type="text" class="form-control" />
          </div>
          <div class="form-group col-md-6">
            <label>Last Name</label>
            <input v-model="user.last_name" type="text" class="form-control" />
          </div>
          <div class="form-group col-md-6">
            <label>Password</label>
            <input
                v-model="user.password"
                type="password"
                class="form-control"
            />
            <small>Leave empty to keep current password</small>
          </div>

          <div class="form-group col-md-6">
            <label>Confirm Password</label>
            <input
                v-model="user.confirm_password"
                type="password"
                class="form-control"
            />
          </div>
          <div class="col-md-12">
            <div class="cus-btn text-right">
              <router-link
                  to="/dashboard"
                  class="cancle-btn text-decoration-none"
              >Back</router-link
              >
              <button
                  type="button"
                  @click="handleUpdateUserRequest"
                  class="send-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="dealership-form mt-3" v-if="user_type == 'dealer'">
      <form class="myform" @submit.prevent="handleUpdateDealerRequest">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>CRM Email</label>
            <input
                v-model="dealer.leads_email"
                type="email"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Secondary CRM Email</label>
            <input
                v-model="dealer.leads_email_secondary"
                type="email"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Backup Email</label>
            <input
                v-model="dealer.backup_email"
                type="email"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Backup Email 2</label>
            <input
                v-model="dealer.backup_email2"
                type="email"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Support Email</label>
            <input
                v-model="dealer.support_email"
                type="email"
                class="form-control"
            />
          </div>
          <div class="col-md-12">
            <div class="cus-btn text-right">
              <router-link
                  to="/dashboard"
                  class="cancle-btn text-decoration-none"
              >Back</router-link
              >
              <button type="submit" class="send-btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- dealership-form -->
  </Dashboard>
</template>
<script>
import Dashboard from "./../../layouts/Dashboard.vue";
import getUser from "./../../composeables/getUser";
import getDealer from "./../../composeables/getDealer";
import axios from "axios";
export default {
  components: {
    Dashboard,
  },
  setup() {
    // Get User Data
    const user_type = window.userRoleType;
    let { user, error, loadUser } = getUser(localStorage.getItem("user_id"));
    loadUser();
    // Update User Data
    const handleUpdateUserRequest = async () => {
      window.showHideMainLoader(true);
      await axios
          .patch(
              axios.defaults.baseUrl + "user/"+localStorage.getItem("user_id"),
              {
                dealer_id: localStorage.getItem("dealer_id"),
                first_name: user.value.first_name,
                last_name: user.value.last_name,
                email: user.value.email,
                user_type: user.value.user_type,
                password: user.value.password,
                confirm_password: user.value.confirm_password,
                permissions: user.value.userPermission,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            if (!response.data.success) {
              if (response.data.message.password) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.password[0],
                });
              }
              if (response.data.message.confirm_password) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.confirm_password[0],
                });
              }
            } else {
              localStorage.setItem("first_name", user.value.first_name);
              localStorage.setItem("last_name", user.value.last_name);
              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });
            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again",
              });
            }
          });
    };
    
      
    
      // Get Dealer Data
      let { dealer, err, loadDealer } = getDealer(
          localStorage.getItem("dealer_id")
      );
      console.log(dealer+''+err);
      if(user_type == 'dealer') {
        loadDealer();
      }
    // Update Dealer Data
    const handleUpdateDealerRequest = async () => {
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "updateDealerEmails",
              {
                id: localStorage.getItem("dealer_id"),
                dealer: dealer.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            window.toast.fire({
              icon: "success",
              title: "Email settings updated successfully",
            });
            dealer.value = response.data.dealer;
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again",
              });
            }
          });
    };
    return { user, error, handleUpdateUserRequest, dealer, err, handleUpdateDealerRequest, user_type };
  },
};
</script>