<template>
  <Dashboard>
    <!-- add user modal -->
    <div
      class="modal fade"
      id="exampleModal12"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Add Support Ticket
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputEmail4">Subject</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Keller Used Cars"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="inputEmail4">Details</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" data-dismiss="modal" class="cancle-btn">
                Cancel
              </button>
              <button type="submit" class="send-btn">
                Submit Support Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add user modal -->

    <!-- Analytics-table -->
    <div class="table-area support-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#exampleModal12"
        >
          Add Ticket</a
        >
      </div>

      <table class="display" id="supportTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Ticket Subject</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
          <tr>
            <td><a href="#">1003</a></td>
            <td>13 Sep, 2019 10:51 AM</td>
            <td><a href="#">website changes</a></td>
            <td>Awaiting Answer</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {},
  mounted() {
    $("#supportTable").dataTable();
  },
};
</script>