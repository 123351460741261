<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Search Engines</h4>
            <apexchart
              id="chart5"
              width="100%"
              height="300"
              type="donut"
              :options="donutChartOptions"
              :series="donutSeries"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="searchEngineSessionsTable">
              <thead>
                <tr>
                  <th>Search Engine</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th>New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in searchEngine" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- google-graph -->
    <!-- channel-table -->
    <div class="channel-tabel">
      <div class="row">
        <div class="col-lg-12">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <h4>Top 50 Keywords</h4>
            <table class="display" id="topFiftyKeywordsSessionsTable">
              <thead>
                <tr>
                  <th>Keyword</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th>New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in topFiftyKeywords" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- channel-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    let donutSeries = ref([]);
    let donutChartOptions = ref({});

    let searchEngineSessions = ref([]);
    let searchEngineSessionsError = ref("");
    let searchEngine = ref([]);
    let searchEngineError = ref("");
    let topFiftyKeywords = ref([]);
    let topFiftyKeywordsError = ref("");

    const getSearchEngineSessions = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getSearchEngineSessions",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          searchEngineSessions.value = response.data;

          donutSeries.value = searchEngineSessions.value.sessions;
          donutChartOptions.value = {
            chart: {
              type: "donut",
            },
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            labels: searchEngineSessions.value.labels,
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((searchEngineSessionsError) => {
          searchEngineSessionsError.value =
            "No Sessions for Google Analytics found";
        });
    };

    const getSearchEngine = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getSearchEngine",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          searchEngine.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#searchEngineSessionsTable")) {
            $("#searchEngineSessionsTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#searchEngineSessionsTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((searchEngineError) => {
          searchEngineError.value = "No Sessions for Google Analytics found";
        });
    };

    const getTopFiftyKeywords = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getTopFiftyKeywords",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          topFiftyKeywords.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#topFiftyKeywordsSessionsTable")) {
            $("#topFiftyKeywordsSessionsTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#topFiftyKeywordsSessionsTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((topFiftyKeywordsError) => {
          topFiftyKeywordsError.value =
            "No Sessions for Google Analytics found";
        });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getSearchEngineSessions();
      getSearchEngine();
      getTopFiftyKeywords();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      $("#searchEngineSessionsTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      $("#topFiftyKeywordsSessionsTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      dateRangePickerInit();
    });

    return {
      donutSeries,
      donutChartOptions,
      searchEngineSessions,
      searchEngine,
      topFiftyKeywords,
      searchEngineSessionsError,
      searchEngineError,
      topFiftyKeywordsError,
      resetFilters,
    };
  },
};
</script>
<style>
#searchEngineSessionsTable_wrapper {
  min-height: auto !important;
}
</style>