<template>
  <Dashboard>
    <!-- Analytics-table -->
    <div class="table-area feedback-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#specialsModal"
          >Add New</a
        >
      </div>

      <table class="display" id="specials">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th class="all">Url</th>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
            <th>Trim</th>
            <th style="width: 50px">Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Analytics-table -->

    <!-- ads-Modal -->
    <div
      class="modal select-modal fade"
      id="specialsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="exampleModalLabel">
              Update Specials
            </h5>
            <h5 v-else class="modal-title" id="exampleModalLabel">
              Add new Specials
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Name</label>
                  <input class="form-control" v-model="specials.name" />
                </div>
                <div class="form-group col-md-6">
                  <label for="">Url</label>
                  <input class="form-control" v-model="specials.url" />
                </div>
                <div class="form-group col-md-6">
                  <label for="">Year</label>
                  <select
                    class="form-control tags1 select2"
                    v-model="specials.year"
                    id="selectMake"
                  >
                    <option value="">Select year</option>
                    <option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="">Make</label>
                  <select
                    class="form-control tags1 select2"
                    v-model="specials.make"
                    id="selectMake"
                  >
                    <option value="">Select make</option>
                    <option v-for="make in makes" :key="make" :value="make">
                      {{ make }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="">Model</label>
                  <select
                    class="form-control tags1 select2"
                    v-model="specials.model"
                    id="selectMake"
                  >
                    <option selected value="">Select model</option>
                    <option v-for="model in models" :key="model" :value="model">
                      {{ model }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="">Trim</label>
                  <select
                    class="form-control tags1 select2"
                    v-model="specials.trim"
                    id="selectMake"
                  >
                    <option selected value="">Select trim</option>
                    <option v-for="trim in trims" :key="trim" :value="trim">
                      {{ trim }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" @click="closeModal" class="cancle-btn">
                Back
              </button>
              <button
                v-if="editMode"
                type="submit"
                class="send-btn"
                @click="updateSpecialsGroup"
              >
                Submit
              </button>
              <button
                v-else
                type="submit"
                class="send-btn"
                @click="addSpecialsGroup"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ads-Modal -->
    <!-- delete Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this Record?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button
                type="button"
                @click="deleteSpecialsGroup"
                class="send-btn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import routes from "./../../routes";
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let specials = ref({
      name: "",
      url: "",
      year: "",
      make: "",
      model: "",
      trim: "",
    });
    let editMode = ref(false);

    let years = ref([]);
    let makes = ref([]);
    let models = ref([]);
    let trims = ref([]);
    let selectedMake = ref("");
    let selectedModel = ref("");

    const closeModal = () => {
      specials.value = {
        name: "",
        url: "",
        year: "",
        make: "",
        model: "",
        trim: "",
      };
      editMode.value = false;
      $("#deleteModal").modal("hide");
      $("#specialsModal").modal("hide");
    };

    const getmakeModels = async () => {
      window.showHideMainLoader(true);
      await axios
        .get(axios.defaults.baseUrl + "getMakeModels", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            years.value = response.data.data.years;
            makes.value = response.data.data.makes;
            models.value = response.data.data.models;
            trims.value = response.data.data.trims;
            setTimeout(() => {
              $(".select2").select2();
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const getSpecials = async () => {
      window.showHideMainLoader(true);
      if ($.fn.DataTable.isDataTable("#specials")) {
        $("#specials").DataTable().clear().destroy();
      }
      await $("#specials")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: axios.defaults.baseUrl + "getSpecials",
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "name" },
            { data: "url" },
            { data: "year" },
            { data: "make" },
            { data: "model" },
            { data: "trim" },
            { data: "action_links" },
          ],
          order: [],
          columnDefs: [{ targets: [6], orderable: false }],
        });
      window.showHideMainLoader(false);
    };

    // Add SpecialsGroup
    const addSpecialsGroup = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "addSpecialsGroup",
          {
            specials: specials.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getSpecials();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    // Open Edit Modal
    $(document).on("click", ".editSpecial", function () {
      editMode.value = true;
      axios
        .get(axios.defaults.baseUrl + "getSpecialsData/" + $(this).data("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          specials.value = response.data;
          $("#specialsModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    });

    // Update SpecialsGroup
    const updateSpecialsGroup = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "updateSpecialsGroup/" + specials.value.id,
          {
            specials: specials.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getSpecials();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    // Open Delete Modal
    $(document).on("click", ".deleteGroup", function () {
      specials.value.id = $(this).data("id");
      $("#deleteModal").modal("show");
    });

    const deleteSpecialsGroup = async () => {
      axios
        .delete(
          axios.defaults.baseUrl + "deleteSpecialsGroup/" + specials.value.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getSpecials();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    }

    // Manage Images
    $(document).on("click", ".manageImages", function () {
      routes.push({ name: "SpecialsImages", params: { id: $(this).data("id") } });
    });

    onMounted(() => {
      getSpecials();
      getmakeModels();
      $(".select2").select2();
    });

    return {
      years,
      makes,
      models,
      trims,
      selectedMake,
      selectedModel,
      closeModal,
      addSpecialsGroup,
      updateSpecialsGroup,
      deleteSpecialsGroup,
      specials,
      editMode,
    };
  },
};
</script>
<style>
.feedback-table .dataTables_wrapper .dataTables_filter {
  margin-right: 100px;
}
</style>