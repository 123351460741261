<template>
  <Dashboard>
    <!-- ManageUsers-Table -->
    <div class="table-area pricing-table positionedbutton">
      <!-- action-btn -->
      <div class="action-drop">
        <a class="action-btn" href="javascript:;" @click="openModal">Add New User</a>
      </div>

      <table id="users_data_table" class="display">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th>Email</th>
            <th>Type</th>
            <th class="all" style='width:50px'>Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- ManageUsers-Table -->
    <BackendUserModel :getUsersRequest="getUsers" />
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard.vue";
import BackendUserModel from "./../../components/ManageUsers/AddEditBackendUserModel.vue";
export default {
  components: {
    Dashboard,
    BackendUserModel,
  },
  setup() {
    let users = ref([]);
    const openModal = () => {
      $(".user-permissions").select2({
              closeOnSelect : false,
              placeholder : "Placeholder",
              allowHtml: true,
              allowClear: true,
              tags: true
              });
      $("#addNewUserModal").modal("show");
    };

    const getUsers = async () => {
      if ($.fn.DataTable.isDataTable("#users_data_table")) {
        $("#users_data_table").DataTable().clear().destroy();
      }
      await $("#users_data_table")
          .addClass("nowrap positionedfilters")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getUsers",
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "name" },
              { data: "email" },
              { data: "usertype" },
              { data: "actions" },
            ],
            order: [],
            columnDefs: [
              {targets: [3], orderable: false }
            ]
          });
    }
    onMounted(() => {
      getUsers();
    })
    return { users, openModal, getUsers };
  },
};
</script>