<template>
  <Dashboard>
    <!-- Craigslist Phrases -->
    <div class="craigslist-area">
      <form class="myform" @submit.prevent="updateFacebookSettings">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-group col-md-12">
                <label>Facebook Ad Account ID</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="XXXXXXXXXXXXX"
                  v-model="dealerInfo.facebook_ad_account_id"
                />
              </div>

              <div class="form-group col-md-12">
                <label>Messenger Leads Permissions</label>
                <select class="form-control tags2" v-model="dealerInfo.messenger_leads_permissions">
                  <option value="1" selected="">Send to CRM</option>
                  <option value="0">Do not send to CRM</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <div class="setting-text">
                  <h4>Facebook Settings</h4>
                  <div class="fb-btn">
                    <a class="facebook" href="javascript:;" @click="authenticateFacebook"
                      ><i class="fa fa-facebook" aria-hidden="true"></i> Sign In
                      with Facebook</a
                    >
                  </div>
                  <div v-if="dealer.facebook_page_id != ''"><a target="_blank" :href="'https://www.facebook.com/' + dealer.facebook_page_id">{{ dealer.facebook_page_name }}</a></div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="alert-text">
                  <h5>
                    <img
                      class="img-fluid"
                      src="@/assets/img/warning.svg"
                      alt=""
                    />
                    Attention!
                  </h5>
                  <p>
                    By Signing in with Facebook you will allow access to
                    autolink to manage your pages and post your vehicles on your
                    page on your behalf.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-12">
            <div class="cus-btn text-right">
              <router-link :to="{ name: 'Dashboard' }" class="cancle-btn">
                Back</router-link
              >
              <button type="submit" class="send-btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Craigslist Phrases -->
  </Dashboard>
  <!-- Select FB Page -->
  <div
      class="modal select-modal fade"
      id="selectfbpage"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Select Facebook Page
          </h5>
          <button
              type="button"
              class="close"
              @click="closePopup"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form class="myform">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Facebook Page</label>
                <select
                    id="selectedFbPage"
                    class="form-control select2"
                    v-model="selectedFbPage"
                >
                  <option selected="selected" value="">Please select a page</option>
                  <option
                      v-for="page in pages"
                      :key="page"
                      :value="page"
                  >
                    {{ page.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closePopup" class="cancle-btn">
                Back
              </button>
              <button
                  type="button"
                  @click="handlePageSelectionRequest"
                  class="send-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Select FB Page -->
</template>
<script>
import Dashboard from "./../../layouts/Dashboard.vue";
import getDealerOtherInfo from "./../../composeables/getDealerOtherInfo";
import axios from "axios";
import {useRoute} from "vue-router";
import $ from "jquery";
import {onMounted, ref} from "vue";
import routes from "../../routes";
export default {
  components: {
    Dashboard,
  },
  setup() {
    const route = useRoute();
    let selectedFbPage = ref('');
    let pages = ref([]);
    const closePopup = () => {
      $("#selectfbpage").modal("hide");
      routes.push({ name: "FacebookSettings" });
    };
    // Get Dealer Information:
    const { dealerInfo, dealer, dealerInfoError, loadDealerInfo } =
        getDealerOtherInfo();
    loadDealerInfo();
    // Update Dealer Information
    const updateFacebookSettings = async () => {
      await axios
          .post(
              axios.defaults.baseUrl + "updateFacebookSettings",
              {
                dealer_other_info: dealerInfo.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            dealerInfo.value = response.data.dealer_other_info;
            window.toast.fire({
              icon: "success",
              title: "Facebook settings updated successfully",
            });
          })
          .catch((error) => {
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again...",
              });
            }
          });
    }

    const authenticateFacebook = async () => {
      let facebookUrl = null;
        axios
          .post(
              axios.defaults.baseUrl + "facebookAuthentication",
              {
                google_auth: "",
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            facebookUrl = response.data.data;
            window.location.href = facebookUrl;
          });
    }

    // Save Selected Location Request
    const handlePageSelectionRequest = async () => {
      if(selectedFbPage.value.facebook_page_id != '') {
        await axios
            .post(
                axios.defaults.baseUrl + "faebookSaveSelectedPage",
                {
                  dealer_id: localStorage.getItem("dealer_id"),
                  facebook_page_id: selectedFbPage.value.id,
                  facebook_page_name: selectedFbPage.value.name,
                  facebook_page_access_token: selectedFbPage.value.access_token
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                  },
                }
            )
            .then((response) => {
              if(response.data.status) {
                window.toast.fire({
                  icon: "success",
                  title: response.data.data,
                });
              }else {
                window.toast.fire({
                  icon: "error",
                  title: response.data.data,
                });
              }
              closePopup();
            })
            .catch((error) => {
              console.log(error);
            });
      }else {
        window.toast.fire({
          icon: "error",
          title: 'No Page Selected',
        });
      }
    }

    onMounted( () => {
      if(route.query.error) {
        window.toast.fire({
          icon: "error",
          title: route.query.error,
        });
      }else if (route.query.code) {
        let access_token = route.query.code;
        axios
            .post(
                axios.defaults.baseUrl + "facebookRefreshTokenSave",
                {
                  access_token: access_token,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                  },
                }
            )
            .then((response) => {
              if(!response.data.success) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.data,
                });
              }else {
                pages.value = response.data.data;
                setTimeout(() => {
                  $("#selectedFbPage").select2();
                });
                $("#selectfbpage").modal({backdrop: 'static', keyboard: false});
              }
            });
      }
    })

    return { dealerInfo, dealer, dealerInfoError, loadDealerInfo, updateFacebookSettings, authenticateFacebook, pages, selectedFbPage, closePopup, handlePageSelectionRequest };
  }
};
</script>