<template>
  <Dashboard>
    <section>
      <!-- inventory-header -->
      <div class="inventory-header lead-header">
        <div class="row">
          <div class="col-md-12 col-lg-6">&nbsp;</div>

          <div class="col-md-12 col-lg-6">
            <div class="vehicle-area">
              <div class="search-box">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Leads"
                  v-model="keyword"
                  @keyup="getLeads"
                />
                <img
                  class="img-fluid search-icon"
                  src="@/assets/img/search.svg"
                  alt=""
                />
              </div>
              <div class="export">
                <a class="export-btn" @click="exportLeads" href="javascript:;"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/file-excel-solid.svg"
                    alt=""
                  />
                  Export</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- inventory-header -->

      <!-- tabs-area -->
      <div class="leads-tabs">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <router-link
              :to="{ name: 'CraigslistPhoneLeads' }"
              class="nav-link"
            >
              Phone Leads
            </router-link>
            <router-link :to="{ name: 'CraigslistTextLeads' }" class="nav-link">
              Text Leads
            </router-link>
            <router-link
              :to="{ name: 'CraigslistClickLeads' }"
              class="nav-link"
            >
              Website Clicks
            </router-link>
            <router-link
              :to="{ name: 'CraigslistOldClickLeads' }"
              class="nav-link active"
            >
              Old Website Clicks
            </router-link>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" role="tabpanel">
            <!-- leads-table -->
            <div class="table-area">
              <table class="display bottommargin5" id="leadsTable">
                <thead>
                  <tr>
                    <th>Url</th>
                    <th class="all">Vehicle</th>
                    <th class="all" style="width: 100px">Clicks</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <!-- leads-table -->
          </div>
        </div>
      </div>
      <!-- tabs-area -->
    </section>
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import axios from "axios";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let keyword = ref("");

    const getLeads = async () => {
      if ($.fn.DataTable.isDataTable("#leadsTable")) {
        $("#leadsTable").DataTable().clear().destroy();
      }
      await $("#leadsTable")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          dom: "rtiplf",
          searching: false,
          bInfo: false,
          pageLength: 50,
          ajax: {
            url: axios.defaults.baseUrl + "getCraiglistOldClicksLeads",
            data: function (d) {
              d.search["value"] = keyword.value;
            },
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "vdp" },
            { data: "vehicle" },
            { data: "vdp_clicks" },
          ],
          order: [],
        });
    };

    const exportLeads = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "exportCraiglistOldClicksLeads",
          {
            responseType: "arraybuffer"
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status != "error") {
            window.showHideMainLoader(false);
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "leads.csv");
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
      window.showHideMainLoader(false);
    };
    onMounted(() => {
      getLeads();
    });

    return {
      keyword,
      getLeads,
      exportLeads,
    };
  },
};
</script>