<template>
  <!-- count-cars -->
  <div class="count-cars" v-if="DashboardCheck">
    <div class="form-row">
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link
            class="car-counter"
            :to="{ name: routeName, params: { search: '30-days-old-inventory' } }"
        >
          <div class="car-number">
            <h2>{{ ThirtyDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>0-30 day old cars</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link
            class="car-counter"
            :to="{ name: routeName, params: { search: '30-to-60-days-old-inventory' } }"
        >
          <div class="car-number">
            <h2>{{ SixtyDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>30-60 day old cars</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link
            class="car-counter"
            :to="{ name: routeName, params: { search: '60-to-90-days-old-inventory' } }"
        >
          <div class="car-number">
            <h2>{{ NinetyDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>60-90 day old cars</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link
            class="car-counter"
            :to="{ name: routeName, params: { search: '90-plus-days-old-inventory' } }"
        >
          <div class="car-number">
            <h2>{{ NinetyPlusDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>90 + day old cars</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link
            class="car-counter"
            :to="{ name: routeName, params: { search: 'inventory-with-no-image' } }"
        >
          <div class="car-number">
            <h2>{{ CarsWithNoImageCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>Cars with no image</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link
            class="car-counter"
            :to="{ name: routeName, params: { search: 'inventory-with-no-price' } }"
        >
          <div class="car-number">
            <h2>{{ CarsWithNoPriceCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>Cars with no price</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link class="car-counter" :to="{ name: 'UsedInventory' }">
          <div class="car-number">
            <h2>{{ UsedCars }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>Used Cars</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link class="car-counter" :to="{ name: 'NewInventory' }">
          <div class="car-number">
            <h2>{{ NewCars }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>New Cars</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <div class="count-cars" v-else>
    <div class="form-row">
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <a
            class="car-counter"
            :href="thirtydays.href"
        >
          <div class="car-number">
            <h2>{{ ThirtyDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>0-30 day old cars</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <a
            class="car-counter"
            :href="sixtydays.href"
        >
          <div class="car-number">
            <h2>{{ SixtyDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>30-60 day old cars</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <a
            class="car-counter"
            :href="ninetydays.href"
        >
          <div class="car-number">
            <h2>{{ NinetyDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>60-90 day old cars</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <a
            class="car-counter"
            :href="ninetyplus.href"
        >
          <div class="car-number">
            <h2>{{ NinetyPlusDaysCarsCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>90 + day old cars</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <a
            class="car-counter"
            :href="noimage.href"
        >
          <div class="car-number">
            <h2>{{ CarsWithNoImageCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>Cars with no image</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <a
            class="car-counter"
            :href="noprice.href"
        >
          <div class="car-number">
            <h2>{{ CarsWithNoPriceCount }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>Cars with no price</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link class="car-counter" :to="{ name: 'UsedInventory' }">
          <div class="car-number">
            <h2>{{ UsedCars }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>Used Cars</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <router-link class="car-counter" :to="{ name: 'NewInventory' }">
          <div class="car-number">
            <h2>{{ NewCars }}</h2>
            <p>cars</p>
          </div>
          <div class="cars-text">
            <p>New Cars</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <!-- count-cars -->
</template>
<script>
import {ref} from "vue";
import routes from "./../../routes";
import { useRoute } from "vue-router";
import getVehicleCount from "./../../composeables/getVehicleCount";
export default {
  setup() {
    const route = useRoute();
    let type = null;
    let status = 'active';
    let routeName = ref('UsedInventory');
    let DashboardCheck = ref(false);

    let thirtydays = '';
    let sixtydays = '';
    let ninetydays = '';
    let ninetyplus = '';
    let noimage = '';
    let noprice = '';

    switch(route.name) {
      case 'UsedInventory':
        type = 'Used';
        break;
      case 'NewInventory':
        routeName.value = 'NewInventory';
        type = 'New';
        break;
      case 'RemovedInventory':
        type = null;
        status = 'deleted';
        break;
      default:
        DashboardCheck.value = true;
        type = null;
    }

    if(route.name != 'Dashboard') {
      thirtydays = routes.resolve({ name: routeName.value, params: { search: '30-days-old-inventory' } });
      sixtydays = routes.resolve({ name: routeName.value, params: { search: '30-to-60-days-old-inventory' } });
      ninetydays = routes.resolve({ name: routeName.value, params: { search: '60-to-90-days-old-inventory' } });
      ninetyplus = routes.resolve({ name: routeName.value, params: { search: '90-plus-days-old-inventory' } });
      noimage = routes.resolve({ name: routeName.value, params: { search: 'inventory-with-no-image' } });
      noprice = routes.resolve({ name: routeName.value, params: { search: 'inventory-with-no-price' } });
    }

    const {
      ThirtyDaysCarsCount,
      SixtyDaysCarsCount,
      NinetyDaysCarsCount,
      NinetyPlusDaysCarsCount,
      CarsWithNoImageCount,
      CarsWithNoPriceCount,
      UsedCars,
      NewCars,
      error,
      loadVehicleCount,
    } = getVehicleCount(type, status);
    loadVehicleCount();
    // Disable Loader:
    return {
      ThirtyDaysCarsCount,
      SixtyDaysCarsCount,
      NinetyDaysCarsCount,
      NinetyPlusDaysCarsCount,
      CarsWithNoImageCount,
      CarsWithNoPriceCount,
      UsedCars,
      NewCars,
      error,
      thirtydays,
      sixtydays,
      ninetydays,
      ninetyplus,
      noimage,
      noprice,
      DashboardCheck,
      routeName
    };
  }
};
</script>