import { ref } from 'vue';
import axios from 'axios';
const getVehicleCount = (type, status) => {
    const ThirtyDaysCarsCount = ref(0);
    const SixtyDaysCarsCount = ref(0);
    const NinetyDaysCarsCount = ref(0);
    const NinetyPlusDaysCarsCount = ref(0);
    const CarsWithNoImageCount = ref(0);
    const CarsWithNoPriceCount = ref(0);
    const UsedCars = ref(0);
    const NewCars = ref(0);
    const error = ref('');
    try {
        const loadVehicleCount = async () => {
            await axios
                .post(axios.defaults.baseUrl + "getVehicleCount",
                    {
                        inventory_type: type,
                        inventory_status: status,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    ThirtyDaysCarsCount.value = response.data.thirtyDaysCars;
                    SixtyDaysCarsCount.value = response.data.sixtyDaysCars;
                    NinetyDaysCarsCount.value = response.data.ninetyDaysCars;
                    NinetyPlusDaysCarsCount.value = response.data.ninetyPlusDaysCars;
                    CarsWithNoImageCount.value = response.data.carsWithNoImage;
                    CarsWithNoPriceCount.value = response.data.carsWithNoPrice;
                    UsedCars.value = response.data.usedCars;
                    NewCars.value = response.data.newCars;
                })
                .catch((error) => {
                    error.value = 'No User Type Found';
                });
        }
        return { ThirtyDaysCarsCount, SixtyDaysCarsCount, NinetyDaysCarsCount, NinetyPlusDaysCarsCount, CarsWithNoImageCount, CarsWithNoPriceCount, UsedCars, NewCars, error, loadVehicleCount };
    } catch (error) {
        error.value = 'No User Type Found';
    }
}
export default getVehicleCount;