<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- channel-table -->
    <div class="channel-tabel">
      <div class="row">
        <div class="col-lg-12">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <h4>Top 50 Referrers</h4>
            <table class="display" id="topReferralsTable">
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Sessions</th>
                  <th>New Sessions</th>
                  <th style="width:75px">New Users</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in topReferrals" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- channel-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import axios from "axios";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    let topReferrals = ref([]);
    let topReferralsError = ref("");

    const getTopReferrals = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getTopReferrals",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          topReferrals.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#topReferralsTable")) {
            $("#topReferralsTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#topReferralsTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((topReferralsError) => {
          topReferralsError.value = "No Sessions for Google Analytics found";
        });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getTopReferrals();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      $("#topReferralsTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      dateRangePickerInit();
    });

    return {
      topReferrals,
      topReferralsError,
      resetFilters
    };
  },
};
</script>