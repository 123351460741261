<template>
  <router-view />
</template>
<script>
import "bootstrap";
import "popper.js";
export default {
  mounted() {},
};
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/animate.css";
@import "./assets/css/normalize.css";
@import "./assets/css/select2.min.css";
@import "./assets/css/slick.css";
@import "./assets/css/daterangepicker.css";
@import "./assets/css/dropzone.css";
@import "./assets/css/jquery.dataTables.min.css";
@import "./assets/css/responsive.dataTables.min.css";
@import "./assets/css/style.css?v=1.008";
@import "./assets/css/responsive.css";
table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    background-color: #2A4385;
}
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
    background-color: #d33333 !important;
}
</style>
