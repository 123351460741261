<template>
  <Dashborad>
    <!-- Blog Posts table -->
    <div class="table-area blog-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#blogPostModal"
          >Add Blog</a
        >
      </div>

      <table class="display" id="blogs">
        <thead>
          <tr>
            <th class="all">Title</th>
            <th>URL (Slug)</th>
            <th style="width: 60px">Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Blog Posts table -->
    <!-- Add blog Posts modal -->
    <div
      class="modal fade"
      id="blogPostModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Edit Blog Post
            </h5>
            <h5 v-else class="modal-title" id="staticBackdropLabel">
              Add Blog Post
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            class="myform"
            @submit.prevent="onSubmit"
            enctype="multipart/form-data"
          >
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-8">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label>Blog Title</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="blog.label"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Blog URL</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="blog.slug"
                      />
                      <small>
                        <img
                          class="img-fluid"
                          src="@/assets/img/warning.svg"
                          alt=""
                        />
                        Will be used to make urls. Do not use any spaces,
                        slashes or special characters</small
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label>Featured Image</label>
                      <input type="file" @change="onFileChange" />
                      <img
                        v-if="blog.featured_image"
                        :src="blog.featured_image"
                        alt="Featured Image"
                        style="height: 75px; margin-top: 5px"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <label>Blog Details</label>
                  <TinyMceEditor
                    v-model="blog.details"
                    api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre"
                    :init="{
                      menubar: false,
                      branding: false,
                      extended_valid_elements: 'span[class|align|style]',
                      forced_root_block_attrs: { class: 'root_block_p' },
                      element_format: 'html',
                      relative_urls: false,
                      remove_script_host: false,
                      height: 250,
                      browser_spellcheck: true,
                      verify_html: false,
                      images_upload_handler: tinyMceUploader,
                      plugins:
                        'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                      toolbar:
                        'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code',
                    }"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>SEO Title</label>
                  <input
                    type="text"
                    class="form-control"
                    required
                    maxlength="70"
                    v-model="blog.meta_title"
                  />
                  <small
                    >Maximum 70 characters. Please do not use any special
                    characters</small
                  >
                </div>

                <div class="form-group col-md-12">
                  <label>SEO Description</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    required
                    maxlength="300"
                    placeholder=""
                    v-model="blog.meta_description"
                  ></textarea>
                  <small
                    >Maximum 300 characters. Please do not use any special
                    characters</small
                  >
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closeModal" class="cancle-btn">
                  Cancel
                </button>
                <button type="submit" class="send-btn">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add blog Posts modal -->
    <!-- delete Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this post?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button type="button" @click="deletePost" class="send-btn">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete Modal -->
  </Dashborad>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import { onMounted, ref } from "vue";
import Dashborad from "./../../layouts/Dashboard.vue";
import TinyMceEditor from "@tinymce/tinymce-vue";
export default {
  components: {
    Dashborad,
    TinyMceEditor,
  },
  setup() {
    let blog = ref({});
    let allowSubmit = ref(true);
    let editMode = ref(false);
    let newImageForUpload = ref("no");
    let idForDelete = ref("");

    // Close Modal
    const closeModal = () => {
      blog.value = {};
      editMode.value = false;
      $(".tox-tbtn").removeClass("tox-tbtn--enabled");
      $(".tox.tox-silver-sink.tox-tinymce-aux").html("");
      $("#blogPostModal").modal("hide");
      $("#deleteModal").modal("hide");
    };

    // Submit form
    const onSubmit = () => {
      if (allowSubmit.value) {
        if (editMode.value) {
          updateBlogPost();
        } else {
          addBlogPost();
        }
      }
    };

    // File Change Method for Image upload field
    const onFileChange = async (element) => {
      let file = element.target.files[0];
      if (file["size"] > 1048576) {
        window.toast.fire({
          icon: "error",
          title: "Image size can not be greater than 1MB",
        });
        return false;
      }
      if (
        file["type"] != "image/png" &&
        file["type"] != "image/jpg" &&
        file["type"] != "image/jpeg"
      ) {
        window.toast.fire({
          icon: "error",
          title: "File format not supported",
        });
        allowSubmit.value = false;
        return false;
      }
      let reader = new FileReader();
      reader.onloadend = (file) => {
        if (file) {
          newImageForUpload.value = "yes";
          blog.value.featured_image = reader.result;
        }
      };
      reader.readAsDataURL(file);
    };

    // Add Blog Post
    const addBlogPost = async () => {
      if (
        blog.value.featured_image == null ||
        blog.value.featured_image == "" ||
        blog.value.featured_image == "undefined"
      ) {
        window.toast.fire({
          icon: "error",
          title: "Featured Image is required",
        });
        return false;
      }
      let data = new FormData();
      data.append("label", blog.value.label);
      data.append("slug", blog.value.slug);
      data.append("featured_image", blog.value.featured_image);
      data.append("details", blog.value.details);
      data.append("meta_title", blog.value.meta_title);
      data.append("meta_description", blog.value.meta_description);
      window.showHideMainLoader(true);
      await axios
        .post(axios.defaults.baseUrl + "addBlogPost", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Blog Post added successfully",
            });
            getBlogPosts();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };
    // Open Edit Modal
    $(document).on("click", ".editPost", function () {
      editMode.value = true;
      axios
        .get(axios.defaults.baseUrl + "getPost/" + $(this).data("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          blog.value = response.data;
          newImageForUpload.value = "no";
          editMode.value = true;
          $("#blogPostModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            console.log(error);
          }
        });
    });
    // Update Blog Post
    const updateBlogPost = async () => {
      let data = new FormData();
      data.append("label", blog.value.label);
      data.append("slug", blog.value.slug);
      data.append("featured_image", blog.value.featured_image);
      data.append("details", blog.value.details);
      data.append("meta_title", blog.value.meta_title);
      data.append("meta_description", blog.value.meta_description);
      data.append("new_image_upload", newImageForUpload.value);
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "updateBlogPost/" + blog.value.id,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Blog Post updated successfully",
            });
            getBlogPosts();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };
    // Open Delete Modal for Blog Post
    $(document).on("click", ".deletePost", function () {
      idForDelete.value = $(this).data("id");
      $("#deleteModal").modal("show");
    });
    // Delete Blog Post
    const deletePost = async () => {
      window.showHideMainLoader(true);
      await axios
        .delete(
          axios.defaults.baseUrl + "deleteBlogPost/" + idForDelete.value,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "Blog Post deleted successfully",
            });
            getBlogPosts();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: "Error, Please try again.",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const getBlogPosts = async () => {
      if ($.fn.DataTable.isDataTable("#blogs")) {
        $("#blogs").DataTable().clear().destroy();
      }
      await $("#blogs")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: axios.defaults.baseUrl + "getBlogPosts",
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "label" },
            { data: "slug" },
            { data: "action_links" },
          ],
          order: [],
          columnDefs: [{ targets: [2], orderable: false }],
        });
    };

    const tinyMceUploader = async (blobInfo, success, failure) => {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", axios.defaults.baseUrl + "editorImageUpload");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + localStorage.getItem("auth_token")
      );
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failure("HTTP Error: " + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json) {
          failure("Invalid JSON: " + xhr.responseText);
          return;
        }
        if (json.status == "error") {
          failure(json.location);
          return;
        }
        success(json.location);
      };
      formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    };

    onMounted(() => {
      getBlogPosts();
    });

    return {
      blog,
      editMode,
      onFileChange,
      closeModal,
      addBlogPost,
      updateBlogPost,
      onSubmit,
      deletePost,
      tinyMceUploader,
    };
  },
};
</script>
<style>
#blogs tbody tr td {
  position: relative;
}
</style>