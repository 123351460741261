import { ref } from 'vue';
import axios from 'axios';
const getUsers = () => {
    const users = ref([]);
    const error = ref('');
    try {
        const loadUsers = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getListUsers",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    users.value = response.data;
                })
                .catch((error) => {
                    error.value = 'No Users Found';
                });
        }
        return { users, error, loadUsers };
    } catch (error) {
        error.value = 'No User Type Found';
    }
}
export default getUsers;