import { ref } from 'vue';
import axios from 'axios';
const getGmbRefreshToken = () => {
    const token = ref([]);
    try {
        const loadToken = async () => {
            await axios
                .post(axios.defaults.baseUrl + "getGmbRefreshToken",
                    {
                        dealer: '',
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    token.value = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        return { token, loadToken };
    } catch (error) {
        console.log(error);
    }
}
export default getGmbRefreshToken;