<template>
  <Dashboard>
    <!-- dealership-form -->
    <div class="dealership-form">
      <form class="myform" @submit.prevent="updateDealerOtherInfo">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Dealer Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              v-model="dealerInfo.dealer_name"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Email Address</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email Address"
              v-model="dealerInfo.dealer_email"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Website URL</label>
            <input
              type="text"
              class="form-control"
              placeholder="https://www.website.com"
              v-model="dealerInfo.dealer_url"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Phone Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="dealerInfo.dealer_phone"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Service Phone Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="dealerInfo.dealer_service_phone"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Footer Phone Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="dealerInfo.dealer_footer_phone_number"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Footer Phone Number 2</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="dealerInfo.dealer_footer_phone_number2"
            />
          </div>
          <div class="form-group col-md-4">
            <label>IT Support Contact Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="IT Support Contact Name"
              v-model="dealerInfo.dealer_it_support_contact_name"
            />
          </div>
          <div class="form-group col-md-4">
            <label>IT Support Contact Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="dealerInfo.dealer_it_support_contact_number"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Street</label>
            <input
              type="text"
              class="form-control"
              placeholder="Streed Address"
              v-model="dealerInfo.dealer_street"
            />
          </div>
          <div class="form-group col-md-4">
            <label>City</label>
            <input
              type="text"
              class="form-control"
              placeholder="City"
              v-model="dealerInfo.dealer_city"
            />
          </div>
          <div class="form-group col-md-2">
            <label>State</label>
            <select
              class="form-control tags2"
              v-model="dealerInfo.dealer_state"
            >
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NB">NB</option>
              <option value="NE">NE</option>
              <option value="NS">NS</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="ON">ON</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="PE">PE</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label for="inputZip">Zip Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Zip Code"
              v-model="dealerInfo.dealer_zip"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="inputZip">Complete Address</label>
            <input
              type="text"
              class="form-control"
              placeholder=" Complete Address same"
              v-model="dealerInfo.dealer_full_address"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="inputZip">Complete Address 2</label>
            <input
              type="text"
              class="form-control"
              placeholder="Complete Address 2"
              v-model="dealerInfo.dealer_full_address2"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="inputZip">Serving City</label>
            <input
              type="text"
              class="form-control"
              placeholder="Serving City"
              v-model="dealerInfo.dealer_serving"
            />
          </div>
          <div class="form-group col-md-12">
            <label for="inputZip">Disclaimer</label>
            <textarea
              class="form-control"
              rows="3"
              v-model="dealer.disclaimer"
            ></textarea>
          </div>
          <div class="col-md-12">
            <div class="cus-btn text-right">
              <router-link :to="{ name: 'Dashboard' }" class="cancle-btn">
                Back</router-link
              >
              <button type="submit" class="send-btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- dealership-form -->
  </Dashboard>
</template>
<script>
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard.vue";
import getDealerOtherInfo from "../../composeables/getDealerOtherInfo";
import $ from "jquery";
export default {
  components: {
    Dashboard,
  },
  setup() {
    // Get Dealer Information:
    const { dealerInfo, dealer, dealerInfoError, loadDealerInfo } =
      getDealerOtherInfo();
    loadDealerInfo();
    // Update Dealer Information
    const updateDealerOtherInfo = async () => {
      $('.loaders').show();
      await axios
        .post(
          axios.defaults.baseUrl + "updateDealerOtherInfo",
          {
            dealer: dealer.value,
            dealer_other_info: dealerInfo.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          $('.loaders').hide();
          dealerInfo.value = response.data.dealer_other_info;
          dealer.value = response.data.dealer;
           window.toast.fire({
              icon: "success",
              title: "Dealer settings updated successfully",
            });
        })
        .catch((error) => {
          $('.loaders').hide();
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again...",
            });
          }
        });
    };
    return { dealerInfo, dealer, dealerInfoError, loadDealerInfo, updateDealerOtherInfo };
  },
};
</script>
<style scoped>
.cancle-btn {
  text-decoration: none;
}
</style>