<template>
  <Dashboard>
    <!-- Vehicle Infromation -->
    <div class="vehicle-information">
      <div class="row">
        <div class="col-md-4 col-lg-3 text-center">
          <div class="photo" v-if="imageList2.length == 0">
            <div class="upload" @click="openImagesModal('imagesModal')">
              <img class="img-fluid" src="@/assets/img/upload.svg" alt="" />
              <h2>Upload Images</h2>
              <h4>Click to upload</h4>
              <div class="button-wrapper">
                <span class="label"> Upload Image </span>
              </div>
            </div>
          </div>

          <!-- details-slidder -->
          <div class="details-slider" v-else>
            <div class="sliders slider-for">
              <div v-for="image in imageList2" :key="image">
                <img class="img-fluid" :src="image" alt="" />
              </div>
            </div>
            <div class="sliders slider-nav">
              <div v-for="image in imageList2" :key="image">
                <img class="img-fluid" :src="image" alt="" />
              </div>
            </div>
          </div>
          <button v-if="imageList2.length > 0" type="button" @click="openImagesModal('imagesModal')" class="send-btn">Manage image</button>
        </div>
        <div class="col-md-8 col-lg-9">
          <form
            class="myform"
            id="addVehicle"
            @submit.prevent="handleVehicleCreateRequest"
          >
            <!-- vin-number -->
            <div class="vin-number">
              <div class="form-row">
                <div class="col-md-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="vehicle.vin"
                    placeholder="Enter your VIN Number of the Vehicle"
                  />
                </div>
                <div class="col-md-2 col-6">
                  <div class="decode">
                    <button
                      type="button"
                      class="send-btn"
                      @click.prevent="handleVinDecodeRequest"
                    >
                      Decode
                    </button>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="decode">
                    <button type="submit" class="save-btn">Save</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- vin-number -->

            <div class="infomation vehicle-information">
              <h4>Vehicle Infromation</h4>
              <div class="form-row">
                <!-- left -->
                <div class="col-md-6">
                  <div class="form-row rightbar">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Year</label>
                      <select
                        class="form-control select2 yearSelect"
                        v-model="vehicle.year"
                      >
                        <option v-for="year in years" :key="year" :value="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Make</label>
                      <select
                        class="form-control select2 makeSelect"
                        v-model="vehicle.make"
                      >
                        <option
                          v-for="make in vehicleMakes"
                          :key="make.id"
                          :value="make.make"
                        >
                          {{ make.make }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Model</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.model"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Trim</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.trim"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Fuel Type</label>
                      <select
                        class="form-control select2"
                        v-model="vehicle.fuel"
                      >
                        <option
                          v-for="type in vehicleFuelTypes"
                          :key="type.id"
                          :value="type.type"
                        >
                          {{ type.type }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Mileage</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.mileage"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">MPG City</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.mpg_city"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">MPG Highway</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.mpg"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Engine</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.engine"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Displacement</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.displacement"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Transmission</label>
                      <select
                        class="form-control select2 transmissionSelect"
                        v-model="vehicle.transmission"
                      >
                        <option
                          v-for="transmission in vehicleTransmission"
                          :key="transmission.id"
                          :value="transmission.transmission"
                        >
                          {{ transmission.transmission }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Cylinders</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.cylinders"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Doors</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.doors"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Drive Type</label>
                      <select
                        class="form-control select2 drivetrainSelect"
                        v-model="vehicle.drivetrain"
                      >
                        <option
                          v-for="driveType in vehicleDriveTypes"
                          :key="driveType.id"
                          :value="driveType.drive_type"
                        >
                          {{ driveType.drive_type }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Body Style</label>
                      <select
                        class="form-control select2 bodyStyleSelect"
                        v-model="vehicle.body_style"
                      >
                        <option
                          v-for="body_style in vehicleBodyStyle"
                          :key="body_style.id"
                          :value="body_style.body_style"
                        >
                          {{ body_style.body_style }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Autosize</label>
                      <select
                        class="form-control select2 autoSizeSelect"
                        v-model="vehicle.autosize"
                      >
                        <option
                          v-for="size in vehicleAutoSize"
                          :key="size.id"
                          :value="size.size"
                        >
                          {{ size.size }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- left -->

                <!-- right -->
                <div class="col-md-6">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Dealer Certified</label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >Yes
                          <input
                            type="radio"
                            v-model="vehicle.certified"
                            value="1"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >No
                          <input
                            type="radio"
                            v-model="vehicle.certified"
                            value="0"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Date in Stock</label>
                      <input
                        type='text'
                        readonly
                        id="date_in_stock"
                        class="form-control reportrange datesinglebackground"
                        v-model="vehicle.date_in_stock"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Stock Number</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.stock_no"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Exterior Color</label>
                      <select
                        class="form-control select2"
                        v-model="vehicle.ext_color"
                      >
                        <option>Select</option>
                        <option
                          v-for="color in vehicleExteriorColor"
                          :key="color.id"
                          :value="color.color"
                        >
                          {{ color.color }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Interior Color</label>
                      <select
                        class="form-control select2"
                        v-model="vehicle.int_color"
                      >
                        <option
                          v-for="color in vehicleInteriorColor"
                          :key="color.id"
                          :value="color.color"
                        >
                          {{ color.color }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">MSRP</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.msrp"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label for="inputEmail4">Condition / Status</label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >New
                          <input
                            type="radio"
                            v-model="vehicle.new_used"
                            value="New"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >Used
                          <input
                            type="radio"
                            v-model="vehicle.new_used"
                            value="Used"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >Live
                          <input
                            type="radio"
                            v-model="vehicle.status"
                            value="Live"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >In Prep
                          <input
                            type="radio"
                            v-model="vehicle.status"
                            value="In Prep"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Selling Price</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.price"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Special Price</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.special_price"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Internet Price</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.internet_price"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Invoice Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.invoice_amount"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Show in Specials?</label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >Yes
                          <input
                            type="radio"
                            v-model="vehicle.show_in_specials"
                            value="yes"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >No
                          <input
                            type="radio"
                            v-model="vehicle.show_in_specials"
                            value="no"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">On Hold?</label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >Yes
                          <input
                            type="radio"
                            v-model="vehicle.on_hold"
                            value="yes"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >No
                          <input
                            type="radio"
                            v-model="vehicle.on_hold"
                            value="no"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- right -->

                <div class="form-group col-md-12">
                  <TinyMceEditor v-model="vehicle.description"
                      api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre"
                      :init="{
                        menubar: false,
                        branding: false,
                        extended_valid_elements: 'span[class|align|style]',
                        forced_root_block_attrs: {'class': 'root_block_p'},
                        element_format: 'html',
                        relative_urls: false,
                        remove_script_host: false,
                        height: 200,
                        browser_spellcheck: true,
                        verify_html: false,
                        images_upload_handler: tinyMceUploader,
                        plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                        toolbar: 'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code'
                      }"
                  />
                </div>

                <div class="form-group col-md-12">
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Craigslist Ad Keywords"
                    v-model="vehicle.craigslist_keywords"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Internal Field"
                    v-model="vehicle.internal_field"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Craigslist Title Text"
                    v-model="vehicle.craigslist_title"
                  />
                </div>

                <div class="col-md-12">
                  <div class="cus-btn text-right">
                    <button type="submit" class="send-btn">Add Vehicle</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Vehicle Infromation -->
    <!-- Manage Images Modal-->
    <div
        class="modal fade"
        id="imagesModal"
        tabindex="-1"
        data-backdrop="static"
        data-keyboard="false"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="imageUploadContainer" style="border-radius:20px;">
              <div class="dz-message needsclick">
                <i class="fa fa-cloud-upload fa-3x" style="color: #0087F7;cursor: pointer;"></i><br>
                Drop files here or click to upload.<br>
                <span class="note needsclick">(Maximum allowed filesize is <strong>10Mbs</strong>.)</span>
              </div>
            </div>
            <hr>
            <draggable
                :list="imageList"
                group="images"
                @start="drag=true"
                @end="drag=false"
                item-key="id"
                style="display:flex;flex-shrink: 0;flex-flow: row wrap;justify-content: flex-end;align-items: flex-end;">
              <template #item="{element, index}">
                <div style="width:80px;margin-right:5px;position:relative">
                  <img style="width:80px;border-radius:4px" :src="element" />
                  <a href="javascript:;" @click="onRemove(index)" style="position:absolute;top:0;right:0;background: #fff;">
                    <img
                        style="width:20px"
                        src="@/assets/img/delete-solid.svg"
                        alt=""
                    />
                  </a>
                </div>
              </template>
            </draggable>
          </div>

          <div class="modal-footer">
            <div class="cus-btn">
              <button type="button" @click="cancelImageChanges" class="cancle-btn">
                Cancel Changes
              </button>
              <button type="button" class="send-btn" @click="updateVehicleImages">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Manage Images Modal-->
  </Dashboard>
</template>
<script>
import {onMounted, ref} from "vue";
import axios from "axios";
import $ from "jquery";
import routes from './../../routes';
import "daterangepicker";
import moment from "moment";
import Dashboard from "./../../layouts/Dashboard.vue";
import getVehicleMake from "./../../composeables/getVehicleMake";
import getVehicleFuelTypes from "./../../composeables/getVehicleFuelTypes";
import getVehicleTransmission from "./../../composeables/getVehicleTransmission";
import getVehicleDriveTypes from "./../../composeables/getVehicleDriveTypes";
import getVehicleBodyStyle from "./../../composeables/getVehicleBodyStyle";
import getVehicleAutoSize from "./../../composeables/getVehicleAutoSize";
import getVehicleExteriorColor from "./../../composeables/getVehicleExteriorColor";
import getVehicleInteriorColor from "./../../composeables/getVehicleInteriorColor";
import { Dropzone } from "dropzone";
import draggable from "vuedraggable";
import TinyMceEditor from '@tinymce/tinymce-vue'
export default {
  components: {
    Dashboard,
    draggable,
    TinyMceEditor
  },
  setup() {
    let drag = ref(false);
    let isFetching = ref(false);
    let imageList = ref([]);
    let imageList2 = ref([]);
    let vehicle = ref({});
    let years = ref([]);
    let error = ref("");
    // Year Default Values:
    let year = 1900;
    let date = new Date();
    let endYear = date.getFullYear() + 1;
    let end = endYear - year;
    let yearsArray = [];
    for (let count = 0; count <= end; count++) {
      yearsArray[count] = year + count;
    }

    const onRemove = async (index) => {
      imageList.value.splice(index, 1);
    }

    const closeImagesModal = async (element) => {
      $('#'+element).modal('hide');
    }

    const openImagesModal = async (element) => {
      $('#'+element).modal('show');
    }

    const cancelImageChanges = async () => {
      imageList.value = imageList2.value;
      closeImagesModal('imagesModal');
    }

    // Update Request to update the vehicle images
    const updateVehicleImages = async () => {
      carouselUnset();
      vehicle.value.imagelist = imageList.value.toString();
      imageList2.value = imageList.value;
      setTimeout(() => {
        carouselInit();
      })
      closeImagesModal('imagesModal');
    }

    const carouselInit = async() => {
      //details slider
      $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
      });


      $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        responsive: [{
          breakpoint: 992,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 2
          }
        },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              slidesToShow: 2
            }
          }
        ]
      })
    }

    const carouselUnset = async() => {
      $('.slider-for').slick('unslick');
      $('.slider-nav').slick('unslick');
    }

    const imagesHandler = async () => {
      $(".dz-preview").remove();
      $(".dz-message.needsclick").show();
      new Dropzone("div.imageUploadContainer", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
        url: axios.defaults.baseUrl + "uploadInventoryImages",
        acceptedFiles: "image/*",
        maxFilesize: "10",
        parallelUploads: "1",
        timeout: 0,
        init: function() {
          $(".add_more_images_anchor").remove();
          this.on('addedfile', function(file){
            console.log(file);
            $(".dz-message.needsclick").hide();
            $(".add_more_images_anchor").remove();
            $("div.imageUploadContainer").append('<div class="add_more_images_anchor dz-preview" style="border: 1px dashed #0087F7;border-radius: 20px;cursor: pointer;"><div class="dz-image" style="cursor: pointer;">&nbsp;</div><div class="dz-details" style="opacity:1;width: 100%;height: 100%;cursor: pointer;"><div class="dz-size" style="cursor: pointer;"><span data-dz-size="" style="cursor: pointer;"><i class="fa fa-cloud-upload fa-3x" style="color: #0087F7;cursor: pointer;"></i></div><div class="dz-filename" style="cursor: pointer;"><span data-dz-name="" style="cursor: pointer;border: none;">Add More</span></div></div></div>');
          });
          this.on("sending", function(file, xhr, formData){
            formData.append("id", vehicle.value.id);
          });
          this.on("complete", function(file, xhr, formData){
            console.log(file+xhr+formData);
            $(".add_more_images_anchor").remove();
            $("div.imageUploadContainer").append('<div class="add_more_images_anchor dz-preview" style="border: 1px dashed #0087F7;border-radius: 20px;cursor: pointer;"><div class="dz-image" style="cursor: pointer;">&nbsp;</div><div class="dz-details" style="opacity:1;width: 100%;height: 100%;cursor: pointer;"><div class="dz-size" style="cursor: pointer;"><span data-dz-size="" style="cursor: pointer;"><i class="fa fa-cloud-upload fa-3x" style="color: #0087F7;cursor: pointer;"></i></div><div class="dz-filename" style="cursor: pointer;"><span data-dz-name="" style="cursor: pointer;border: none;">Add More</span></div></div></div>');
          });
          this.on("success", function(file, response){
            if (response.status == 'success') {
              imageList.value.push(response.data);
              setTimeout(() => {
                carouselUnset();
                carouselInit();
              })
            }else {
              window.toast.fire({
                icon: response.status,
                title: response.data,
              });
            }
          });
        }
      });
      $(document).on("click", ".add_more_images_anchor", function() {
        $('div.imageUploadContainer').get(0).dropzone.hiddenFileInput.click();
      });
    }

    years.value = yearsArray.reverse();
    // Get Vehicle Make Default Values:
    const { loadVehicleMake, vehicleMakes, err } = getVehicleMake();
    loadVehicleMake();
    // Get Vehicle Fuel Types
    const { vehicleFuelTypes, loadVehicleFuelTypes, fuelTypeError } =
      getVehicleFuelTypes();
    loadVehicleFuelTypes();
    // Get Vehicle Transmission Data
    const { vehicleTransmission, loadVehicleTransmission, transmissionError } =
      getVehicleTransmission();
    loadVehicleTransmission();
    // Get Vehicle Drive Types
    const { vehicleDriveTypes, loadVehicleDriveTypes, DriveTypesError } =
      getVehicleDriveTypes();
    loadVehicleDriveTypes();
    // Get Vehicle Body Style
    const { vehicleBodyStyle, loadVehicleBodyStyle, BodyStyleError } =
      getVehicleBodyStyle();
    loadVehicleBodyStyle();
    // Get Vehicle Auto Size
    const { vehicleAutoSize, loadVehicleAutoSize, AutoSizeError } =
      getVehicleAutoSize();
    loadVehicleAutoSize();
    // Get Vehicle Exterior Color
    const {
      vehicleExteriorColor,
      loadVehicleExteriorColor,
      ExteriorColorError,
    } = getVehicleExteriorColor();
    loadVehicleExteriorColor();
    // Get Vehicle Interior Color
    const {
      vehicleInteriorColor,
      loadVehicleInteriorColor,
      InteriorColorError,
    } = getVehicleInteriorColor();
    loadVehicleInteriorColor();

    // Vin Decode Process:
    const handleVinDecodeRequest = async () => {
      $(".loaders").fadeIn();
      await axios
        .post(
          axios.defaults.baseUrl + "vinDecode",
          {
            vehicle,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (!response.data.success) {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          } else {
            vehicle.value = response.data.vehicle;
            loadVehicleMake();
            loadVehicleFuelTypes();
            loadVehicleTransmission();
            loadVehicleDriveTypes();
            loadVehicleBodyStyle();
            loadVehicleAutoSize();
            loadVehicleExteriorColor();
            loadVehicleInteriorColor();
            if (vehicle.value.year) {
              $(".vehicle-information .yearSelect")
                .select2()
                .val(vehicle.value.year)
                .trigger("change");
            }
            if (vehicle.value.make) {
              $(".vehicle-information .makeSelect")
                .select2()
                .val(vehicle.value.make)
                .trigger("change");
            }
            if (vehicle.value.transmission) {
              $(".vehicle-information .transmissionSelect")
                .select2()
                .val(vehicle.value.transmission)
                .trigger("change");
            }
            if (vehicle.value.drivetrain) {
              $(".vehicle-information .drivetrainSelect")
                .select2()
                .val(vehicle.value.drivetrain)
                .trigger("change");
            }
            if (vehicle.value.body_style) {
              $(".vehicle-information .bodyStyleSelect")
                .select2()
                .val(vehicle.value.body_style)
                .trigger("change");
            }
            if (vehicle.value.autosize) {
              $(".vehicle-information .autoSizeSelect")
                .select2()
                .val(vehicle.value.autosize)
                .trigger("change");
            }
            if (vehicle.value.date_in_stock_returns) {
              vehicle.value.date_in_stock = new Date(
                vehicle.value.date_in_stock_returns
              );
            }
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
          }
          $(".loaders").fadeOut();
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: error,
            });
          }
          $(".loaders").fadeOut();
        });
    };
    // Add Vehicle Process:
    const handleVehicleCreateRequest = async () => {
      $(".loaders").fadeIn();
      await axios
        .post(
          axios.defaults.baseUrl + "addVehicle",
          { vehicle: vehicle },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            $.each(response.data.message, function (key, value) {
              window.toast.fire({
                icon: "error",
                title: value,
              });
            });
          } else {
            if (response.data.status) {
              routes.push({ name: "UsedInventory" });
              window.toast.fire({
                icon: "success",
                title: "Inventory Saved",
              });
            }
          }
          $(".loaders").fadeOut();
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: error,
            });
          }
          $(".loaders").fadeOut();
        });
    }

    const datePickerInit = async () => {
      console.log(moment(vehicle.value.date_in_stock).format('MM/DD/YYYY')) ;
      $(".reportrange").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        startDate: moment(vehicle.value.date_in_stock).format('MM/DD/YYYY'),
        endDate: moment(vehicle.value.date_in_stock).format('MM/DD/YYYY'),
        minYear: 1900,
        maxYear: parseInt(moment().add(1, "year").format('YYYY'),10),
        locale: {
          format: 'MM/DD/YYYY'
        }
      }, function(start) {
        vehicle.value.date_in_stock = moment(start).format('MM/DD/YYYY');
      });
    }

    const tinyMceUploader = async (blobInfo, success, failure) => {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', axios.defaults.baseUrl + "editorImageUpload");
      xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("auth_token"));
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json) {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        if (json.status == 'error') {
          failure(json.location);
          return;
        }
        success(json.location);
      };
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    }

    onMounted( () => {
      vehicle.value.date_in_stock = moment().format('MM/DD/YYYY');
      datePickerInit();
      imagesHandler();
      $(".vehicle-information .select2").select2({
        placeholder: "Select",
        allowClear: true,
      });
    })
let today = vehicle.value.date_in_stock = new Date();
    return {
      years,
      vehicleMakes,
      vehicle,
      vehicleFuelTypes,
      vehicleTransmission,
      vehicleDriveTypes,
      vehicleBodyStyle,
      vehicleAutoSize,
      vehicleExteriorColor,
      vehicleInteriorColor,
      error,
      err,
      fuelTypeError,
      transmissionError,
      DriveTypesError,
      BodyStyleError,
      AutoSizeError,
      ExteriorColorError,
      InteriorColorError,
      handleVinDecodeRequest,
      handleVehicleCreateRequest,
      drag,
      isFetching,
      onRemove,
      openImagesModal,
      cancelImageChanges,
      updateVehicleImages,
      imagesHandler,
      imageList,
      imageList2,
      today,
      tinyMceUploader
    };
  }
};
</script>
