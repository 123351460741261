<template>
  <Dashboard>
    <!-- Analytics-table -->
    <div class="table-area feedback-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#specialsimagesModal"
          >Add New</a
        >
      </div>

      <table class="display" id="specialsimages">
        <thead>
          <tr>
            <th class="all">Image</th>
            <th style="width: 50px">Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Analytics-table -->

    <!-- ads-Modal -->
    <div
      class="modal select-modal fade"
      id="specialsimagesModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="exampleModalLabel">
              Update Image
            </h5>
            <h5 v-else class="modal-title" id="exampleModalLabel">
              Add new Image
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Image</label>
                    <input type="file" @change="onFileChange" />
                    <img
                        v-if="specialsimages.image"
                        :src="specialsimages.image"
                        alt="Image"
                        style="height:75px;margin-top:5px"
                    />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" @click="closeModal" class="cancle-btn">
                Back
              </button>
              <button
                v-if="editMode"
                type="submit"
                class="send-btn"
                @click="updateSpecialsImagesGroup"
              >
                Submit
              </button>
              <button
                v-else
                type="submit"
                class="send-btn"
                @click="addSpecialsImagesGroup"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ads-Modal -->
    <!-- delete Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this Image?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button
                type="button"
                @click="deleteSpecialsImagesGroup"
                class="send-btn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    const route = useRoute();
    const specialsId = route.params.id;
    let newImageForUpload = ref('no');

    let specialsimages = ref({
      specials_group_id: "",
      image: ""
    });

    let editMode = ref(false);
    let allowSubmit = ref(true);

    // File Change Method for Image upload field
    const onFileChange = async (element) => {
      let file = element.target.files[0];
      if(file['size'] > 1048576) {
        window.toast.fire({
          icon: "error",
          title: 'Image size can not be greater than 1MB',
        });
        return false;
      }
      if(file['type'] != 'image/png' && file['type'] != 'image/jpg' && file['type'] != 'image/jpeg') {
        window.toast.fire({
          icon: "error",
          title: 'File format not supported',
        });
        allowSubmit.value = false;
        return false;
      }
      let reader = new FileReader();
      reader.onloadend = (file) => {
        if (file) {
          newImageForUpload.value = 'yes';
          specialsimages.value.image = reader.result;
        }
      }
      reader.readAsDataURL(file);
    }

    const closeModal = () => {
      specialsimages.value = {
        specials_group_id: "",
        image: ""
      };
      editMode.value = false;
      newImageForUpload.value = 'no';
      $("#deleteModal").modal("hide");
      $("#specialsimagesModal").modal("hide");
    };

    const getSpecialsImages = async () => {
      window.showHideMainLoader(true);
      if ($.fn.DataTable.isDataTable("#specialsimages")) {
        $("#specialsimages").DataTable().clear().destroy();
      }
      await $("#specialsimages")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: axios.defaults.baseUrl + "getSpecialsImages/"+specialsId,
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "image" },
            { data: "action_links" },
          ],
          order: [],
          columnDefs: [{ targets: [0, 1], orderable: false }],
        });
      window.showHideMainLoader(false);
    };

    // Add SpecialsImagesGroup
    const addSpecialsImagesGroup = async () => {
      let data = new FormData();
      data.append("image", specialsimages.value.image);
      data.append("specials_group_id", specialsId);
      if(newImageForUpload.value == 'no') {
        window.toast.fire({
          icon: "error",
          title: 'No image selected',
        });
        return false;
      }
      
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "addSpecialsImagesGroup",
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getSpecialsImages();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    // Open Edit Modal
    $(document).on("click", ".editSpecialImage", function () {
      newImageForUpload.value = 'no';
      editMode.value = true;
      axios
        .get(axios.defaults.baseUrl + "getSpecialsImagesData/" + $(this).data("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          specialsimages.value = response.data;
          $("#specialsimagesModal").modal("show");
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    });

    // Update SpecialsImagesGroup
    const updateSpecialsImagesGroup = async () => {
      let data = new FormData();
      data.append("image", specialsimages.value.image);
      data.append("specials_group_id", specialsId);

      if(newImageForUpload.value == 'no') {
        window.toast.fire({
          icon: "error",
          title: 'No image selected',
        });
        return false;
      }
      
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "updateSpecialsImagesGroup/" + specialsimages.value.id,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getSpecialsImages();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
        
      window.showHideMainLoader(false);
    };

    // Open Delete Modal
    $(document).on("click", ".deleteGroup", function () {
      specialsimages.value.id = $(this).data("id");
      $("#deleteModal").modal("show");
    });

    const deleteSpecialsImagesGroup = async () => {
      axios
        .delete(
          axios.defaults.baseUrl + "deleteSpecialsImagesGroup/" + specialsId+"/"+specialsimages.value.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getSpecialsImages();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    onMounted(() => {
      getSpecialsImages();
      $(".select2").select2();
    });

    return {
      closeModal,
      addSpecialsImagesGroup,
      updateSpecialsImagesGroup,
      deleteSpecialsImagesGroup,
      specialsimages,
      editMode,
      onFileChange,
    };
  },
};
</script>
<style>
.feedback-table .dataTables_wrapper .dataTables_filter {
  margin-right: 100px;
}
</style>