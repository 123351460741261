<template>
  <div class="search-box pull-right" style="margin-right: 0px">
    <input
      type="text"
      class="reportrange form-control daterangebackground"
      style="background-color: #ffffff !important"
    />
  </div>
  <div class="clearfix"></div>
  <br />
  <!-- tabs-area -->
  <div class="tabs-area">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-link active processed"
          id="nav-Website-tab"
          data-toggle="tab"
          href="#nav-Website"
          role="tab"
          aria-controls="nav-Website"
          aria-selected="true"
        >
          Website Leads
          <h3>{{ websiteCount }}</h3>
        </a>
        <a
          class="nav-link"
          id="nav-Craigslist-tab"
          data-toggle="tab"
          href="#nav-Craigslist"
          role="tab"
          aria-controls="nav-Craigslist"
          aria-selected="false"
        >
          Craigslist Leads
          <h3>{{ craigslistCount }}</h3>
        </a>
        <a
          class="nav-link"
          id="nav-Facebook-tab"
          data-toggle="tab"
          href="#nav-Facebook"
          role="tab"
          aria-controls="nav-Facebook"
          aria-selected="false"
        >
          Facebook Leads
          <h3>{{ facebookCount }}</h3>
        </a>
        <a
          class="nav-link"
          id="nav-tiktok-tab"
          data-toggle="tab"
          href="#nav-tiktok"
          role="tab"
          aria-controls="nav-tiktok"
          aria-selected="false"
        >
          TikTok Leads
          <h3>{{ tiktokCount }}</h3>
        </a>
      </div>
    </nav>
    <div class="tab-content dashboardNavTabs" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-Website"
        role="tabpanel"
        aria-labelledby="nav-Website-tab"
      >
        <div class="graph-tab">
          <apexchart
            v-if="websiteCount > 0"
            ref="myChart"
            width="100%"
            height="300"
            type="area"
            :options="websiteOptions"
            :series="website"
          ></apexchart>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-Craigslist"
        role="tabpanel"
        aria-labelledby="nav-Craigslist-tab"
      >
        <div class="graph-tab">
          <apexchart
            v-if="craigslistCount > 0"
            ref="myChart2"
            width="100%"
            height="300"
            type="area"
            :options="craigslistOptions"
            :series="craigslist"
          ></apexchart>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-Facebook"
        role="tabpanel"
        aria-labelledby="nav-Facebook-tab"
      >
        <div class="graph-tab">
          <apexchart
            v-if="facebookCount > 0"
            ref="myChart3"
            width="100%"
            height="300"
            type="area"
            :options="facebookOptions"
            :series="facebook"
          ></apexchart>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-tiktok"
        role="tabpanel"
        aria-labelledby="nav-tiktok-tab"
      >
        <div class="graph-tab">
          <apexchart
            v-if="tiktokCount > 0"
            ref="myChart4"
            width="100%"
            height="300"
            type="area"
            :options="tiktokOptions"
            :series="tiktok"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
  <!-- tabs-area -->
</template>
<script>
import $ from "jquery";
import VueApexCharts from "vue3-apexcharts";
import axios from "axios";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
//import $ from "jquery";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    let websiteCount = ref("0");
    let craigslistCount = ref("0");
    let facebookCount = ref("0");
    let tiktokCount = ref("0");

    let chartLabels = ref([]);
    let websiteOptions = ref([]);
    let craigslistOptions = ref([]);
    let facebookOptions = ref([]);
    let tiktokOptions = ref([]);

    let website = ref({});
    let craigslist = ref({});
    let facebook = ref({});
    let tiktok = ref({});

    const getLeadsInsights = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "getLeadsInsights",
          {
            dealer_id: localStorage.getItem("dealer_id"),
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.status) {
            websiteCount.value = response.data.data.websiteCount;
            craigslistCount.value = response.data.data.craigslistCount;
            facebookCount.value = response.data.data.facebookCount;
            tiktokCount.value = response.data.data.tiktokCount;
            chartLabels.value = response.data.data.dateLabels;
            // websiteOptions.value.labels = response.data.data.dateLabels;
            // craigslistOptions.value.labels = response.data.data.dateLabels;
            // facebookOptions.value.labels = response.data.data.dateLabels;

            websiteOptions.value = {
              labels: response.data.data.dateLabels,
              chart: {
                type: "line",
                stacked: false,
                height: 300,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: [2, 2, 2],
              },
              colors: ["#FD6D54", "#FCD932", "#46D9AF"],
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },
              fill: {
                opacity: [1, 1, 1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
                labels: {
                  rotate: 0
                }
              },
              yaxis: {
                min: 0,
              },
            };

            craigslistOptions.value = {
              labels: response.data.data.dateLabels,
              chart: {
                type: "line",
                stacked: false,
                height: 300,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: [2, 2],
              },
              colors: ["#FD6D54", "#FCD932"],
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },
              fill: {
                opacity: [1, 1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
                labels: {
                  rotate: 0
                }
              },
              yaxis: {
                min: 0,
              },
            };

            facebookOptions.value = {
              labels: response.data.data.dateLabels,
              chart: {
                type: "line",
                stacked: false,
                height: 300,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: [2],
              },
              colors: ["#FD6D54"],
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },
              fill: {
                opacity: [1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
                labels: {
                  rotate: 0
                }
              },
              yaxis: {
                min: 0,
              },
            };

            tiktokOptions.value = {
              labels: response.data.data.dateLabels,
              chart: {
                type: "line",
                stacked: false,
                height: 300,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: [2],
              },
              colors: ["#FD6D54"],
              plotOptions: {
                bar: {
                  columnWidth: "30%",
                },
              },
              fill: {
                opacity: [1],
                gradient: {
                  inverseColors: false,
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100],
                },
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "text",
                labels: {
                  rotate: 0
                }
              },
              yaxis: {
                min: 0,
              },
            };

            website.value = [
              {
                name: "Forms",
                type: "line",
                data: response.data.data.websiteFormArray.map(Number),
              },
              {
                name: "Calls",
                type: "line",
                data: response.data.data.websiteCallArray.map(Number),
              },
              {
                name: "Sms",
                type: "line",
                data: response.data.data.websiteSmsArray.map(Number),
              },
            ];

            craigslist.value = [
              {
                name: "Calls",
                type: "line",
                data: response.data.data.craigslistCallArray.map(Number),
              },
              {
                name: "Sms",
                type: "line",
                data: response.data.data.craigslistSmsArray.map(Number),
              },
            ];

            facebook.value = [
              {
                name: "Leads",
                type: "line",
                data: response.data.data.facebookArray.map(Number),
              },
            ];

            tiktok.value = [
              {
                name: "Leads",
                type: "line",
                data: response.data.data.tiktokArray.map(Number),
              },
            ];
          }
          $(".nav-link.active").addClass("processed");
        })
        .catch((error) => {
          console.log(error);
        });
      window.showHideMainLoader(false);
    };

    $(document).on("click", ".nav-link", function () {
      if ($(this).hasClass("processed")) {
        console.log("active");
      } else {
        $(this).addClass("processed");
        getLeadsInsights();
      }
    });

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      $(".nav-link").removeClass("processed");
      getLeadsInsights();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      dateRangePickerInit();
    });

    return {
      websiteOptions,
      craigslistOptions,
      facebookOptions,
      tiktokOptions,
      website,
      craigslist,
      facebook,
      tiktok,
      websiteCount,
      craigslistCount,
      facebookCount,
      tiktokCount,
    };
  },
};
</script>
<style>
.dashboardNavTabs .graph-tab {
  min-height: 300px;
}
</style>