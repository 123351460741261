import { createApp } from 'vue'
import App from './App'
import routes from './routes'
const app = createApp(App)
import axios from 'axios'
console.log(process.env.VUE_APP_BASE_URL)
axios.defaults.baseUrl = process.env.VUE_APP_BASE_URL
import Swal from 'sweetalert2'
import $ from "jquery";
$(document).on('focusin', function(e) {
  if ($(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
    e.stopImmediatePropagation();
  }
});

window.swal = Swal
// initialize toast alert notification globally
window.toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
window.confirm = Swal.mixin();
window.showHideMainLoader = async (element) => {
  if(element) {
    $('.loaders').show();
  }else {
    $('.loaders').fadeOut();
  }
}

window.userRoleType = localStorage.getItem("user_type");

app.use(routes).mount('#app')
window.AppName = "Autolinkme"
document.title=window.AppName