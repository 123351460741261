<template>
  <Dashboard>
    <!-- ads-Modal -->
    <div
        class="modal select-modal fade"
        id="googlePostModal"
        tabindex="-1"
        aria-labelledby="googlePostModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="googlePostModalLabel">
              Say something about this vehicle
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <h4 class="local">
                  <img
                      class="img-fluid"
                      src="@/assets/img/location-map.svg"
                      alt=""
                  />
                  Location
                </h4>

                <div class="form-group col-md-12">
                  <select class="form-control tags1" v-model="selectedLocation">
                    <option selected="selected" value="">Select a Location</option>
                    <option v-for="gmbLocation in gmbLocations" :key="gmbLocation" v-bind:value="{ id: gmbLocation.name, name: gmbLocation.title }">
                      {{ gmbLocation.title }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-12">
                  <textarea
                      id="posting_vehicle_text"
                      v-model="vehicleText"
                      class="form-control"
                      placeholder="Type text"
                      rows="5"
                  ></textarea>
                  <div class="clearfix"></div>
                  <div class="text-right">
                      <a class="emojis_opener2" href="javascript:;" style='color:#2A4385'><i class="fa fa-smile-o" aria-hidden="true" style="font-size: 25px"></i></a>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="form-group col-md-12 emojis_container2" style="display:none;max-height: 160px;overflow-y: scroll">
                    <a class="single_emoji2" v-for="emoji in emojis" v-bind:key="emoji" v-bind:data-emoji="emoji.unicode" href="javascript:;" style="margin: 10px 5px;display:inline-block;text-decoration:none;font-size:25px;" v-bind:title="emoji.name" v-html="emoji.unicode"></a>
                </div>
                <div class="clearfix"></div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="postToGMB('now')">
              Post on GMB
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ads-Modal -->
    <!-- inventory-header -->
    <div class="inventory-header">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="vehicle-area">
            <div class="search-box">
              <input
                  type="search"
                  class="form-control"
                  placeholder="Search vehicles"
                  v-model="keyword"
                  @keyup="getInventoryForGMBPosting"
              />
              <img
                  class="img-fluid search-icon"
                  src="@/assets/img/search-solid.svg"
                  alt=""
              />
            </div>
            <div class="action-link">
              <ul>
                <li><a class="listingFilters all active" @click="listingFilters('all')" href="javascript:;">All</a></li>
                <li><a class="listingFilters price" @click="listingFilters('price')" href="javascript:;">No Price</a></li>
                <li><a class="listingFilters imagelist" @click="listingFilters('imagelist')" href="javascript:;">No Images</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- inventory-header -->

    <!-- Analytics-table -->
    <div class="table-area hide-select-search">
      <table id="postingTable" class="display">
        <thead>
        <tr>
          <th class="all" style="width:40px;"></th>
          <th style="width:65px">Image</th>
          <th>Vehicle</th>
          <th class="all" style="width:75px">Stock Number</th>
          <th style="width:65px">Age</th>
          <th style="width:65px">Price</th>
          <th style="width:65px">Actions</th>
        </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- Analytics-table -->
    <!-- carpost-area -->
    <div class="carpost-area" :class="vehiclesToPost.length > 0 && schedulePosts? 'show-car' : ''">
      <div class="post-area">
        <ul>
          <li><a href="#"><img class="img-fluid" src="@/assets/img/car-icon.svg" alt=""> Vehicles : {{ vehiclesToPost.length }}</a></li>
        </ul>
      </div>
      <div class="select-area">
        <div class="form-group">
          <select class="form-control tags1" v-model="selectedLocation">
            <option selected="selected" value="">Select a Location</option>
            <option v-for="gmbLocation in gmbLocations" :key="gmbLocation" v-bind:value="{ id: gmbLocation.name, name: gmbLocation.title }">
              {{ gmbLocation.title }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <input
            type="text"
            readonly
            placeholder="Please select date and time"
            id="postScheduleDate"
            class="form-control datesinglebackground"
            v-model="postScheduleDate"
          />
        </div>      
        <a class="cus-btn post" href="javascript:;" @click="postToGMB('schedule')">post</a>
      </div>
    </div>
    <!-- carpost-area -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted, ref} from "vue";
import "daterangepicker";
import moment from "moment";

import Dashboard from "./../../../layouts/Dashboard.vue";
import getGmbRefreshToken from "../../../composeables/Google/getGmbRefreshToken";
export default {
  components: {
    Dashboard
  },
  setup() {
    let keyword = ref("");
    let filterType = ref("all");
    let vehicleText = ref("");
    let vehiclesToPost = ref([]);
    let gmbLocations = ref({});
    let selectedLocation = ref("");
    let emojis = ref({});
    let schedulePosts = ref(false);
    let postScheduleDate = ref(moment().format('MM/DD/YYYY hh:mm A'));

    const datePickerInit = async () => {
      $("#postScheduleDate").daterangepicker({
        timePicker: true,
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        startDate: moment().format('MM/DD/YYYY hh:mm A'),
        endDate: moment().format('MM/DD/YYYY hh:mm A'),
        minDate: moment().format('MM/DD/YYYY hh:mm A'),
        minYear: parseInt(moment().year()),
        maxYear: parseInt(moment().add(10, "year").format('YYYY'),10),
        locale: {
          format: 'MM/DD/YYYY hh:mm A'
        }
      }, function(start) {
        postScheduleDate.value = moment(start).format('MM/DD/YYYY hh:mm A');
      });
    }

    const { token, loadToken } = getGmbRefreshToken();

    const listingFilters = (type) => {
      $(".listingFilters").removeClass("active");
      $(".listingFilters."+type).addClass("active");
      filterType.value = type;
      vehiclesToPost.value = [];
      schedulePosts.value = false;
      getInventoryForGMBPosting();
    }

    $(document).on('click', '.emojis_opener2', function() {
      $('.emojis_container2').slideToggle();
    })

    $(document).on("click", ".single_emoji2", function() {
      var emoji = $(this).data("emoji");
      var $txt = $("#posting_vehicle_text");
      var caretPos = $txt[0].selectionStart;
      var textAreaTxt = $txt.val();
      
      var textarea = document.createElement("textarea");
      textarea.innerHTML = textAreaTxt.substring(0, caretPos) + " " + emoji + " " + textAreaTxt.substring(caretPos);
      vehicleText.value = textarea.value;
      $txt[0].focus();
    })

    $(document).on('click', 'input.updatevehiclecheckbox', function() {
      schedulePosts.value = true;
      if($(this).is(':checked')) {
        vehiclesToPost.value.push($(this).data('id'));
      }else {
        var index = vehiclesToPost.value.indexOf($(this).data('id'));
        if (index !== -1) {
          vehiclesToPost.value.splice(index, 1);
        }
      }
    })

    $(document).on('click', '.post_this_vehicle', function() {
      vehiclesToPost.value = [];
      schedulePosts.value = false;
      vehiclesToPost.value.push($(this).data('id'));
      $('#googlePostModal').modal('show');
    })

    const closeModal = () => {
      vehiclesToPost.value = [];
      schedulePosts.value = false;
      $('#postingTable').find('input:checkbox').prop('checked', false);
      $("#googlePostModal").modal("hide");
    }

    const getInventoryForGMBPosting = async () => {
      if ($.fn.DataTable.isDataTable("#postingTable")) {
        $("#postingTable").DataTable().clear().destroy();
      }
      await $("#postingTable")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getInventoryForGMBPosting",
              type: "post",
              data: function (d) {
                d.search["value"] = keyword.value;
                d.vehiclesToPost = vehiclesToPost.value.join(",");
                d.filterType = filterType.value;
              },
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "checkbox" },
              { data: "image" },
              { data: "vehicle" },
              { data: "stock_no" },
              { data: "age" },
              { data: "price" },
              { data: "actions" }
            ],
            order: [],
            columnDefs: [
              {targets: [0, 1, 6], orderable: false }
            ]
          });
    }

    const postToGMB = async (status) => {
      if(vehiclesToPost.value.length < 1) {
        window.toast.fire({
          icon: "error",
          title: "Please select a vehicle",
        });
        return false;
      }
      if(selectedLocation.value == '') {
        window.toast.fire({
          icon: "error",
          title: "Please select a location",
        });
        return false;
      }
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "postToGMB",
              {
                vehicleText: vehicleText.value,
                selectedLocation: selectedLocation.value.id,
                selectedLocationName: selectedLocation.value.name,
                vehicles: vehiclesToPost.value,
                postScheduleDate: postScheduleDate.value,
                status: status
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            closeModal();
            window.showHideMainLoader(false);
            window.toast.fire({
              icon: response.data.status,
              title: response.data.data,
            });
          });
    }

    const getGMBBatchLocationData = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getGMBBatchLocationData",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            gmbLocations.value = response.data;
          });
    }

    const getEmojis = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getEmojis",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            console.log(response);
            emojis.value = response.data;
          });
    }

    onMounted( () => {
      getInventoryForGMBPosting();
      getEmojis();
      datePickerInit();
      loadToken().then(() => {
        if (token.value) {
          getGMBBatchLocationData();
        } else {
          let googleUrl = null;
          axios
              .post(
                  axios.defaults.baseUrl + "googleAuthentication",
                  {
                    google_auth: "",
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("auth_token"),
                    },
                  }
              )
              .then((response) => {
                googleUrl = response.data.data;
                window.location.href = googleUrl;
              });
        }
      });
    })

    return {
      getInventoryForGMBPosting,
      keyword,
      listingFilters,
      postToGMB,
      vehicleText,
      gmbLocations,
      selectedLocation,
      vehiclesToPost,
      emojis,
      closeModal,
      schedulePosts,
      postScheduleDate
    };
  }
};
</script>