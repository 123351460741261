<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- google-graph -->
    <div class="mygraph">
      <h4>Lead Reports</h4>
      <div class="row">
        <div class="col-md-6">
          <apexchart
            v-if="enableOrganicChart"
            id="organicChart"
            width="100%"
            :height="organicLeadsList.length > 0? '380' : '428'"
            type="donut"
            :options="organicChartOptions"
            :series="organicChartSeries"
          ></apexchart>
          <!-- Analytics-table -->
          <div class="website-leads-report-table" v-if="organicLeadsList">
            <h4>Organic</h4>
            <table class="table">
              <thead></thead>
              <tbody>
                <tr v-for="source in organicLeadsList" :key="source.lead_source">
                  <td>{{ source.lead_source }}</td>
                  <td>{{ source.leads }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{{ organicLeadsTotal }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>

        <div class="col-md-6">
          <apexchart
            v-if="enableSocialChart"
            id="socialChart"
            width="100%"
            :height="socialLeadsList.length > 0? '380' : '428'"
            type="donut"
            :options="socialChartOptions"
            :series="socialChartSeries"
          ></apexchart>
          <!-- Analytics-table -->
          <div class="website-leads-report-table" v-if="socialLeadsList">
            <h4>Social</h4>
            <table class="table">
              <thead></thead>
              <tbody>
                <tr v-for="source in socialLeadsList" :key="source.lead_source">
                  <td>{{ source.lead_source }}</td>
                  <td>{{ source.leads }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{{ socialLeadsTotal }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
        
        <div class="clearfix"></div>

        <div class="col-md-6">
          <apexchart
            v-if="enableThirdPartyChart"
            id="thirdPartyChart"
            width="100%"
            :height="thirdPartyLeadsList.length > 0? '380' : '428'"
            type="donut"
            :options="thirdPartyChartOptions"
            :series="thirdPartyChartSeries"
          ></apexchart>
          <!-- Analytics-table -->
          <div class="website-leads-report-table" v-if="thirdPartyLeadsList">
            <h4>Third Party</h4>
            <table class="table">
              <thead></thead>
              <tbody>
                <tr v-for="source in thirdPartyLeadsList" :key="source.lead_source">
                  <td>{{ source.lead_source }}</td>
                  <td>{{ source.leads }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{{ thirdPartyLeadsTotal }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>

        <div class="col-md-6">
          <apexchart
            v-if="enablePaidSearchChart"
            id="paidSearchChart"
            width="100%"
            :height="paidSearchLeadsList.length > 0? '380' : '428'"
            type="donut"
            :options="paidSearchChartOptions"
            :series="paidSearchChartSeries"
          ></apexchart>
          <!-- Analytics-table -->
          <div class="website-leads-report-table" v-if="paidSearchLeadsList">
            <h4>Paid Search</h4>
            <table class="table">
              <thead></thead>
              <tbody>
                <tr v-for="source in paidSearchLeadsList" :key="source.lead_source">
                  <td>{{ source.lead_source }}</td>
                  <td>{{ source.leads }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{{ paidSearchLeadsTotal }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- google-graph -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let organicLeadsList = ref([]);
    let socialLeadsList = ref([]);
    let thirdPartyLeadsList = ref([]);
    let paidSearchLeadsList = ref([]);

    let organicLeadsTotal = ref(0);
    let socialLeadsTotal = ref(0);
    let thirdPartyLeadsTotal = ref(0);
    let paidSearchLeadsTotal = ref(0);

    let organicChartSeries = ref([]);
    let socialChartSeries = ref([]);
    let thirdPartyChartSeries = ref([]);
    let paidSearchChartSeries = ref([]);

    let organicChartOptions = ref({});
    let socialChartOptions = ref({});
    let thirdPartyChartOptions = ref({});
    let paidSearchChartOptions = ref({});

    let enableOrganicChart = ref("false");
    let enableSocialChart = ref("false");
    let enableThirdPartyChart = ref("false");
    let enablePaidSearchChart = ref("false");

    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getWebsiteFromLeadsReport();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const getWebsiteFromLeadsReport = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getWebsiteFromLeadsReport",
          {
            fromDate: startDate.value,
            toDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          organicLeadsList.value = response.data.organic;
          socialLeadsList.value = response.data.social;
          thirdPartyLeadsList.value = [];
          paidSearchLeadsList.value = response.data.paid;

          organicLeadsTotal.value = 0;
          socialLeadsTotal.value = 0;
          thirdPartyLeadsTotal.value = 0;
          paidSearchLeadsTotal.value = 0;

          enableOrganicChart.value = true;
          enableSocialChart.value = true;
          enableThirdPartyChart.value = true;
          enablePaidSearchChart.value = true;

          let organicLeads = [];
          let organicLabels = [];

          let socialLeads = [];
          let socialLabels = [];

          let thirdPartyLeads = [];
          let thirdPartyLabels = [];

          let paidSearchLeads = [];
          let paidSearchLabels = [];

          if (response.data.organic.length > 0) {
            $.each(response.data.organic, function (index, val) {
              organicLeads.push(val.leads);
              organicLabels.push(val.lead_source);

              organicLeadsTotal.value = organicLeadsTotal.value + val.leads;
            });
          }

          if (response.data.social.length > 0) {
            $.each(response.data.social, function (index, val) {
              socialLeads.push(val.leads);
              socialLabels.push(val.lead_source);

              socialLeadsTotal.value =socialLeadsTotal.value + val.leads;
            });
          }

          if (response.data.thirdParty.length > 0) {
            let i = 1;
            $.each(response.data.thirdParty, function (index, val) {
              console.log(index);
              if(i > 5) {
                return false;
              }
              if(val.lead_source != 'Other') {
                thirdPartyLeads.push(val.leads);
                thirdPartyLabels.push(val.lead_source);

                thirdPartyLeadsList.value.push({leads: val.leads, lead_source: val.lead_source});

                thirdPartyLeadsTotal.value =thirdPartyLeadsTotal.value + val.leads;
                i++;
              }
            });
          }

          if (response.data.paid.length > 0) {
            $.each(response.data.paid, function (index, val) {
              paidSearchLeads.push(val.leads);
              paidSearchLabels.push(val.lead_source);

              paidSearchLeadsTotal.value =paidSearchLeadsTotal.value + val.leads;
            });
          }

          organicChartSeries.value = organicLeads;
          socialChartSeries.value = socialLeads;
          thirdPartyChartSeries.value = thirdPartyLeads;
          paidSearchChartSeries.value = paidSearchLeads;

          organicChartOptions.value = {
            chart: {
              type: "donut",
            },
            labels: organicLabels,
            // colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };

          socialChartOptions.value = {
            chart: {
              type: "donut",
            },
            labels: socialLabels,
            // colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };

          thirdPartyChartOptions.value = {
            chart: {
              type: "donut",
            },
            labels: thirdPartyLabels,
            // colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };

          paidSearchChartOptions.value = {
            chart: {
              type: "donut",
            },
            labels: paidSearchLabels,
            // colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      dateRangePickerInit();
    });

    return {
      organicChartSeries,
      socialChartSeries,
      thirdPartyChartSeries,
      paidSearchChartSeries,
      enableOrganicChart,
      enableSocialChart,
      enableThirdPartyChart,
      enablePaidSearchChart,
      organicChartOptions,
      socialChartOptions,
      thirdPartyChartOptions,
      paidSearchChartOptions,
      organicLeadsList,
      socialLeadsList,
      thirdPartyLeadsList,
      paidSearchLeadsList,
      organicLeadsTotal,
      socialLeadsTotal,
      thirdPartyLeadsTotal,
      paidSearchLeadsTotal,
      resetFilters,
    };
  },
};
</script>