<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-6">
          <div class="mygraph">
            <h4>Search Engines</h4>
            <apexchart
              v-if="enableChart"
              id="chart13"
              width="100%"
              height="300"
              type="donut"
              :options="donutChartOptions"
              :series="donutSeries"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="socialSearchTable">
              <thead>
                <tr>
                  <th>Search Engine</th>
                  <th>Sessions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in socialSearch" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- google-graph -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());
    let enableChart = ref("false");

    let donutSeries = ref([]);
    let donutChartOptions = ref({});

    let socialSessions = ref([]);
    let socialSessionsError = ref("");
    let socialSearch = ref([]);
    let socialSearchError = ref("");

    const getSocialSessions = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getSocialSessions",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          socialSessions.value = response.data;
          enableChart.value = true;
          donutSeries.value = socialSessions.value.sessions;
          donutChartOptions.value = {
            chart: {
              type: "donut",
            },
            colors: ["#f0932b", "#46D9AF", "#50AEF4", "#FCE15F", "#FD6D54"],
            labels: socialSessions.value.labels,
            legend: {
              position: "top",
              offsetY: 0,
              height: 60,
            },
            dataLabels: {
              enabled: false,
            },
          };
        })
        .catch((socialSessionsError) => {
          socialSessionsError.value = "No Sessions for Google Analytics found";
        });
    };

    const getSocialSearch = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getSocialSearch",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          socialSearch.value = response.data.data.rows;

          if ($.fn.DataTable.isDataTable("#socialSearchTable")) {
            $("#socialSearchTable").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#socialSearchTable").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((socialSearchError) => {
          socialSearchError.value = "No Sessions for Google Analytics found";
        });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getSocialSessions();
      getSocialSearch();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      $("#socialSearchTable").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      dateRangePickerInit();
    });

    return {
      donutSeries,
      donutChartOptions,
      socialSessions,
      socialSearch,
      socialSessionsError,
      socialSearchError,
      resetFilters,
      enableChart,
    };
  },
};
</script>