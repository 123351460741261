<template>
  <Dashboard>
    <!-- Analytics-table -->
    <div class="table-area hide-select-search">
      <table class="display" id="googleadstable">
        <thead>
        <tr>
          <th class="all">Ad ID</th>
          <th class="all">Title</th>
          <th style="width:80px">Status</th>
          <th style="width:100px">Destination</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="Ad in allAds" :key="Ad">
          <td>{{ Ad.id }}</td>
          <td>{{ Ad.headline1 }}</td>
          <td>{{ Ad.status }}</td>
          <td><a :href="Ad.final_urls" class="cus-btn" target="_blank">VDP</a></td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { useRoute } from "vue-router";
import {onMounted, ref} from "vue";
import Dashboard from "./../../../../layouts/Dashboard.vue";
import getDealerOtherInfo from "../../../../composeables/getDealerOtherInfo";
import axios from "axios";
export default {
  components: {
    Dashboard,
  },
  setup() {
    const route = useRoute();
    let allAds = ref([]);

    // Get Dealer Other Info:
    const { dealer, dealerInfo, dealerInfoError, loadDealerInfo } = getDealerOtherInfo();
    loadDealerInfo().then(() => {
      if(dealerInfo.value.google_ads_account_id != '' && dealerInfo.value.google_ads_account_id != 'undefined') {
        getGoogleAdsAds();
      }
    });
    const getGoogleAdsAds = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getGoogleAds/"+dealerInfo.value.google_ads_account_id+"/"+route.params.id+"/automated"+"/automated/",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if(response.status) {
              if ($.fn.DataTable.isDataTable("#googleadstable")) {
                $("#googleadstable").DataTable().clear().destroy();
              }

              allAds.value = response.data.data;

              setTimeout(() => {
                datatableInit();
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
    
    const datatableInit = async () => {
      $("#googleadstable").addClass("nowrap").dataTable({
        responsive: true,
        order: []
      });
    }

    onMounted( () => {
      datatableInit();
    })

    return { dealer, dealerInfo, dealerInfoError, allAds };
  }
};
</script>