import { ref } from 'vue';
import axios from 'axios';
const getVehicleInteriorColor = () => {
    var vehicleInteriorColor = ref([]);
    const InteriorColorError = ref('');
    try {
        const loadVehicleInteriorColor = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleInteriorColor",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleInteriorColor.value = response.data;
                })
                .catch((InteriorColorError) => {
                    InteriorColorError.value = 'No Vehicle Interior Color found';
                });
        }
        return { vehicleInteriorColor, InteriorColorError, loadVehicleInteriorColor };
    } catch (InteriorColorError) {
        InteriorColorError.value = 'No Vehicle Interior Color found';
    }
}
export default getVehicleInteriorColor;