import { ref } from 'vue';
import axios from 'axios';
const updateSingleVehicleSpecialPrice = (id, price) => {
    let status = ref("");
    try {
        const loadUpdateSingVehicleSpeicalPriceRequest = async () => {
            await axios
                .post(axios.defaults.baseUrl + "updateSingleVehicleSpecialPrice",
                    {
                        id: id,
                        price: price,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    if(response) {
                        status.value = "success";
                    }
                })
                .catch((error) => {
                    if(error) {
                        // status.value = "error";
                        console.log(error);
                    }
                });
        }
        return { status, loadUpdateSingVehicleSpeicalPriceRequest };
    } catch (error) {
        if(error) {
            // status.value = "error";
            console.log(error);
        }
    }
}
export default updateSingleVehicleSpecialPrice;