import { ref } from 'vue';
import axios from 'axios';
const getVehicle = (id) => {
    let vehicle = ref([]);
    const error = ref('');
    try {
        const loadVehicle = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicle/" + id,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicle.value = response.data;
                    if (vehicle.value.date_in_stock) {
                        vehicle.value.date_in_stock = new Date(
                            vehicle.value.date_in_stock
                        );
                    }
                })
                .catch((error) => {
                    error.value = 'No vehicle Data found';
                });
        }
        return { vehicle, error, loadVehicle, };
    } catch (error) {
        error.value = 'No vehicle Data found';
    }
}
export default getVehicle;