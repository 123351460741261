<template>
  <Dashboard>
    <VehiclesCountCards />
    <VehiclesListing inventoryTypeText="used" />
  </Dashboard>
</template>
<script>
import Dashboard from "../../layouts/Dashboard.vue";
import VehiclesCountCards from "../../components/Inventory/VehiclesCountCards.vue";
import VehiclesListing from "../../components/Inventory/VehiclesListing.vue";
export default {
  components: {
    Dashboard,
    VehiclesCountCards,
    VehiclesListing,
  }
};
</script>