<template>
  <Dashboard>
    <!-- dealership-form -->
    <div class="dealership-form">
      <form class="myform" @submit.prevent="onSubmit">
        <div class="form-row">
          <!-- <div class="form-group col-md-4">
            <label>Website URL</label>
            <input
              type="text"
              class="form-control"
              placeholder="https://www.website.com"
              v-model="dealerInfo.dealer_url"
            />
          </div> -->
          <div class="form-group col-md-4">
            <label>Vehicles To Show In Specials</label>
            <select
              class="form-control tags2"
              v-model="dealerInfo.specials_age"
            >
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Google Analytics View ID</label>
            <input
              type="text"
              class="form-control"
              placeholder="xxxxxxxxx"
              v-model="dealerInfo.analytics_view_id"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Google Ad Account ID</label>
            <input
              type="text"
              class="form-control"
              placeholder="xxxxxxxxxx"
              v-model="dealerInfo.google_ads_account_id"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Google My Business Store ID</label>
            <input
              type="text"
              class="form-control"
              placeholder="xxxxxxxxxx"
              v-model="dealerInfo.gmb_store_id"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Facebook Ad Account ID</label>
            <input
              type="text"
              class="form-control"
              placeholder="xxxxxxxxxxxxxx"
              v-model="dealerInfo.facebook_ad_account_id"
            />
          </div>
          <div class="form-group col-md-4">
            <label>TikTok Account ID</label>
            <input
              type="text"
              class="form-control"
              placeholder="xxxxxxxxxxxxxx"
              v-model="dealer.tiktok_advertiser_id"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Facebook Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="Facebook Link"
              v-model="dealerInfo.dealer_facebook_link"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Instagram Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="Instagram Link"
              v-model="dealerInfo.dealer_instagram_link"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Google Plus Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="Google Plus Link"
              v-model="dealerInfo.dealer_gplus_link"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Twitter Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="Twitter Link"
              v-model="dealerInfo.social_twitter"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Youtube Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="Youtube Link"
              v-model="dealerInfo.social_youtube"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Yelp Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="Yelp Link"
              v-model="dealerInfo.social_yelp"
            />
          </div>
          <div class="form-group col-md-4">&nbsp;</div>
          <div class="form-group col-md-4">&nbsp;</div>
          <div class="form-group col-md-6 col-lg-3">
            <label
              >Header Logo
              <input
                type="file"
                class="d-none"
                id="dealer_logo_header"
                data-name="dealer_logo_header"
                @change="onFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('dealer_logo_header')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="update-logo">
              <img
                v-if="dealerInfo.dealer_logo_header"
                :src="dealerInfo.dealer_logo_header"
              />
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label
              >Footer Logo
              <input
                type="file"
                class="d-none"
                id="dealer_logo_footer"
                data-name="dealer_logo_footer"
                @change="onFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('dealer_logo_footer')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="update-logo">
              <img
                v-if="dealerInfo.dealer_logo_footer"
                :src="dealerInfo.dealer_logo_footer"
              />
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label
              >Mobile Menu Background
              <input
                type="file"
                class="d-none"
                id="mobile_background"
                data-name="mobile_background"
                @change="onFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('mobile_background')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="update-logo">
              <img
                v-if="dealerInfo.mobile_background"
                :src="dealerInfo.mobile_background"
              />
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label
              >Dream Car Section Background
              <input
                type="file"
                class="d-none"
                id="dreamcar_background"
                data-name="dreamcar_background"
                @change="onFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('dreamcar_background')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="update-logo">
              <img
                v-if="dealerInfo.dreamcar_background"
                :src="dealerInfo.dreamcar_background"
              />
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-4">
            <label
              >Credit Application Background
              <input
                type="file"
                class="d-none"
                id="credit_application_background"
                data-name="credit_application_background"
                @change="onFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('credit_application_background')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="biger-img-placeholder">
              <img
                v-if="dealerInfo.credit_application_background"
                :src="dealerInfo.credit_application_background"
              />
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-4">
            <label
              >Co Application Background
              <input
                type="file"
                class="d-none"
                id="co_application_background"
                data-name="co_application_background"
                @change="onFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('co_application_background')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="biger-img-placeholder">
              <img
                v-if="dealerInfo.co_application_background"
                :src="dealerInfo.co_application_background"
              />
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-4">
            <label
              >Video Banner
              <input
                type="file"
                class="d-none"
                id="dealer_vide_banner"
                data-name="dealer_vide_banner"
                @change="onVideoFileChange"
              />
              <a
                href="javascript:;"
                class="edit-logo"
                @click="triggerFileSelect('dealer_vide_banner')"
                ><img class="img-fluid" src="@/assets/img/pen.svg" alt="" />
                Change</a
              >
            </label>
            <div class="biger-img-placeholder">
              <video v-if="dealerInfo.dealer_vide_banner" controls height="237">
                <source :src="dealerInfo.dealer_vide_banner" />
              </video>
              <img
                v-else
                class="img-thumbnail"
                src="@/assets/img/placeholder-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="form-group col-md-4">
            <label>Footer Text</label>
            <input
              type="text"
              class="form-control"
              placeholder="Footer Text"
              v-model="dealerInfo.dealer_footer_text"
            />
          </div>
          <div class="form-group col-md-4">
            <label>General Meta Title</label>
            <input
              type="text"
              class="form-control"
              maxlength="70"
              placeholder="General Meta Title"
              v-model="dealerInfo.meta_title"
            />
            <small
              >Maximum 70 characters. Please do not use any special
              characters</small
            >
          </div>
          <div class="form-group col-md-4">
            <label>General Meta Description</label>
            <textarea
              class="form-control"
              rows="1"
              required
              maxlength="300"
              placeholder="General Meta Description"
              v-model="dealerInfo.meta_description"
            ></textarea>

            <small
              >Maximum 300 characters. Please do not use any special
              characters</small
            >
          </div>
          <div class="form-group col-md-6">
            <label>Global Header Scripts</label>
            <Codemirror
              v-model:value="dealerInfo.header_script"
              :options="codeMirrorOptions"
              border
              placeholder=""
              :height="200"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Global Body Start Scripts</label>
            <Codemirror
              v-model:value="dealerInfo.body_start_script"
              :options="codeMirrorOptions"
              border
              placeholder=""
              :height="200"
            />
          </div>
          <div class="form-group col-md-12">
            <label>Global Body End Scripts</label>
            <Codemirror
              v-model:value="dealerInfo.footer_script"
              :options="codeMirrorOptions"
              border
              placeholder=""
              :height="200"
            />
          </div>
          <div class="form-group col-md-12">
            <label>Why Buy From Us Section</label>
            <TinyMceEditor
              v-model="dealerInfo.why_buy_from_us"
              api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre"
              :init="{
                menubar: false,
                branding: false,
                extended_valid_elements: 'span[class|align|style]',
                forced_root_block_attrs: { class: 'root_block_p' },
                element_format: 'html',
                relative_urls: false,
                remove_script_host: false,
                height: 350,
                browser_spellcheck: true,
                verify_html: false,
                images_upload_handler: tinyMceUploader,
                plugins:
                  'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                toolbar:
                  'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code',
              }"
            />
          </div>
          <div class="col-md-12">
            <div class="cus-btn text-right">
              <router-link :to="{ name: 'Dashboard' }" class="cancle-btn">
                Back</router-link
              >
              <button type="submit" class="send-btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- dealership-form -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { ref } from "vue";
import axios from "axios";
import Dashboard from "./../../layouts/Dashboard.vue";
import getDealerOtherInfo from "../../composeables/getDealerOtherInfo";
import Codemirror from "codemirror-editor-vue3";
import TinyMceEditor from "@tinymce/tinymce-vue";
export default {
  components: {
    Dashboard,
    Codemirror,
    TinyMceEditor,
  },
  setup() {
    let codeMirrorOptions = ref({
      lineNumbers: true, // Show line number
      smartIndent: true, // Smart indent
      indentUnit: 2, // The smart indent unit is 2 spaces in length
      foldGutter: true, // Code folding
      styleActiveLine: true, // Display the style of the selected row
    });
    // Click Function for upload file field
    const triggerFileSelect = (id) => {
      $("#" + id).trigger("click");
    };
    // Get Dealer Other Info:
    const { dealer, dealerInfo, dealerInfoError, loadDealerInfo } =
      getDealerOtherInfo();
    loadDealerInfo();

    // File Change Method for Image upload field
    const onFileChange = async (element) => {
      let fieldName = $(element.target).data("name");
      let file = element.target.files[0];
      if (file["size"] > 1048576) {
        window.toast.fire({
          icon: "error",
          title: "Image size can not be greater than 1MB",
        });
        return false;
      }
      if (
        file["type"] != "image/png" &&
        file["type"] != "image/jpg" &&
        file["type"] != "image/jpeg"
      ) {
        window.toast.fire({
          icon: "error",
          title: "File format not supported",
        });
        return false;
      }
      let formData = new FormData();
      formData.append("fieldName", fieldName);
      formData.append("file", file);
      formData.append("fileType", "image");
      uploadFile(fieldName, formData);
    };

    // Video File Change Method for Image upload field
    const onVideoFileChange = async (element) => {
      let fieldName = $(element.target).data("name");
      let file = element.target.files[0];
      if (file["size"] > 20971520) {
        window.toast.fire({
          icon: "error",
          title: "Video size can not be greater than 20MB",
        });
        return false;
      }
      if (file["type"] != "video/mp4") {
        window.toast.fire({
          icon: "error",
          title: "File format not supported. Only mp4 files are allowed",
        });
        return false;
      }
      let formData = new FormData();
      formData.append("fieldName", fieldName);
      formData.append("file", file);
      formData.append("fileType", "video");
      uploadFile(fieldName, formData);
    };

    const uploadFile = async (fieldName, formData) => {
      window.showHideMainLoader(true);
      await axios
        .post(axios.defaults.baseUrl + "uploadGeneralSettingFiles", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "File uploaded successfully",
            });
            dealerInfo.value[fieldName] = response.data.data;
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.data,
            });
          }
        })
        .catch((error) => {
          window.showHideMainLoader(true);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    // On Submit Form:
    const onSubmit = async () => {
      $(".loaders").show();
      let data = new FormData();
      data.append("id", dealerInfo.value.id);
      // data.append("dealer_url", dealerInfo.value.dealer_url);
      data.append("specials_age", dealerInfo.value.specials_age);
      data.append("analytics_view_id", dealerInfo.value.analytics_view_id);
      data.append(
        "google_ads_account_id",
        dealerInfo.value.google_ads_account_id
      );
      data.append(
        "gmb_store_id",
        dealerInfo.value.gmb_store_id
      );
      data.append(
        "facebook_ad_account_id",
        dealerInfo.value.facebook_ad_account_id
      );
      data.append(
        "tiktok_advertiser_id",
        dealer.value.tiktok_advertiser_id
      );
      data.append(
        "dealer_facebook_link",
        dealerInfo.value.dealer_facebook_link
      );
      data.append(
        "dealer_instagram_link",
        dealerInfo.value.dealer_instagram_link
      );
      data.append("dealer_gplus_link", dealerInfo.value.dealer_gplus_link);
      data.append("social_twitter", dealerInfo.value.social_twitter);
      data.append("social_youtube", dealerInfo.value.social_youtube);
      data.append("social_yelp", dealerInfo.value.social_yelp);
      data.append("dealer_logo_header", dealerInfo.value.dealer_logo_header);
      data.append("dealer_logo_footer", dealerInfo.value.dealer_logo_footer);
      data.append("mobile_background", dealerInfo.value.mobile_background);
      data.append("dreamcar_background", dealerInfo.value.dreamcar_background);
      data.append(
        "credit_application_background",
        dealerInfo.value.credit_application_background
      );
      data.append(
        "co_application_background",
        dealerInfo.value.co_application_background
      );
      data.append("dealer_vide_banner", dealerInfo.value.dealer_vide_banner);
      data.append("dealer_footer_text", dealerInfo.value.dealer_footer_text);
      data.append("meta_title", dealerInfo.value.meta_title);
      data.append("meta_description", dealerInfo.value.meta_description);
      data.append("header_script", dealerInfo.value.header_script);
      data.append("body_start_script", dealerInfo.value.body_start_script);
      data.append("footer_script", dealerInfo.value.footer_script);
      data.append("why_buy_from_us", dealerInfo.value.why_buy_from_us);
      await axios
        .post(axios.defaults.baseUrl + "updateGeneralSettings", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          $(".loaders").hide();
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: "General Settings updated successfully...",
            });
          } else {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        })
        .catch((error) => {
          $(".loaders").hide();
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    const tinyMceUploader = async (blobInfo, success, failure) => {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", axios.defaults.baseUrl + "editorImageUpload");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + localStorage.getItem("auth_token")
      );
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failure("HTTP Error: " + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json) {
          failure("Invalid JSON: " + xhr.responseText);
          return;
        }
        if (json.status == "error") {
          failure(json.location);
          return;
        }
        success(json.location);
      };
      formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    };

    return {
      dealer,
      dealerInfo,
      dealerInfoError,
      onFileChange,
      onVideoFileChange,
      onSubmit,
      triggerFileSelect,
      codeMirrorOptions,
      tinyMceUploader,
    };
  },
};
</script>