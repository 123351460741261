<template>
  <Dashboard>
    <!-- dealer-form -->
    <div class="dealership-form">
        <form class="myform" @submit.prevent="dealerFormHandler">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>First Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="First Name"
              v-model="dealerData.first_name"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Last Name"
              v-model="dealerData.last_name"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Email Address</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email Address"
              v-model="dealerData.email"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Street Address</label>
            <input
              type="text"
              class="form-control"
              placeholder="Street Address"
              v-model="dealerData.address"
              required
            />
          </div>
            <div class="form-group col-md-4">
            <label>City</label>
            <input
              type="text"
              class="form-control"
              placeholder="City"
              v-model="dealerData.city"
              required
            />
          </div>
            <div class="form-group col-md-4">
              <label>State</label>
              <select
                class="form-control tags2"
                v-model="dealerData.state"
                required
              >
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NB">NB</option>
                <option value="NE">NE</option>
                <option value="NS">NS</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="ON">ON</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="PE">PE</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
          </div>
          <div class="form-group col-md-4">
            <label>Zip Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Zip Code"
              v-model="dealerData.postal_code"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Latitude</label>
            <input
              type="text"
              class="form-control"
              placeholder="Latitude"
              v-model="dealerData.latitude"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Longitude</label>
            <input
              type="text"
              class="form-control"
              placeholder="Longitude"
              v-model="dealerData.longitude"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Phone Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="dealerData.dealer_phone_number"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Website URL</label>
            <input
              type="text"
              class="form-control"
              placeholder="Website URL"
              v-model="dealerData.website"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Preapproved URL</label>
            <input
              type="text"
              class="form-control"
              placeholder="Preapproved URL"
              v-model="dealerData.preapproved_url"
              required
            />
          </div>
          <div class="form-group col-md-4" v-if="!editMode">
            <label>Dealer Type</label>
            <select
              class="form-control tags2"
              v-model="dealerData.dealer_vehicle_type"
              required
            >
            <option value="1">Used Cars Dealer</option>
            <option value="2">New Cars Dealer</option>
            </select>
          </div>
          <div class="form-group col-md-4" v-if="!editMode">
            <label>Dealer Make Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Dealer Make Name"
              v-model="dealerData.new_make_name_if_new"
            />
          </div>
          <div class="form-group col-md-4" v-if="!editMode">
            <label>Website Template</label>
            <select
              class="form-control tags2"
              v-model="dealerData.template_number"
              required
            >
            <option value="one">One</option>
            <option value="four">Four</option>
            <option value="five">Five</option>
            <option value="six">Six</option>
            <option value="seven">Seven</option>
            <option value="eight">Eight</option>
            <option value="nine">Nine</option>
            </select>
          </div>
          <div class="form-group col-md-4" v-if="!editMode">
            <label>Website Theme</label>
            <select
              class="form-control tags2"
              v-model="dealerData.theme_id"
              required
            >
            <option value="red">red</option>
            <option value="light-red">light-red</option>
            <option value="orange">orange</option>
            <option value="blue">blue</option>
            <option value="black">black</option>
            <option value="green">green</option>
            <option value="yellow">yellow</option>
            <option value="grey">grey</option>
            </select>
          </div>
          <div class="form-group col-md-4" v-if="!editMode">
            <label>Agency</label>
            <select
              class="form-control tags2"
              v-model="dealerData.reseller"
              required
            >
            <option value="self">DealersGear</option>
            <option value="dom360">Dom360</option>
            <option value="terishkafranco">Terishka Franco</option>
            <option value="eautoblaster">eAuto Blaster</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Craigslist Title Text</label>
            <input
              type="text"
              class="form-control"
              placeholder="Craigslist Title Text"
              v-model="dealerData.craigslist_title_text"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Craigslist Specific Location Text</label>
            <input
              type="text"
              class="form-control"
              placeholder="Craigslist Specific Location Text"
              v-model="dealerData.craigslist_title_text_2"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Craigslist Service Offered</label>
            <select
              class="form-control tags2"
              v-model="dealerData.craiglist_offered"
              required
            >
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Craigslist Cost Per Ad</label>
            <input
              type="number"
              class="form-control"
              placeholder="Craigslist Cost Per Ad"
              v-model="dealerData.cost_per_ad"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Craigslist Monthly Service Fee</label>
            <input
              type="number"
              class="form-control"
              placeholder="Craigslist Monthly Service Fee"
              v-model="dealerData.monthly_service"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label>Craigslist Posting Category</label>
            <select
              class="form-control tags2"
              v-model="dealerData.craigslist_posting_category"
              required
            >
            <option value="ctd">Cars & Trucks</option>
            <option value="hvd">Heavy Equipment</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Facebook Business Manager</label>
            <select
              class="form-control tags2"
              v-model="dealerData.facebook_business_account"
              required
            >
            <option value="1">DealersGear</option>
            <option value="2">DealersGear INC</option>
            </select>
          </div>
          <div class='clearfix'></div>
          <div class="col-md-12">
            <div class="cus-btn text-right">
              <router-link class="cancle-btn text-decoration-none" :to="{ name: 'ManageDealers' }"
                >Back</router-link
              >
              <button type="submit" class="send-btn" v-if="!editMode">Add Dealer</button>
              <button type="submit" class="send-btn" v-else>Update Dealer</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- dealer-form -->
  </Dashboard>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import { useRoute } from "vue-router";
import routes from "./../../routes";
import Dashboard from "./../../layouts/Dashboard.vue";
import getDealer from "./../../composeables/getDealer";
// import $ from "jquery";
export default {
  components: {
    Dashboard,
  },
  setup() {
    const route = useRoute();
    let dealerData = ref ({});
    let editMode = ref (false);
    if (typeof route.params.id !== 'undefined') {
      editMode.value = true;
    }
    // Get Dealer Data
    if (editMode.value) {
      let { dealer, err, loadDealer } = getDealer(
          route.params.id
      );
      console.log(dealer+''+err);
      loadDealer().then(() => {
        dealerData.value = dealer.value;
      });
    }
    // Add Dealer
    const dealerFormHandler = async () => {
      window.showHideMainLoader(true);
      let formSubmissionUrl = axios.defaults.baseUrl + "addDealer";
      if(editMode.value) {
        formSubmissionUrl = axios.defaults.baseUrl + "editDealer";
      }
      await axios
        .post(
          formSubmissionUrl,
          {
            dealerData: dealerData.value
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          if(response.data.success) {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            routes.push({ name: "ManageDealers" });
          }else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again...",
            });
          }
        });
    };
    return { dealerData, dealerFormHandler, editMode };
  },
};
</script>
<style scoped>
.cancle-btn {
  text-decoration: none;
}
</style>