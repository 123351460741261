<template>
  <Dashboard>

    <div class="table-area pricing-table positionedbutton">
      <div class="action-drop">
        <a class="action-btn" href="javascript:;" @click="loginWithGoogle">Connect GMB</a>
      </div>
      <table id="locations_table" class="display">
        <thead>
        <tr>
          <th class="all">Name</th>
          <th>Phone</th>
          <th>Location</th>
          <th class="all">Action</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="GMBLocation in allGMBLocations" :key="GMBLocation">
            <td>{{ GMBLocation.title }}</td>
            <td>{{ GMBLocation.phoneNumbers.primaryPhone }}</td>
            <td><a class="map" v-bind:href="GMBLocation.metadata.mapsUri" target="_blank"> <img class="img-fluid" src="../../../assets/img/location-map.svg"> View On map</a></td>
            <td>
              <router-link class="cus-btn" :to="{ name: 'GMBInsights', params: { id: GMBLocation.name } }">Insights</router-link >
              <router-link class="cus-btn" :to="{ name: 'GMBPostAds' }">Post</router-link>
              <router-link class="cus-btn" :to="{ name: 'GMBReviews', params: { id: GMBLocation.name } }">Reviews</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Select GMB Locations -->
    <div
        class="modal select-modal fade"
        id="selectgmblocation"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Select GMB Location
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>GMB Locations</label>
                  <select
                      id="gmb_locations"
                      class="form-control select2 gmb_locations"
                      v-model="gmb_locations"
                      multiple
                  >
                    <option
                        v-for="location in locations"
                        :key="location.locationId"
                        :value="location.locationId"
                    >
                      {{ location.locationName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Back
                </button>
                <button
                    type="button"
                    @click="handleLocationSelectionRequest"
                    class="send-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Select GMB Locations -->
  </Dashboard>
</template>
<script>
import axios from "axios";
import { useRoute } from "vue-router";
import Dashboard from "./../../../layouts/Dashboard.vue";
import getGmbRefreshToken from "./../../../composeables/Google/getGmbRefreshToken";
import $ from "jquery";
import {ref, onMounted} from "vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    window.showHideMainLoader(true);
    let gmb_locations = ref([]);
    let locations = ref([]);
    let allGMBLocations = ref([]);
    const closePopup = () => {
      $("#selectgmblocation").modal("hide");
    };
    const route = useRoute();

    const getAllGMBListing = async () => {
      await axios
          .post(
              axios.defaults.baseUrl + "getAllGMBLocations",
              {
                dealer_id: localStorage.getItem("dealer_id")
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if ($.fn.DataTable.isDataTable("#locations_table")) {
              $("#locations_table").DataTable().clear().destroy();
            }
            allGMBLocations.value = response.data.data;
            setTimeout(() => {
              datatableInit();
            });
          })
          .catch((error) => {
            console.log(error);
          });
    }


    const { token, loadToken } = getGmbRefreshToken();
    loadToken().then(() => {
        if (route.query.code) {
           window.showHideMainLoader(true);
          let access_token = route.query.code;
          axios
            .post(
              axios.defaults.baseUrl + "googleRefreshTokenSave",
              {
                access_token: access_token,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
            )
            .then((response) => {
               window.showHideMainLoader(false);
              if(response.data.success) {
                locations.value = response.data.data;
                setTimeout(() => {
                  $("#gmb_locations").select2({
                    closeOnSelect : false
                  });
                });
                $("#selectgmblocation").modal({backdrop: 'static', keyboard: false});
              }else {
                window.toast.fire({
                  icon: "error",
                  title: response.data.data,
                });
              }
            });
        }else if (token.value) {
          getAllGMBListing();
        }
    });

    const loginWithGoogle = async () => {
      window.showHideMainLoader(true);
      let googleUrl = null;
      axios
        .post(
          axios.defaults.baseUrl + "googleAuthentication",
          {
            google_auth: "",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.showHideMainLoader(false);
          googleUrl = response.data.data;
          window.location.href = googleUrl;
        });
    }

    // Save Selected Location Request
    const handleLocationSelectionRequest = async () => {
      if(gmb_locations.value.length > 0) {
        window.showHideMainLoader(true);
        await axios
            .post(
                axios.defaults.baseUrl + "googleSaveSelectedLocations",
                {
                  dealer_id: localStorage.getItem("dealer_id"),
                  gmb_locations: gmb_locations.value
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                  },
                }
            )
            .then((response) => {
              console.log(response);
              getAllGMBListing();
              closePopup();
            })
            .catch((error) => {
              console.log(error);
            });
        window.showHideMainLoader(false);
      }else {
        window.toast.fire({
          icon: "error",
          title: 'No Location Selected',
        });
      }
    }

    const datatableInit = async () => {
      $("#locations_table").addClass("nowrap").dataTable({
        responsive: true,
        order: [],
        columnDefs: [
          {targets: [3], orderable: false }
        ]
      });
       window.showHideMainLoader(false);
    }
    onMounted( () => {
      datatableInit()
    })

    return {gmb_locations, locations, closePopup, handleLocationSelectionRequest, getAllGMBListing, allGMBLocations, loginWithGoogle};
  },
};
</script>