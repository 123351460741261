<template>
  <Dashboard>

    <InsightCharts />

    <!-- Analytics-table -->
    <div class="table-area">
      <table class="display" id="facebookTable">
        <thead>
          <tr>
            <th class="all">Name</th>
            <th style="width: 100px;">Status</th>
            <th class="all" style="width:100px">action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="Campaign in allCampaigns" :key="Campaign">
            <td>{{ Campaign.name }}</td>
            <td>{{ Campaign.status }}</td>
            <td><router-link class="cus-btn" :to="{ name: 'FacebookAdSets', params: {id: Campaign.id}}">AdSets</router-link></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Analytics-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import {onMounted, ref} from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
import InsightCharts from "./../../components/Facebook/InsightCharts.vue";
import axios from "axios";
export default {
  components: {
    Dashboard,
    InsightCharts
  },
  setup() {
    let allCampaigns = ref([]);
    const getFacebookCampaigns = async () => {
      await axios
          .get(
              axios.defaults.baseUrl + "getFacebookCampaigns",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if(response.status) {
              if ($.fn.DataTable.isDataTable("#facebookTable")) {
                $("#facebookTable").DataTable().clear().destroy();
              }
              allCampaigns.value = response.data.data.campaigns;
              setTimeout(() => {
                datatableInit();
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const datatableInit = async () => {
      $("#facebookTable").addClass("nowrap").dataTable({
        responsive: true,
        order: []
      });
    }

    onMounted( () => {
      datatableInit();
      getFacebookCampaigns();
    })

    return { allCampaigns }
  }
};
</script>