import { ref } from 'vue';
import axios from 'axios';
const getVehicleMake = () => {
    var vehicleMakes = ref([]);
    const err = ref('');
    try {
        const loadVehicleMake = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleMake",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleMakes.value = response.data;
                })
                .catch((err) => {
                    err.value = 'No Vehicle Make data found';
                });
        }
        return { vehicleMakes, err, loadVehicleMake };
    } catch (err) {
        err.value = 'No Vehicle Make data found';
    }
}
export default getVehicleMake;