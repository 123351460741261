<template>
  <Dashboard>
    <section>
      <!-- inventory-header -->
      <div class="inventory-header lead-header">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="leads-btn">
              <div class="btns-group">
                <a class="cus-btn" href="javascript:;" @click="resetFilters"
                  >Reset</a
                >
              </div>
              <div class="search-box">
                <input
                  type="text"
                  class="reportrange form-control daterangebackground"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-6 websiteLeadsSearch">
            <div class="vehicle-area">
              <div class="search-box">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Leads"
                  v-model="keyword"
                  @keyup="getLeads"
                />
                <img
                  class="img-fluid search-icon"
                  src="@/assets/img/search.svg"
                  alt=""
                />
              </div>
              <div class="export">
                <a
                  class="export-btn"
                  @click="exportLeads"
                  href="javascript:;"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/file-excel-solid.svg"
                    alt=""
                  />
                  Export</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- inventory-header -->

      <!-- tabs-area -->
      <div class="leads-tabs">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <router-link
              :to="{ name: 'WebsiteFormLeads' }"
              class="nav-link active"
            >
              Website Leads
            </router-link>
            <router-link :to="{ name: 'WebsitePhoneLeads' }" class="nav-link">
              Phone Leads
            </router-link>
            <router-link :to="{ name: 'WebsiteTextLeads' }" class="nav-link">
              Text Track Leads
            </router-link>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" role="tabpanel">
            <!-- leads-table -->
            <div class="table-area">
              <table class="display bottommargin5" id="leadsTable">
                <thead>
                  <tr>
                    <th class="all">Date / Time</th>
                    <th class="all">Type</th>
                    <th>Name</th>
                    <th>Source</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th class="all" style="width: 80px">action</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <!-- leads-table -->
          </div>
        </div>
      </div>
      <!-- tabs-area -->
      <!-- Webiste Leads details modal -->
      <div
        v-if="showPopup"
        class="modal fade"
        id="websiteLeadsModal"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Lead Details</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mytable" id="leadDetailsContainer">
                <table class="table">
                  <tbody>
                    <tr v-if="LeadDetail.created">
                      <td>Lead Date</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.created }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.lead_type">
                      <td>Lead Type</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.lead_type }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.lead_source">
                      <td>Lead Source</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.lead_source }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.available_down_payment">
                      <td>Down payment</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.available_down_payment }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.year">
                      <td>Vehicle Year</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.year }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.make">
                      <td>Vehicle Make</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.make }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.model">
                      <td>Vehicle Model</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.model }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.miles">
                      <td>Vehicle Miles</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.miles }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.vehicle_condition">
                      <td>Vehicle Condition</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.vehicle_condition }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.vin">
                      <td>Vehicle VIN</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.vin }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.stock_no">
                      <td>Vehicle Stock</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.stock_no }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.first_name">
                      <td>Customer First Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.first_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.last_name">
                      <td>Customer Last Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.last_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.phone_number">
                      <td>Customer Phone</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.phone_number }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.email_address">
                      <td>Customer Email</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.email_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.test_drive_date">
                      <td>Fate</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.test_drive_date }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.test_drive_time">
                      <td>Time</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.test_drive_time }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.ip_address">
                      <td>Device IP</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.ip_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.social_security_number">
                      <td>Customer SSN</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.social_security_number }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.driving_license_number">
                      <td>DL#</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.driving_license_number }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.driving_license_state">
                      <td>DL State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.driving_license_state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.date_of_birth">
                      <td>Date Of Birth</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.date_of_birth }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.street_address">
                      <td>Customer Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.street_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.monthlyrentmortage">
                      <td>Monthly Rent / Mortgage</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.monthlyrentmortage }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.city">
                      <td>Customer City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.state">
                      <td>Customer State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.zip_code">
                      <td>Customer Zip</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.zip_code }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.time_at_address">
                      <td>Time at Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.time_at_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.street_address2">
                      <td>Customer Address 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.street_address2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.monthlyrentmortage2">
                      <td>Monthly Rent / Mortgage 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.monthlyrentmortage2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.city2">
                      <td>Customer City 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.city2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.state2">
                      <td>Customer State 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.state2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.zip_code2">
                      <td>Customer Zip 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.zip_code2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.time_at_address2">
                      <td>Time at Address 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.time_at_address2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.employment_status">
                      <td>Employment Status</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.employment_status }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.monthly_rent">
                      <td>Monthly Rent</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.monthly_rent }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.anual_income">
                      <td>Income</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.anual_income }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.time_employed">
                      <td>Time Employed</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.time_employed }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.employer_name">
                      <td>Employer Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.employer_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.job_title">
                      <td>Job Title</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.job_title }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_phone">
                      <td>Work Phone Number</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_phone }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_street_address">
                      <td>Work Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_street_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_city">
                      <td>Work City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_state">
                      <td>Work State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_zip_code">
                      <td>Work Zip</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_zip_code }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.employment_status2">
                      <td>Employment Status 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.employment_status2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.monthly_rent2">
                      <td>Monthly Rent 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.monthly_rent2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.anual_income2">
                      <td>Income 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.anual_income2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.time_employed2">
                      <td>Time Employed 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.time_employed2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.employer_name2">
                      <td>Employer Name 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.employer_name2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.job_title2">
                      <td>Job Title 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.job_title2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_phone2">
                      <td>Work Phone Number 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_phone2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_street_address2">
                      <td>Work Address 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_street_address2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_city2">
                      <td>Work City 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_city2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_state2">
                      <td>Work State 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_state2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.work_zip_code2">
                      <td>Work Zip 2</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.work_zip_code2 }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_name">
                      <td>Company Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_street_address">
                      <td>Company Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_street_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_city">
                      <td>Company City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_state">
                      <td>Company State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_zip_code">
                      <td>Company ZIP Code</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_zip_code }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_signer">
                      <td>Company Signer</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_signer }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_monthly_income">
                      <td>Gross Monthly Income</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_monthly_income }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_taxid">
                      <td>Federal Tax ID #</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_taxid }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_phone">
                      <td>Company Phone Number</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_phone }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_email">
                      <td>Company Email</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_email }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_fax">
                      <td>Fax Number</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_fax }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_date_established">
                      <td>Date Established</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_date_established }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_business_years">
                      <td>Number Of years In Business</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_business_years }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.company_type">
                      <td>Type of Business</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.company_type }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.bank_name">
                      <td>Bank Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.bank_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.bank_city">
                      <td>Bank City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.bank_city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.bank_state">
                      <td>Bank State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.bank_state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.bank_phone">
                      <td>Bank Phone Number</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.bank_phone }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.bank_contact_person">
                      <td>Bank Contact Person</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.bank_contact_person }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.commercial_name">
                      <td>Trade Reference Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.commercial_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.commercial_city">
                      <td>Trade Reference City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.commercial_city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.commercial_phone">
                      <td>Trade Reference Phone</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.commercial_phone }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.insurance_carrier">
                      <td>Insurance Carrier</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.insurance_carrier }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.insurance_policyno">
                      <td>Insurance Policy #</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.insurance_policyno }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.insurance_agency">
                      <td>Insurance Agency</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.insurance_agency }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.insurance_phone">
                      <td>Insurance Phone #</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.insurance_phone }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.desired_year">
                      <td>Vehicle Year</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.desired_year }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.desired_make">
                      <td>Vehicle Make</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.desired_make }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.desired_model">
                      <td>Vehicle Model</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.desired_model }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.desired_trim">
                      <td>Vehicle Trim</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.desired_trim }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.desired_mileage">
                      <td>Vehicle Mileage</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.desired_mileage }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.desired_loan">
                      <td>Vehicle Desired Loan</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.desired_loan }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.sales_person">
                      <td>Sales Person</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.sales_person }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.how_did_you_hear_about_us">
                      <td>How Did You Hear About Us</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.how_did_you_hear_about_us }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.sms_form_type">
                      <td>SMS Reason</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.sms_form_type }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.message">
                      <td>Comment</td>
                      <td style="word-break: break-all">
                        <span class="dot"></span>
                        {{ LeadDetail.message }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.first_name">
                      <td>Co Applicant First Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.first_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.last_name">
                      <td>Co Applicant Last Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.last_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.phone_number">
                      <td>Co Applicant Phone</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.phone_number }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.email_address">
                      <td>Co Applicant Email</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.email_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.social_security_number">
                      <td>Co Applicant SSN</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.social_security_number }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.driving_license_number">
                      <td>Co Applicant DL#</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.driving_license_number }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.driving_license_state">
                      <td>Co Applicant DL State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.driving_license_state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.date_of_birth">
                      <td>Co Applicant Date Of Birth</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.date_of_birth }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.street_address">
                      <td>Co Applicant Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.street_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.city">
                      <td>Co Applicant City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.state">
                      <td>Co Applicant State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.zip_code">
                      <td>Co Applicant Zip</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.zip_code }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.time_at_address">
                      <td>Co Applicant Time at Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.time_at_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.employment_status">
                      <td>Co Applicant Employment Status</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.employment_status }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.anual_income">
                      <td>Co Applicant Income</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.anual_income }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.time_employed">
                      <td>Co Applicant Time Employed</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.time_employed }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.employer_name">
                      <td>Co Applicant Employer Name</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.employer_name }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.job_title">
                      <td>Co Applicant Job Title</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.job_title }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.work_phone">
                      <td>Co Applicant Work Phone Number</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.work_phone }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.work_street_address">
                      <td>Co Applicant Work Address</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.work_street_address }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.work_city">
                      <td>Co Applicant Work City</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.work_city }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.work_state">
                      <td>Co Applicant Work State</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.work_state }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.coapplicant.work_zip_code">
                      <td>Co Applicant Work Zip</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.coapplicant.work_zip_code }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.replacing">
                      <td>Are You Replacing This Vehicle?</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.replacing }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.payoff">
                      <td>
                        Does Your Car Have A Payoff With A Bank Or Lender?
                      </td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.payoff }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.previous_damage">
                      <td>Previous Damage History?</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.previous_damage }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.ext_color">
                      <td>Vehicle Exterior Color?</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.ext_color }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.repairs">
                      <td>Does Your Vehicle Need Any Repairs?</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.repairs }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.options">
                      <td>Options?</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.options }}
                      </td>
                    </tr>
                    <tr v-if="LeadDetail.imageLinks">
                      <td>Images</td>
                      <td>
                        <span class="dot"></span>
                        {{ LeadDetail.imageLinks }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="submit" class="cancle-btn" @click="printLead">
                  Print
                </button>
                <button type="submit" data-dismiss="modal" class="send-btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Website Leads details modal -->
    </section>
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import axios from "axios";
import "daterangepicker";
import Dashboard from "./../../../layouts/Dashboard.vue";
import moment from "moment";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let keyword = ref("");
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    $(document).on("click", ".viewLeadDetails", function () {
      openWebsiteLeadsDetailsModal($(this).data("id"));
    });

    const printLead = () => {
      $("#printLead").remove();
      const modal = document.getElementById("leadDetailsContainer");
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("printLead");

      if (!section) {
        section = document.createElement("div");
        section.id = "printLead";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      // Small delay of 0.5s
      window.moveTo(0, 0);
      window.resizeTo(640, 480);
      setTimeout(() => {
        window.print();
      });
    };

    // Website Leads Details Modal Open
    const openWebsiteLeadsDetailsModal = (id) => {
      $(".loaders").show();
      axios
        .post(
          axios.defaults.baseUrl + "getWebsiteLeadDetail",
          {
            id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          $(".loaders").hide();
          LeadDetail.value = response.data;
          showPopup.value = true;
          setTimeout(() => {
            $("#websiteLeadsModal").modal("show");
          });
        })
        .catch((error) => {
          error.value = "No website Leads Data found";
        });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getLeads();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };
    let LeadDetail = ref(null);
    let showPopup = ref(false);

    const getLeads = async () => {
      if ($.fn.DataTable.isDataTable("#leadsTable")) {
        $("#leadsTable").DataTable().clear().destroy();
      }
      await $("#leadsTable")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          dom: "rtiplf",
          searching: false,
          bInfo: false,
          pageLength: 50,
          ajax: {
            url: axios.defaults.baseUrl + "getWebsiteFromLeads",
            data: function (d) {
              d.search["value"] = keyword.value;
              d.fromDate = startDate.value;
              d.toDate = endDate.value;
            },
            type: "post",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          },
          columns: [
            { data: "created_date" },
            { data: "lead_type" },
            { data: "first_name" },
            { data: "lead_source" },
            { data: "email_address" },
            { data: "phone_number" },
            { data: "actions" },
          ],
          order: [],
          columnDefs: [{ targets: [6], orderable: false }],
        });
    };

    // Website Leads Details Modal Open
    const openLeadsDetailsModal = (id) => {
      axios
        .post(
          axios.defaults.baseUrl + "getWebsiteLeadDetail",
          {
            id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          LeadDetail.value = response.data;
          showPopup.value = true;
          $("#websiteLeadsModal").modal("show");
        })
        .catch((error) => {
          error.value = "No website Leads Data found";
        });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    }

    const exportLeads = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "exportWebsiteFormLeads",
          {
            responseType: "arraybuffer",
            fromDate: startDate.value,
            toDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status != "error") {
            window.showHideMainLoader(false);
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "leads.csv");
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
      window.showHideMainLoader(false);
    }

    onMounted(() => {
      dateRangePickerInit();
    });

    return {
      keyword,
      LeadDetail,
      getLeads,
      showPopup,
      openLeadsDetailsModal,
      resetFilters,
      printLead,
      exportLeads,
    };
  },
};
</script>
<style>
#leadsTable tbody tr {
  position: relative;
}
</style>