<template>
  <Dashboard>
    <!-- daterange-header -->
    <div class="inventory-header lead-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="leads-btn">
            <div class="btns-group">
              <a class="cus-btn" href="javascript:;" @click="resetFilters"
                >Reset</a
              >
            </div>
            <div class="search-box">
              <input
                type="text"
                class="reportrange form-control daterangebackground"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daterange-header -->
    <!-- google-graph -->
    <div class="google-graph">
      <div class="row">
        <div class="col-md-12">
          <div class="mygraph">
            <h4>Sessions</h4>
            <apexchart
              v-if="enableChart"
              id="chart2"
              ref="myChart"
              width="100%"
              height="365"
              type="area"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- google-graph -->
    <!-- channel-table -->
    <div class="channel-tabel">
      <div class="row">
        <div class="col-lg-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="topChannels">
              <thead>
                <tr>
                  <th>Medium</th>
                  <th>New Users</th>
                  <th>New Sessions</th>
                  <th>Sessions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in topChannels" :key="item">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                  <td>{{ item[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>

        <div class="col-lg-6">
          <!-- Analytics-table -->
          <div class="table-area hide-select-search">
            <table class="display" id="topSources">
              <thead>
                <tr>
                  <th>Source/Medium</th>
                  <th>New Users</th>
                  <th>New Sessions</th>
                  <th>Sessions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="source in topSources" :key="source">
                  <td>{{ source[0] }}</td>
                  <td>{{ source[1] }}</td>
                  <td>{{ source[2] }}</td>
                  <td>{{ source[3] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Analytics-table -->
        </div>
      </div>
    </div>
    <!-- channel-table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import "daterangepicker";
import moment from "moment";
import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
import axios from "axios";
export default {
  components: {
    Dashboard,
    apexchart: VueApexCharts,
  },
  setup() {
    let series = ref([]);
    let chartOptions = ref({});
    let enableChart = ref("false");
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());

    let googleSessions = ref([]);
    let googleSessionsError = ref("");
    let topChannels = ref([]);
    let topChannelsError = ref("");
    let topSources = ref([]);
    let topSourcesError = ref("");

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getGoogleSessions();
      getTopChannels();
      getTopSources();
      $(".reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };

    const getGoogleSessions = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getGoogleSessions",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          googleSessions.value = response.data;
          enableChart.value = true;
          series.value = [
            {
              name: "Sessions",
              data: googleSessions.value.sessions,
            },
          ];
          chartOptions.value = {
            chart: {
              type: "area",
              stacked: true,
              id: "sessions",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              // OR provide an array
            },
            colors: ["#FCD932"],
            fill: {
              type: "gradient",
              gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
              },
            },
            xaxis: {
              type: "category",
              convertedCatToNumeric: false,
              categories: googleSessions.value.dates,
            },
          };
        })
        .catch((googleSessionsError) => {
          googleSessionsError.value = "No Sessions for Google Analytics found";
        });
    };

    const getTopChannels = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getTopChannels",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          topChannels.value = response.data.data.rows;
          if ($.fn.DataTable.isDataTable("#topChannels")) {
            $("#topChannels").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#topChannels").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((topChannelsError) => {
          topChannelsError.value = "No Sessions for Google Analytics found";
        });
    };

    const getTopSources = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "getTopSources",
          {
            startDate: startDate.value,
            endDate: endDate.value,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          topSources.value = response.data.data.rows;
          if ($.fn.DataTable.isDataTable("#topSources")) {
            $("#topSources").DataTable().clear().destroy();
          }
          setTimeout(() => {
            $("#topSources").addClass("nowrap").dataTable({
              responsive: true,
              fixedHeader: true,
            });
          });
        })
        .catch((topSourcesError) => {
          topSourcesError.value = "No Sessions for Google Analytics found";
        });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
        {
          startDate: startDate.value,
          endDate: endDate.value,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        cb
      );
      cb(startDate.value, endDate.value);
    };

    onMounted(() => {
      $("#topChannels").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      $("#topSources").addClass("nowrap").dataTable({
        responsive: true,
        fixedHeader: true,
      });
      dateRangePickerInit();
    });

    return {
      googleSessions,
      series,
      chartOptions,
      enableChart,
      topChannels,
      topSources,
      topChannelsError,
      topSourcesError,
      googleSessionsError,
      resetFilters,
    };
  },
};
</script>