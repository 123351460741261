<template>
  <!-- header-area -->
  <div class="header-area">
    <div class="row">
      <div class="col-md-6">
        <div class="title">
          <span>
            <h2>
              <span class="mob"
                ><img class="img-fluid" src="@/assets/img/menu.svg" alt=""
              /></span>
              <span class="menuwidth"
                ><img class="img-fluid" src="@/assets/img/menu.svg" alt=""
              /></span>
              {{props.RouteObject.meta.pageTitle}}
            </h2>

            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li
                  v-for:="BreadCrumb in props.RouteObject.meta.breadCrumbs"
                  class="breadcrumb-item"
                  :class="BreadCrumb.class"
                >
                  <router-link :to="BreadCrumb.url">{{
                    BreadCrumb.name
                  }}</router-link>
                </li>
                <!-- <li class="breadcrumb-item"><a href="#">Website</a></li>
                <li class="breadcrumb-item active" aria-current="page">report</li> -->
              </ol>
            </nav>
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <div class="users">
          <ul>
            <li>
              <a href="mailto:support@dealersgear.com"
                ><img class="img-fluid" src="@/assets/img/message.svg" alt=""
              /></a>
            </li>
            <li>
              <a href="tel:+19499230190"
                ><img class="img-fluid" src="@/assets/img/call.svg" alt=""
              /></a>
            </li>
            <li>
              <a href="#">{{ userName }}</a>
              <!-- sub-user -->
              <div class="sub-user">
                <ul>
                  <li>
                    <a href="#" @click="props.Logout"
                      ><img
                        class="img-fluid"
                        src="@/assets/img/sign-out.svg"
                        alt=""
                      />
                      Logout</a
                    >
                  </li>
                </ul>
              </div>
              <!-- sub-user -->
            </li>
            <li>
              <a class="user-img" href="#"
                ><img class="img-fluid" src="@/assets/img/users.png" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- header-area -->
</template>
<script>
export default {
  props: {
    Logout: Function,
    RouteObject: Object
  },
  setup(props) {
    const userName = localStorage.getItem("first_name")+' '+localStorage.getItem("last_name");
    return {props, userName}
  }
};
</script>


