<template>
  <Dashboard>
    <!-- Rebates Table -->
    <div class="table-area addpage-table">
      <!-- action-btn -->
      <div class="action-drop">
        <a
          class="action-btn"
          href="#"
          data-toggle="modal"
          data-target="#rebateModal"
        >
          Add Rebate</a
        >
      </div>

      <table class="display" id="Rebates">
        <thead>
          <tr>
            <th class="all">Make</th>
            <th>Model</th>
            <th>Trim</th>
            <th>Dealer Discount</th>
            <th>Rebate Discount</th>
            <th style="width: 60px">Action</th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- Rebates Table -->
    <!-- add Rebates modal -->
    <div
      class="modal select-modal3 fade"
      id="rebateModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Update Rebate
            </h5>
            <h5 v-else class="modal-title" id="staticBackdropLabel">
              Add Rebate
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform" @submit.prevent="submitForm">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Make:</label>
                  <select
                    v-if="editMode"
                    readonly
                    class="form-control tags1"
                    v-model="rebate.make"
                    id="selectMake"
                  >
                    <option :value="rebate.make" :data-label="rebate.make">
                      {{ rebate.make }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control tags1"
                    v-model="rebate.make"
                    id="selectMake"
                  >
                    <option
                      v-for="make in makes"
                      :key="make"
                      :value="make"
                      :data-label="make"
                    >
                      {{ make }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label>Model:</label>
                  <select
                    v-if="editMode"
                    readonly
                    class="form-control tags1"
                    v-model="rebate.model"
                    id="selectModel"
                  >
                    <option :value="rebate.model" :data-label="rebate.model">
                      {{ rebate.model }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control tags1"
                    v-model="rebate.model"
                    id="selectModel"
                  >
                    <option
                      v-for="model in models"
                      :key="model"
                      :value="model"
                      :data-label="model"
                    >
                      {{ model }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label>Trim:</label>
                  <select
                    v-if="editMode"
                    readonly
                    class="form-control tags1"
                    v-model="rebate.trim"
                    id="selectTrim"
                  >
                    <option :value="rebate.trim" :data-label="rebate.trim">
                      {{ rebate.trim }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control tags1"
                    v-model="rebate.trim"
                    id="selectTrim"
                  >
                    <option
                      v-for="trim in trims"
                      :key="trim"
                      :value="trim"
                      :data-label="trim"
                    >
                      {{ trim }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-12">
                  <label>Dealer Discount:</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    v-model="rebate.dealer_discount"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Rebate Discount:</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    v-model="rebate.rebate_discount"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closeModal" class="cancle-btn">
                  Cancel
                </button>
                <button type="submit" class="send-btn">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add Rebates moda -->
    <!-- delete Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this rebate?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button type="button" @click="deleteRebate" class="send-btn">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete Modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import { onMounted, ref } from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let allRebates = ref({});
    let rebate = ref({});
    let makes = ref({});
    let models = ref({});
    let trims = ref({});
    let allowSubmit = ref(true);
    let editMode = ref(false);
    let idForDelete = ref("");
    // Close Modal
    const closeModal = () => {
      rebate.value = {};
      editMode.value = false;
      $("#rebateModal").modal("hide");
      $("#deleteModal").modal("hide");
    };
    const submitForm = () => {
      if (allowSubmit.value) {
        if (editMode.value) {
          updateRebate();
        } else {
          addRebate();
        }
      }
    };

    const getRebateMakeModelTrim = async () => {
      await axios
        .get(axios.defaults.baseUrl + "getRebateMakeModelTrim", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          makes.value = response.data.makes;
          models.value = response.data.models;
          trims.value = response.data.trims;
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
    };

    const getRebates = async () => {
      if ($.fn.DataTable.isDataTable("#Rebates")) {
        $("#Rebates").DataTable().clear().destroy();
      }
      await $("#Rebates")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: axios.defaults.baseUrl + "getRebates",
              type: "get",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              { data: "make" },
              { data: "model" },
              { data: "trim" },
              { data: "dealer_discount" },
              { data: "rebate_discount" },
              { data: "actions" },
            ],
            order: [],
            columnDefs: [
              {targets: [5], orderable: false }
            ]
          });
    };

    const addRebate = async () => {
      window.showHideMainLoader(true);
      let data = new FormData();
      data.append("make", rebate.value.make);
      data.append("model", rebate.value.model);
      data.append("trim", rebate.value.trim);
      data.append("dealer_discount", rebate.value.dealer_discount);
      data.append("rebate_discount", rebate.value.rebate_discount);
      await axios
        .post(axios.defaults.baseUrl + "addRebate", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getRebates();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    // Open Edit Modal

    $(document).on('click', '.editRebate', function() {
      let rebateId = $(this).data('id');
      window.showHideMainLoader(true);
      axios
        .get(axios.defaults.baseUrl + "getRebateData/"+rebateId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            editMode.value = true;
            rebate.value = response.data.data;
            $("#rebateModal").modal("show");
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    });

    // Update Rebates
    const updateRebate = async () => {
      window.showHideMainLoader(true);
      let data = new FormData();
      data.append("make", rebate.value.make);
      data.append("model", rebate.value.model);
      data.append("trim", rebate.value.trim);
      data.append("dealer_discount", rebate.value.dealer_discount);
      data.append("rebate_discount", rebate.value.rebate_discount);
      await axios
        .post(
          axios.defaults.baseUrl + "updateRebate/" + rebate.value.id,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getRebates();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };
    // Open Delete Modal
    $(document).on('click', '.deleteRebate', function() {
      idForDelete.value = $(this).data('id');
      $("#deleteModal").modal("show");
    });
    // Delete Rebates:
    const deleteRebate = async () => {
      window.showHideMainLoader(true);
      await axios
        .delete(axios.defaults.baseUrl + "deleteRebate/" + idForDelete.value, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            window.toast.fire({
              icon: "success",
              title: response.data.message,
            });
            getRebates();
            closeModal();
          } else {
            window.toast.fire({
              icon: "error",
              title: response.data.message
            });
          }
        })
        .catch(function (error) {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    onMounted(() => {
      getRebates();
      getRebateMakeModelTrim();
    });

    return {
      allRebates,
      rebate,
      editMode,
      closeModal,
      submitForm,
      deleteRebate,
      makes,
      models,
      trims,
    };
  },
};
</script>
<style>
.delete-modal svg {
  display: inline-block;
  overflow: hidden;
}
#Rebates tbody tr td {
  position: relative;
}
</style>