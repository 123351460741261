<template>
  <Dashboard>
    <!-- Vehicle Infromation -->
    <div class="vehicle-information">
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <!-- details-slidder -->
          <div class="details-slider" v-if="isFetching">
            <div class="sliders slider-for" v-if="imageList2">
              <div v-for="image in imageList2" :key="image">
                <img class="img-fluid" :src="image" alt="" />
              </div>
            </div>
            <div class="sliders slider-nav" v-if="imageList2">
              <div v-for="image in imageList2" :key="image">
                <img class="img-fluid" :src="image" alt="" />
              </div>
            </div>

            <button type="button" @click="openImagesModal('imagesModal')" class="send-btn">Manage image</button>
          </div>
          <!-- details-slidder -->
        </div>
        <div class="col-md-8 col-lg-9">
          <form class="myform" @submit.prevent="handleUpdateRequest">
            <!-- vin-number -->
            <div class="vin-number">
              <div class="form-row">
                <div class="col-md-6 col-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="vehicle.vin"
                    placeholder="Enter your VIN Number of the Vehicle"
                  />
                </div>
                <div class="col-md-2 col-2">
                  <label class="switch">
                    <input type="checkbox" v-model="vehicle.vin_import_lock" />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="col-md-2 col-6">
                  <div class="decode">
                    <button v-if="vehicle.website_status == 'active'" type="button" class="send-btn" @click="removeVehicle">Remove</button>
                    <button v-else type="button" class="send-btn" @click="restoreVehicle">Restore</button>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="decode">
                    <button type="submit" class="save-btn">Save</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- vin-number -->

            <div class="infomation editform vehicleInformationEdit">
              <h4>Vehicle Infromation</h4>
              <div class="form-row">
                <!-- left -->
                <div class="col-md-6">
                  <div class="form-row rightbar">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Year
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.year_import_lock"
                          />
                          <span class="slider round"></span>
                        </label>
                      </label>
                      <select
                        class="form-control select2 yearSelect"
                        v-model="vehicle.year"
                      >
                        <option v-for="year in years" :key="year" :value="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Make
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.make_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 makeSelect"
                        v-model="vehicle.make"
                      >
                        <option
                          v-for="make in vehicleMakes"
                          :key="make.id"
                          :value="make.make"
                        >
                          {{ make.make }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Model
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.model_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.model"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Trim
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.trim_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.trim"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Fuel Type
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.fuel_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2"
                        v-model="vehicle.fuel"
                      >
                        <option
                          v-for="type in vehicleFuelTypes"
                          :key="type.id"
                          :value="type.type"
                        >
                          {{ type.type }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Mileage
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.mileage_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.mileage"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >MPG City
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.mpg_city_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.mpg_city"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >MPG Highway
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.mpg_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.mpg"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Engine
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.engine_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.engine"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Displacement
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.displacement_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.displacement"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Transmission
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.transmission_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 transmissionSelect"
                        v-model="vehicle.transmission"
                      >
                        <option
                          v-for="transmission in vehicleTransmission"
                          :key="transmission.id"
                          :value="transmission.transmission"
                        >
                          {{ transmission.transmission }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Cylinders
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.cylinders_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.cylinders"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Doors
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.doors_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.doors"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Drive Type
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.drivetrain_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 drivetrainSelect"
                        v-model="vehicle.drivetrain"
                      >
                        <option
                          v-for="driveType in vehicleDriveTypes"
                          :key="driveType.id"
                          :value="driveType.drive_type"
                        >
                          {{ driveType.drive_type }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Body Style
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.body_style_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 bodyStyleSelect"
                        v-model="vehicle.body_style"
                      >
                        <option
                          v-for="body_style in vehicleBodyStyle"
                          :key="body_style.id"
                          :value="body_style.body_style"
                        >
                          {{ body_style.body_style }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Autosize
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.autosize_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 autoSizeSelect"
                        v-model="vehicle.autosize"
                      >
                        <option
                          v-for="size in vehicleAutoSize"
                          :key="size.id"
                          :value="size.size"
                        >
                          {{ size.size }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- left -->

                <!-- right -->
                <div class="col-md-6">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Dealer Certified
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.certified_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >Yes
                          <input
                            type="radio"
                            v-model="vehicle.certified"
                            value="1"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >No
                          <input
                            type="radio"
                            v-model="vehicle.certified"
                            value="0"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Date in Stock
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.date_in_stock_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type='text'
                        readonly
                        id="date_in_stock"
                        class="form-control reportrange datesinglebackground"
                        v-model="vehicle.date_in_stock"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Stock Number
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.stock_no_import_lock"
                          />
                          <span class="slider round"></span>
                        </label>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.stock_no"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Exterior Color
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.ext_color_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 exteriorColorSelect"
                        v-model="vehicle.ext_color"
                      >
                        <option
                          v-for="color in vehicleExteriorColor"
                          :key="color.id"
                          :value="color.color"
                        >
                          {{ color.color }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Interior Color
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.int_color_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <select
                        class="form-control select2 interiorColorSelect"
                        v-model="vehicle.int_color"
                      >
                        <option
                          v-for="color in vehicleInteriorColor"
                          :key="color.id"
                          :value="color.color"
                        >
                          {{ color.color }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >MSRP
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.msrp_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.msrp"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Condition
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.new_used_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >New
                          <input
                            type="radio"
                            v-model="vehicle.new_used"
                            value="New"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >Used
                          <input
                            type="radio"
                            v-model="vehicle.new_used"
                            value="Used"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">
                        Status
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.status_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >Live
                          <input
                            type="radio"
                            v-model="vehicle.status"
                            value="Live"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >In Prep
                          <input
                            type="radio"
                            v-model="vehicle.status"
                            value="In Prep"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Selling Price
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.price_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.price"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Special Price
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.special_price_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.special_price"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Internet Price
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.internet_price_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.internet_price"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4"
                        >Invoice Amount
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="vehicle.invoice_amount_import_lock"
                          />
                          <span class="slider round"></span> </label
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="vehicle.invoice_amount"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Show in Specials? </label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >Yes
                          <input
                            type="radio"
                            v-model="vehicle.show_in_specials"
                            value="yes"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >No
                          <input
                            type="radio"
                            v-model="vehicle.show_in_specials"
                            value="no"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <label for="inputEmail4">On Hold? </label>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn act"
                          >Yes
                          <input
                            type="radio"
                            v-model="vehicle.on_hold"
                            value="yes"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="radiosbtn"
                          >No
                          <input
                            type="radio"
                            v-model="vehicle.on_hold"
                            value="no"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- right -->

                <div class="form-group col-md-12">
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="vehicle.description_import_lock"
                    />
                    <span class="slider round"></span>
                  </label>
                  <br />
                  <TinyMceEditor v-model="vehicle.description"
                      api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre"
                      :init="{
                        menubar: false,
                        branding: false,
                        extended_valid_elements: 'span[class|align|style]',
                        forced_root_block_attrs: {'class': 'root_block_p'},
                        element_format: 'html',
                        relative_urls: false,
                        remove_script_host: false,
                        height: 200,
                        browser_spellcheck: true,
                        verify_html: false,
                        images_upload_handler: tinyMceUploader,
                        plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                        toolbar: 'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code'
                      }"
                  />
                </div>

                <div class="form-group col-md-12">
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Craigslist Ad Keywords"
                    v-model="vehicle.craigslist_keywords"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Internal Field"
                    v-model="vehicle.internal_field"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Craigslist Title Text"
                    v-model="vehicle.craigslist_title"
                  />
                </div>

                <div class="col-md-12">
                  <div class="cus-btn text-right">
                    <button type="submit" class="send-btn">
                      Update Vehicle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Vehicle Infromation -->
    <!-- Manage Images Modal-->
    <div
        class="modal fade"
        id="imagesModal"
        tabindex="-1"
        data-backdrop="static"
        data-keyboard="false"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="imageUploadContainer" style="border-radius:20px;">
              <div class="dz-message needsclick">
                <i class="fa fa-cloud-upload fa-3x" style="color: #0087F7;cursor: pointer;"></i><br>
                Drop files here or click to upload.<br>
                <span class="note needsclick">(Maximum allowed filesize is <strong>10Mbs</strong>.)</span>
              </div>
            </div>
            <hr>
            <draggable
                :list="imageList"
                group="images"
                @start="drag=true"
                @end="drag=false"
                item-key="id"
                style="display:flex;display:flex;flex-shrink: 0;flex-flow: row wrap;justify-content: flex-end;align-items: flex-end;">
                <template #item="{element, index}">
                  <div style="width:80px;margin-right:5px;position:relative">
                    <img style="width:80px;border-radius:4px" :src="element" />
                    <a href="javascript:;" @click="onRemove(index)" style="position:absolute;top:0;right:0;background: #fff;">
                        <img
                          style="width:20px"
                          src="@/assets/img/delete-solid.svg"
                          alt=""
                        />
                    </a>
                  </div>
                </template>
            </draggable>
          </div>

          <div class="modal-footer">
            <div class="cus-btn">
              <button type="button" @click="cancelImageChanges" class="cancle-btn">
                Cancel Changes
              </button>
              <button type="button" class="send-btn" @click="updateVehicleImages">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Manage Images Modal-->
  </Dashboard>
</template>
<script>
import { onMounted, ref } from "vue";
import $ from "jquery";
import "slick-slider";
import axios from "axios";
import routes from "./../../routes";
import "daterangepicker";
import moment from "moment";
import { useRoute } from "vue-router";
import Dashboard from "./../../layouts/Dashboard.vue";
import getVehicle from "./../../composeables/getVehicle";
import getVehicleMake from "./../../composeables/getVehicleMake";
import getVehicleFuelTypes from "./../../composeables/getVehicleFuelTypes";
import getVehicleTransmission from "./../../composeables/getVehicleTransmission";
import getVehicleDriveTypes from "./../../composeables/getVehicleDriveTypes";
import getVehicleBodyStyle from "./../../composeables/getVehicleBodyStyle";
import getVehicleAutoSize from "./../../composeables/getVehicleAutoSize";
import getVehicleExteriorColor from "./../../composeables/getVehicleExteriorColor";
import getVehicleInteriorColor from "./../../composeables/getVehicleInteriorColor";
import { Dropzone } from "dropzone";
import draggable from "vuedraggable";
import TinyMceEditor from '@tinymce/tinymce-vue'
export default {
  components: {
    Dashboard,
    draggable,
    TinyMceEditor
  },
  setup() {
    const route = useRoute();
    let drag = ref(false);
    let isFetching = ref(false);
    let years = ref([]);
    let imageList = ref([]);
    let imageList2 = ref([]);

    const onRemove = async (index) => {
      imageList.value.splice(index, 1);
    }

    const closeImagesModal = async (element) => {
      $('#'+element).modal('hide');
    }

    const openImagesModal = async (element) => {
      $('#'+element).modal('show');
    }

    const cancelImageChanges = async () => {
      imageList.value = vehicle.value.imagelist.split(',');
      closeImagesModal('imagesModal');
    }

    // Year Default Values:
    let year = 1900;
    let date = new Date();
    let endYear = date.getFullYear() + 1;
    let end = endYear - year;
    let yearsArray = [];
    for (let count = 0; count <= end; count++) {
      yearsArray[count] = year + count;
    }
    years.value = yearsArray.reverse();
    // Get Vehicle Make Default Values:
    const { loadVehicleMake, vehicleMakes, err } = getVehicleMake();
    loadVehicleMake();
    // Get Vehicle Fuel Types
    const { vehicleFuelTypes, loadVehicleFuelTypes, fuelTypeError } =
      getVehicleFuelTypes();
    loadVehicleFuelTypes();
    // Get Vehicle Transmission Data
    const { vehicleTransmission, loadVehicleTransmission, transmissionError } =
      getVehicleTransmission();
    loadVehicleTransmission();
    // Get Vehicle Drive Types
    const { vehicleDriveTypes, loadVehicleDriveTypes, DriveTypesError } =
      getVehicleDriveTypes();
    loadVehicleDriveTypes();
    // Get Vehicle Body Style
    const { vehicleBodyStyle, loadVehicleBodyStyle, BodyStyleError } =
      getVehicleBodyStyle();
    loadVehicleBodyStyle();
    // Get Vehicle Auto Size
    const { vehicleAutoSize, loadVehicleAutoSize, AutoSizeError } =
      getVehicleAutoSize();
    loadVehicleAutoSize();
    // Get Vehicle Exterior Color
    const {
      vehicleExteriorColor,
      loadVehicleExteriorColor,
      ExteriorColorError,
    } = getVehicleExteriorColor();
    loadVehicleExteriorColor();
    // Get Vehicle Interior Color
    const {
      vehicleInteriorColor,
      loadVehicleInteriorColor,
      InteriorColorError,
    } = getVehicleInteriorColor();
    loadVehicleInteriorColor();
    // Load Vehicle Data
    const { vehicle, error, loadVehicle } = getVehicle(route.params.id);
    loadVehicle().then(() => {
      isFetching.value = true;
      imageList.value = vehicle.value.imagelist.split(',');
      imageList2.value = vehicle.value.imagelist.split(',');
      setTimeout(() => {
        carouselInit();
      })
      if (vehicle.value.year) {
        if(years.value.indexOf(vehicle.value.year) === -1){
          years .value.push(vehicle.value.year);
        }
        $(".vehicleInformationEdit .yearSelect")
          .select2()
          .val(vehicle.value.year)
          .trigger("change");
      }
      if (vehicle.value.make) {
        if(vehicleMakes.value.indexOf(vehicle.value.make) === -1){
          let timeStamp = new Date().getTime();
          vehicleMakes .value.push({'id': timeStamp, 'make': vehicle.value.make});
        }
        $(".vehicleInformationEdit .makeSelect")
          .select2()
          .val(vehicle.value.make)
          .trigger("change");
      }
      if (vehicle.value.transmission) {
        if(vehicleTransmission.value.indexOf(vehicle.value.transmission) === -1){
          let timeStamp = new Date().getTime();
          vehicleTransmission .value.push({'id': timeStamp, 'transmission': vehicle.value.transmission});
        }
        $(".vehicleInformationEdit .transmissionSelect")
          .select2()
          .val(vehicle.value.transmission)
          .trigger("change");
      }
      if (vehicle.value.drivetrain) {
        if(vehicleDriveTypes.value.indexOf(vehicle.value.drivetrain) === -1){
          let timeStamp = new Date().getTime();
          vehicleDriveTypes.value.push({'id': timeStamp, 'drive_type': vehicle.value.drivetrain});
        }
        $(".vehicleInformationEdit .drivetrainSelect")
          .select2()
          .val(vehicle.value.drivetrain)
          .trigger("change");
      }
      if (vehicle.value.body_style) {
        if(vehicleBodyStyle.value.indexOf(vehicle.value.body_style) === -1){
          let timeStamp = new Date().getTime();
          vehicleBodyStyle.value.push({'id': timeStamp, 'body_style': vehicle.value.body_style});
        }
        $(".vehicleInformationEdit .bodyStyleSelect")
          .select2()
          .val(vehicle.value.body_style)
          .trigger("change");
      }
      if (vehicle.value.autosize) {
        if(vehicleAutoSize.value.indexOf(vehicle.value.autosize) === -1){
          let timeStamp = new Date().getTime();
          vehicleAutoSize.value.push({'id': timeStamp, 'autosize': vehicle.value.autosize});
        }
        $(".vehicleInformationEdit .autoSizeSelect")
          .select2()
          .val(vehicle.value.autosize)
          .trigger("change");
      }
      if (vehicle.value.ext_color) {
        if(vehicleExteriorColor.value.indexOf(vehicle.value.ext_color) === -1){
          let timeStamp = new Date().getTime();
          vehicleExteriorColor.value.push({'id': timeStamp, 'ext_color': vehicle.value.ext_color});
        }
        $(".vehicleInformationEdit .vehicleExteriorColorSelect")
          .select2()
          .val(vehicle.value.ext_color)
          .trigger("change");
      }
      if (vehicle.value.int_color) {
        if(vehicleInteriorColor.value.indexOf(vehicle.value.int_color) === -1){
          let timeStamp = new Date().getTime();
          vehicleInteriorColor .value.push({'id': timeStamp, 'int_color': vehicle.value.int_color});
        }
        $(".vehicleInformationEdit .vehicleInteriorColorSelect")
          .select2()
          .val(vehicle.value.int_color)
          .trigger("change");
      }
      
      if (vehicle.value.date_in_stock) {
        vehicle.value.date_in_stock = moment(vehicle.value.date_in_stock).format('MM/DD/YYYY');
        setTimeout(() => {
          datePickerInit();
        });
      }else {
        vehicle.value.date_in_stock = moment().format('MM/DD/YYYY');
        setTimeout(() => {
          datePickerInit();
        });
      }
      
    });

    // Update Requst to update the vehicle information
    const handleUpdateRequest = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "updateVehicle/" + route.params.id,
          {
            vehicle,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if (response.status) {
            window.toast.fire({
              icon: "success",
              title: "Inventory Updated",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Update Request to update the vehicle images
    const updateVehicleImages = async () => {
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "updateVehicleImages",
              {
                vehicle_id: vehicle.value.id,
                images: imageList.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            console.log(response);
            if (response.status) {
              window.toast.fire({
                icon: "success",
                title: "Images Updated",
              });
            }
            carouselUnset();
            vehicle.value.imagelist = imageList.value.toString();
            imageList2.value = imageList.value;
            setTimeout(() => {
              carouselInit();
            })
            window.showHideMainLoader(false);
            closeImagesModal('imagesModal');
          })
          .catch((error) => {
            console.log(error);
          });
        window.showHideMainLoader(false);
    }

    const removeVehicle = async () => {
      window.showHideMainLoader(true);
      let id = vehicle.value.id;
      await axios
          .post(axios.defaults.baseUrl + "removeVehicle",
          {
            id
          },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem('auth_token'),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            window.toast.fire({
                icon: response.data.status,
                title: response.data.message,
              });
              if(response.data.status == 'success') {
                if(vehicle.value.new_used == 'New') {
                  routes.push({ name: "NewInventory" });
                }else {
                  routes.push({ name: "UsedInventory" });
                }
                vehicle.value.website_status = 'deleted';
              }
          }).catch((error) => {
            window.showHideMainLoader(false);
            console.log(error);
          });
    }

    const restoreVehicle = async () => {
      window.showHideMainLoader(true);
      let id = vehicle.value.id;
      await axios
          .post(axios.defaults.baseUrl + "restoreVehicle",
          {
            id
          },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem('auth_token'),
                },
              }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            window.toast.fire({
                icon: response.data.status,
                title: response.data.message,
              });
              if(response.data.status == 'success') {
                if(vehicle.value.new_used == 'New') {
                  routes.push({ name: "NewInventory" });
                }else {
                  routes.push({ name: "UsedInventory" });
                }
                vehicle.value.website_status = 'active';
                vehicle.value.deleted_date = null;
              }
          }).catch((error) => {
            window.showHideMainLoader(false);
            console.log(error);
          });
    }

    const carouselInit = async() => {
      //details slider
      $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
      });


      $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        responsive: [{
          breakpoint: 992,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 2
          }
        },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              slidesToShow: 2
            }
          }
        ]
      })
    }

    const carouselUnset = async() => {
      $('.slider-for').slick('unslick');
      $('.slider-nav').slick('unslick');
    }

    const imagesHandler = async () => {
      $(".dz-preview").remove();
      $(".dz-message.needsclick").show();
      new Dropzone("div.imageUploadContainer", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
        url: axios.defaults.baseUrl + "uploadInventoryImages",
        acceptedFiles: "image/*",
        maxFilesize: "10",
        parallelUploads: "1",
        timeout: 0,
        init: function() {
          $(".add_more_images_anchor").remove();
          this.on('addedfile', function(file){
            console.log(file);
            $(".dz-message.needsclick").hide();
            $(".add_more_images_anchor").remove();
            $("div.imageUploadContainer").append('<div class="add_more_images_anchor dz-preview" style="border: 1px dashed #0087F7;border-radius: 20px;cursor: pointer;"><div class="dz-image" style="cursor: pointer;">&nbsp;</div><div class="dz-details" style="opacity:1;width: 100%;height: 100%;cursor: pointer;"><div class="dz-size" style="cursor: pointer;"><span data-dz-size="" style="cursor: pointer;"><i class="fa fa-cloud-upload fa-3x" style="color: #0087F7;cursor: pointer;"></i></div><div class="dz-filename" style="cursor: pointer;"><span data-dz-name="" style="cursor: pointer;border: none;">Add More</span></div></div></div>');
          });
          this.on("sending", function(file, xhr, formData){
            formData.append("id", vehicle.value.id);
          });
          this.on("complete", function(file, xhr, formData){
            console.log(file+xhr+formData);
            $(".add_more_images_anchor").remove();
            $("div.imageUploadContainer").append('<div class="add_more_images_anchor dz-preview" style="border: 1px dashed #0087F7;border-radius: 20px;cursor: pointer;"><div class="dz-image" style="cursor: pointer;">&nbsp;</div><div class="dz-details" style="opacity:1;width: 100%;height: 100%;cursor: pointer;"><div class="dz-size" style="cursor: pointer;"><span data-dz-size="" style="cursor: pointer;"><i class="fa fa-cloud-upload fa-3x" style="color: #0087F7;cursor: pointer;"></i></div><div class="dz-filename" style="cursor: pointer;"><span data-dz-name="" style="cursor: pointer;border: none;">Add More</span></div></div></div>');
          });
          this.on("success", function(file, response){
            if (response.status == 'success') {
              imageList.value.push(response.data);
              setTimeout(() => {
                carouselUnset();
                carouselInit();
              })
            }else {
              window.toast.fire({
                icon: response.status,
                title: response.data,
              });
            }
          });
        }
      });
      $(document).on("click", ".add_more_images_anchor", function() {
        $('div.imageUploadContainer').get(0).dropzone.hiddenFileInput.click();
      });
    }

    const datePickerInit = async () => {
      console.log(moment(vehicle.value.date_in_stock).format('MM/DD/YYYY')) ;
      $(".reportrange").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        startDate: moment(vehicle.value.date_in_stock).format('MM/DD/YYYY'),
        endDate: moment(vehicle.value.date_in_stock).format('MM/DD/YYYY'),
        minYear: 1900,
        maxYear: parseInt(moment().add(1, "year").format('YYYY'),10),
        locale: {
          format: 'MM/DD/YYYY'
        }
      }, function(start) {
        vehicle.value.date_in_stock = moment(start).format('MM/DD/YYYY');
      });
    }

    const tinyMceUploader = async (blobInfo, success, failure) => {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', axios.defaults.baseUrl + "editorImageUpload");
      xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("auth_token"));
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json) {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        if (json.status == 'error') {
          failure(json.location);
          return;
        }
        success(json.location);
      };
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    }

    onMounted( () => {      
      imagesHandler();
      $(".vehicle-information .select2").select2({
        placeholder: "Select",
        allowClear: true,
      });
    })

    return {
      vehicle,
      years,
      vehicleMakes,
      vehicleFuelTypes,
      vehicleTransmission,
      vehicleDriveTypes,
      vehicleBodyStyle,
      vehicleAutoSize,
      vehicleExteriorColor,
      vehicleInteriorColor,
      error,
      err,
      fuelTypeError,
      transmissionError,
      DriveTypesError,
      BodyStyleError,
      AutoSizeError,
      ExteriorColorError,
      InteriorColorError,
      handleUpdateRequest,
      imageList,
      imageList2,
      openImagesModal,
      closeImagesModal,
      cancelImageChanges,
      updateVehicleImages,
      isFetching,
      drag,
      onRemove,
      removeVehicle,
      restoreVehicle,
      tinyMceUploader
    };
  }
};
</script>