import { ref } from 'vue';
import axios from 'axios';
const getDealerOtherInfo = () => {
    const dealerInfo = ref([]);
    const dealer = ref([]);
    const dealerInfoError = ref('');
    try {
        const loadDealerInfo = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getDealerOtherInfo/" + localStorage.getItem('dealer_id'),
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    dealerInfo.value = response.data.dealer_other_info;
                    dealer.value = response.data.dealer;
                })
                .catch((dealerInfoError) => {
                    dealerInfoError.value = 'No dealer Data found';
                });
        }
        return { dealerInfo, dealer, dealerInfoError, loadDealerInfo };
    } catch (dealerInfoError) {
        dealerInfoError.value = 'No dealer Data found';
    }
}
export default getDealerOtherInfo;