<template>
  <Dashboard>
    <!-- setting-header -->
    <div class="setting-header">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <form>
            <div class="form-row">
              <div class="col-5">
                <div class="avabile-ads">
                  <h5>Available Ads : 1</h5>
                </div>
              </div>

              <div class="col-7">
                <div class="monthly">
                  <h5>Monthly Budget </h5>
                  <input type="text" class="form-control" placeholder="0.00">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="auto-refill">
            <form>
              <div class="form-row">
                <div class="col-sm-6">

                  <div class="form-check form-check-inline">
                    <label class="radiosbtn act">Auto Refill
                      <input type="radio" checked="checked" name="radio">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="radiosbtn act">Manual Refill
                      <input type="radio" checked="checked" name="radio">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="refill-btn">
                    <input type="text" class="form-control" placeholder="0.00">
                    <a class="cus-btn" href="#">Refill Now </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- setting-header -->

    <!-- Craigslist Phrases -->
    <div class="craigslist-area">

      <form class="myform">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="inputEmail4">Craigslist Phrases</label>
            <textarea class="form-control" rows="5"></textarea>
            <small>Press enter key for new line. Every new line will be treated as new phrase</small>
          </div>

          <div class="form-group col-md-12">
            <label for="inputEmail4">Craigslist Ad Keywords (General For Whole Inventory)</label>
            <textarea class="form-control" rows="5"></textarea>
          </div>

          <div class="form-group col-md-12">
            <div class="row">
              <div class="col-md-5 col-lg-3">
                <div class="setting-text">
                  <h4>Facebook Settings</h4>
                  <div class="fb-btn">
                    <a class="facebook" href="#"><i class="fa fa-facebook" aria-hidden="true"></i> Sign In with Facebook</a>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-lg-6">
                <div class="alert-text">
                  <h5><img class="img-fluid" src="@/assets/img/warning.svg" alt=""> Attention!</h5>
                  <p>By Signing in with Facebook you will allow access to autolink to manage your pages and post your
                    vehicles on your page on your behalf.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
    <!-- Craigslist Phrases -->


    <div class="cus-btn text-right">
      <button type="submit" class="cancle-btn">Cancel</button>
      <button type="submit" class="send-btn">Save Changes</button>
    </div>
  </Dashboard>
</template>
<script>
import Dashboard from "./../../../layouts/Dashboard.vue";

export default {
  components: {
    Dashboard,
  },
};
</script>
