import { ref } from 'vue';
import axios from 'axios';
const getDepartmentTypes = () => {
    const departmentTypes = ref([]);
    const departmentTypesError = ref('');
    try {
        const loadDepartmentTypes = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getDepartmentTypes",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    departmentTypes.value = response.data;
                })
                .catch((departmentTypesError) => {
                    departmentTypesError.value = 'No Department Types found';
                });
        }
        return { departmentTypes, departmentTypesError, loadDepartmentTypes };
    } catch (departmentTypesError) {
        departmentTypesError.value = 'No Department Types found';
    }
}
export default getDepartmentTypes;