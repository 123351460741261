<template>
  <Dashboard>
    <!-- analytics-area -->
    <div class="analytics-area">
      <!-- sub-title -->
      <div class="sub-title">
        <h4>Overview</h4>
        <div class="reportrange">
          Select Date &nbsp;
          <img class="img-fluid" src="@/assets/img/arrow.svg" alt="" />
        </div>
      </div>
      <!-- sub-title -->

      <!-- overview-box -->
      <div class="overview-box">
        <div class="overviews">
          <p>Traffic</p>
          <h3>2.4K</h3>
          <p class="up">
            <img class="img-fluid" src="@/assets/img/up.svg" alt="" /> 36.8%
          </p>
        </div>
        <div class="overviews">
          <p>Sessions</p>
          <h3>2.7K</h3>
          <p class="down">
            <img class="img-fluid" src="@/assets/img/down.svg" alt="" /> 36.8%
          </p>
        </div>
        <div class="overviews">
          <p>Bounce Rate</p>
          <h3>86.31%</h3>
          <p class="down">
            <img class="img-fluid" src="@/assets/img/down.svg" alt="" /> 36.8%
          </p>
        </div>

        <div class="overviews">
          <p>Conversions</p>
          <h3>2,000</h3>
          <p class="down">
            <img class="img-fluid" src="@/assets/img/down.svg" alt="" /> 36.8%
          </p>
        </div>

        <div class="overviews">
          <p>Conversion rate</p>
          <h3>33.29%</h3>
          <p class="down">
            <img class="img-fluid" src="@/assets/img/down.svg" alt="" /> 36.8%
          </p>
        </div>
      </div>
      <!-- overview-box -->

      <!-- Analytics-graph -->
      <div class="analytics-graph">
        <div class="row">
          <div class="col-md-6">
            <!-- sub-title -->
            <div class="sub-title">
              <h4>Analytics</h4>
              <div class="reportrange">
                Select Date &nbsp;
                <img class="img-fluid" src="@/assets/img/arrow.svg" alt="" />
              </div>
            </div>
            <!-- sub-title -->
            <div class="graph">
              <div id="graph1"></div>
            </div>
          </div>

          <div class="col-md-6">
            <!-- sub-title -->
            <div class="sub-title">
              <h4>Calls</h4>
              <div class="reportrange">
                Select Date &nbsp;
                <img class="img-fluid" src="@/assets/img/arrow.svg" alt="" />
              </div>
            </div>
            <!-- sub-title -->
            <div class="graph">
              <!-- call-bar -->
              <div class="call-bar">
                <div class="service-bar" style="height: 60%">
                  <div class="call-overly">
                    <h4>Service</h4>
                    <img
                      class="img-fluid"
                      src="@/assets/img/Vectors.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div class="call-bar">
                <div class="sale-bar" style="height: 50%">
                  <div class="call-overly">
                    <h4>Sale</h4>
                    <img
                      class="img-fluid"
                      src="@/assets/img/Vectors.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <!-- call-bar -->
            </div>
          </div>
        </div>
      </div>
      <!-- Analytics-graph -->

      <!-- Analytics-table -->
      <div class="table-area">
        <h4>Most visited pages</h4>
        <table class="display">
          <thead>
            <tr>
              <th>Best Performance</th>
              <th>Users</th>
              <th>Conversions</th>
              <th>Conversion rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>m.facebook.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>cars.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
            <tr>
              <td>dealersgear.com</td>
              <td>211</td>
              <td>6</td>
              <td>33.29%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Analytics-table -->
    </div>
    <!-- analytics-area -->
  </Dashboard>
</template>
<script>
// import $ from "jquery";
// import { ref } from "vue";
// import VueApexCharts from "vue3-apexcharts";
import Dashboard from "./../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
    // apexchart: VueApexCharts,
  },
  setup() {
    
    return {
      
    };
  },
};
</script>