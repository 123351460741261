<template>
  <Dashboard>
    <!-- InventoryExportSettings-Table -->
    <div class="table-area pricing-table positionedbutton">
      <div class="action-drop">
        <a class="action-btn" href='javascript:;' @click="openAddMarketPlaceModal">Add Marketplace</a>
      </div>
      <table class="display" id="exportSettings">
        <thead>
          <tr>
            <th class="all">Marketplace</th>
            <th class="all" style="width:70px;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="marketPlace in marketPlaces" :key="marketPlace">
            <td>{{ marketPlace.market_place_name }}</td>
            <td>
              <a href="javascript:void(0);" class="editExportSettings" @click="editMarketPlace(marketPlace)"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 0.0234375C15.1758 0.0234375 14.6133 0.246094 14.1797 0.679688L6.75 8.08594L6.58594 8.25L6.53906 8.48438L6.02344 11.1094L5.78906 12.2109L6.89062 11.9766L9.51562 11.4609L9.75 11.4141L9.91406 11.25L17.3203 3.82031C18.1846 2.95605 18.1846 1.54395 17.3203 0.679688C16.8867 0.246094 16.3242 0.0234375 15.75 0.0234375ZM15.75 1.47656C15.9258 1.47656 16.0986 1.56738 16.2656 1.73438C16.5996 2.06836 16.5996 2.43164 16.2656 2.76562L9 10.0312L7.71094 10.2891L7.96875 9L15.2344 1.73438C15.4014 1.56738 15.5742 1.47656 15.75 1.47656ZM0 3V18H15V8.10938L13.5 9.60938V16.5H1.5V4.5H8.39062L9.89062 3H0Z" fill="#63729A"/>
              </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- add edit marketplace modal -->
    <div
        class="modal select-modal fade"
        id="addEditMarketplaceModal"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!editMode" class="modal-title" id="staticBackdropLabel">
              Add New Marketplace
            </h5>
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Update Marketplace
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="myform">
            <input type="hidden" v-model="id" />
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Marketplace name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="marketPlaceDetails.market_place_name"
                    required
                  />
                </div>

              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button type="button" @click="closePopup" class="cancle-btn">
                  Back
                </button>
                <button
                    v-if="editMode"
                    type="button"
                    @click="handleMarketplaceUpdateRequest"
                    class="send-btn"
                >
                  Submit
                </button>
                <button
                    v-else
                    type="button"
                    @click="handleMarketplaceAddRequest"
                    class="send-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add edit marketplace modal -->
    <!-- InventoryExportSettings-Table -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import {onMounted, ref} from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  setup() {
    let marketPlaces = ref([]);
    let marketPlaceDetails = ref({});
    let editMode = ref(false);

    const editMarketPlace = async (marketPlace) => {
      editMode.value = true;
      marketPlaceDetails.value = marketPlace;
      $("#addEditMarketplaceModal").modal("show");
    }


    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#exportSettings")) {
        $("#exportSettings").DataTable().clear().destroy();
      }
      $("#exportSettings").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false }
        ]
      });
    }

    const loadMarketPlaces = async () => {
      await axios
          .get(axios.defaults.baseUrl + "getAllMarketPlaces",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem('auth_token'),
                },
              }
          )
          .then((response) => {
            if ($.fn.DataTable.isDataTable("#exportSettings")) {
              $("#exportSettings").DataTable().clear().destroy();
            }
            marketPlaces.value = response.data;
            setTimeout(() => {
              datatableInit();
            });
          })
    }

    const handleMarketplaceUpdateRequest = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "updateMarketPlace",
          {
            marketplaceData: marketPlaceDetails.value
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
          loadMarketPlaces();
          closePopup();
        });
    }

    const handleMarketplaceAddRequest = async () => {
      await axios
        .post(
          axios.defaults.baseUrl + "addMarketPlace",
          {
            marketplaceData: marketPlaceDetails.value
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
           window.toast.fire({
            icon: response.data.status,
            title: response.data.message,
          });
          loadMarketPlaces();
          closePopup();
        });
    }

    const openAddMarketPlaceModal = () => {
      marketPlaceDetails.value.market_place_name = '';
      $("#addEditMarketplaceModal").modal("show");
    };

    const closePopup = () => {
      marketPlaceDetails.value.market_place_name = '';
      $("#addEditMarketplaceModal").modal("hide");
    };

    onMounted(() => {
      datatableInit();
      loadMarketPlaces();
    })
    return { marketPlaces, loadMarketPlaces, closePopup, marketPlaceDetails, editMarketPlace, editMode, handleMarketplaceUpdateRequest, handleMarketplaceAddRequest, openAddMarketPlaceModal };
  }
};
</script>
