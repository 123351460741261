import { ref } from 'vue';
import axios from 'axios';
const getVehicleAutoSize = () => {
    var vehicleAutoSize = ref([]);
    const AutoSizeError = ref('');
    try {
        const loadVehicleAutoSize = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getVehicleAutoSize",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    vehicleAutoSize.value = response.data;
                })
                .catch((AutoSizeError) => {
                    AutoSizeError.value = 'No Vehicle Auto Size found';
                });
        }
        return { vehicleAutoSize, AutoSizeError, loadVehicleAutoSize };
    } catch (AutoSizeError) {
        AutoSizeError.value = 'No Vehicle Auto Size found';
    }
}
export default getVehicleAutoSize;