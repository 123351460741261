<template>
  <Dashboard>
    <VehiclesCountCards />
    <DashboardInsightCharts />
    <!-- main-area -->
    <!-- count-cars -->
    <div class="count-cars ads">
      <div class="form-row">
        <div class="col-6 col-sm-6 col-md-4 col-lg-3">
          <router-link
            v-if="dealerInfo.analytics_view_id != '' && dealerInfo.analytics_view_id !== null"
            class="car-counter"
            :to="{ name: 'Channels' }"
          >
            <div class="car-number">
              <img class="img-fluid" src="@/assets/img/Analytics.png" alt="" />
            </div>
            <div class="cars-text">
              <p>Google Analytics</p>
            </div>
          </router-link>
          <a href="javascript:;" v-else class="car-counter" @click="openModal">
            <div class="car-number">
              <img class="img-fluid" src="@/assets/img/Analytics.png" alt="" />
            </div>
            <div class="cars-text">
              <p>Google Analytics</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3">
          <router-link
            v-if="dealerInfo.google_ads_account_id != '' && dealerInfo.google_ads_account_id !== null"
            class="car-counter"
            :to="{ name: 'GoogleCampaigns' }"
          >
            <div class="car-number">
              <img class="img-fluid" src="@/assets/img/ads.png" alt="" />
            </div>
            <div class="cars-text">
              <p>Google Ads</p>
            </div>
          </router-link>
          <a v-else class="car-counter" href="javascript:;" @click="openModal">
            <div class="car-number">
              <img class="img-fluid" src="@/assets/img/ads.png" alt="" />
            </div>
            <div class="cars-text">
              <p>Google Ads</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3">
          <router-link
            v-if="dealerInfo.facebook_ad_account_id != '' && dealerInfo.facebook_ad_account_id !== null"
            class="car-counter"
            :to="{ name: 'FacebookCampaigns' }"
          >
            <div class="car-number">
              <img
                class="img-fluid"
                src="@/assets/img/Facebook-ads.png"
                alt=""
              />
            </div>
            <div class="cars-text">
              <p>Facebook Ads</p>
            </div>
          </router-link>
          <a v-else class="car-counter" href="javascript:;" @click="openModal">
            <div class="car-number">
              <img
                class="img-fluid"
                src="@/assets/img/Facebook-ads.png"
                alt=""
              />
            </div>
            <div class="cars-text">
              <p>Facebook Ads</p>
            </div>
          </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3">
          <router-link
            v-if="dealerInfo.gmb_refresh_token != '' && dealerInfo.facebook_ad_account_id !== null"
            class="car-counter"
            :to="{ name: 'GoogleMyBusiness' }"
          >
            <div class="car-number">
              <img
                class="img-fluid"
                src="@/assets/img/my-Business.png"
                alt=""
              />
            </div>
            <div class="cars-text">
              <p>Google My Business</p>
            </div>
          </router-link>
          <a v-else class="car-counter" href="javascript:;" @click="openModal">
            <div class="car-number">
              <img
                class="img-fluid"
                src="@/assets/img/my-Business.png"
                alt=""
              />
            </div>
            <div class="cars-text">
              <p>Google My Business</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- count-cars -->
    <!-- Feature not available modal -->
    <div
      class="modal fade"
      id="featureNotAvailable"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Attention</h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p style="margin-bottom: 20px">
              Please Contact
              <span style="font-weight: 700; color: #3151a1">DealersGear</span>
              To Activate This Feature
            </p>
            <p style="margin-bottom: 5px">
              <span style="font-weight: 700; color: black">Email : </span
              ><a style="color: #3151a1" href="mailto:support@dealersgear.com"
                >support@dealersgear.com</a
              >
            </p>
            <p>
              <span style="font-weight: 700; color: black">Phone : </span>
              <span style="color: #3151a1">(949) 923-0190</span>
            </p>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="button" @click="closeModal" class="send-btn">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Feature not available modal -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import Dashboard from "./../../layouts/Dashboard.vue";
import VehiclesCountCards from "./../../components/Inventory/VehiclesCountCards.vue";
import DashboardInsightCharts from "./../../components/Dashboard/DashboardInsightCharts.vue";
import getDealerOtherInfo from "../../composeables/getDealerOtherInfo";
import { onMounted } from "vue";
export default {
  components: {
    Dashboard,
    VehiclesCountCards,
    DashboardInsightCharts,
  },
  setup() {
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };

    // Get Dealer Information:
    const { dealerInfo, dealer, dealerInfoError, loadDealerInfo } =
      getDealerOtherInfo();
    loadDealerInfo();

    onMounted(() => {
      window.showHideMainLoader(false);
    });

    return {
      DashboardInsightCharts,
      dealerInfo,
      dealer,
      dealerInfoError,
      openModal,
      closeModal,
    };
  },
};
</script>
<style>
#printLead {
  display: none;
}
@media print {
  #printLead {
    display: block;
  }
  .main-area {
    display: none;
  }
  #leadDetailsContainer {
    display: block;
  }
  .mytable .table td:first-child {
    font-weight: 500;
  }
  .mytable .table td,
  .mytable .table th {
    padding: 8px 0;
    vertical-align: middle;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #e6e7e8;
    font-size: 14px;
    line-height: 1.5;
    color: #63729a;
  }
  .mytable .table td,
  .mytable .table th {
    padding: 8px 0;
    vertical-align: middle;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #e6e7e8;
    font-size: 14px;
    line-height: 1.5;
    color: #63729a;
  }
  span.dot {
    display: inline-block;
    height: 4px;
    width: 4px;
    background: #63729a;
    border-radius: 50%;
    margin-bottom: 1px;
    margin-right: 5px;
  }
}
</style>