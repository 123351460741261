import { ref } from 'vue';
import axios from 'axios';
const getCmsPages = () => {
    const cmspages = ref([]);
    const cmsPagesError = ref('');
    try {
        const loadCmsPages = async () => {
            await axios
                .get(axios.defaults.baseUrl + "getCmsPages",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    cmspages.value = response.data;
                })
                .catch((cmsPagesError) => {
                    cmsPagesError.value = 'No Department Types found';
                });
        }
        return { cmspages, cmsPagesError, loadCmsPages };
    } catch (cmsPagesError) {
        cmsPagesError.value = 'No Department Types found';
    }
}
export default getCmsPages;