<template>
  <div class="dealership-form">
    <form class="myform">
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Tracking Number</label>
            <input
              readonly
              v-if="editMode"
              id="trackingNumberFromDB"
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
            />
            <SimpleTypeahead
              v-else
              placeholder="Enter area code e.g 909"
              id="typeahead_id"
              :items="searchedTrackingNumbers"
              :minInputLength="3"
              @selectItem="selectedTrackingNumber"
              @onInput="searchTrackingNumber"
            >
            </SimpleTypeahead>
          </div>
          <div class="form-group col-md-6">
            <label>Redirect to</label>
            <input
              type="text"
              class="form-control"
              placeholder="(xxx) xxx-xxxx"
              v-model="redirectTo"
            />
          </div>
          <div class="form-group col-md-6" v-if="editMode">
            <label>Send Email</label>
            <select
              id="sendEmail"
              class="form-control select2 tags2"
              v-model="sendEmail"
              required
            >
            <option value="yes">Yes</option>
            <option value="no">No</option>
            </select>
          </div>
          <div class="form-group col-md-6" v-if="editMode">
            <label>Recording Consent</label>
            <select
              id="recordingConsent"
              class="form-control select2 tags2"
              v-model="recordingConsent"
              required
            >
            <option value="1">Yes</option>
            <option value="0">No</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="cus-btn text-right">
          <router-link
            class="cancle-btn text-decoration-none"
            :to="{ name: 'ManageDealers' }"
            >Back</router-link
          >
          <button type="button" class="send-btn" v-if="editMode" @click="updateTrackingNumber">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>
    <script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  components: {
    SimpleTypeahead,
  },
  setup() {
    const route = useRoute();
    const dealerId = route.params.id;
    const type = route.params.type.charAt(0).toUpperCase() + route.params.type.slice(1);
    let searchedTrackingNumbers = ref([]);
    let trackingNumberFromDB = ref("");
    let redirectTo = ref("");
    let sendEmail = ref("yes");
    let recordingConsent = ref(1);
    let numberId = ref("");
    let editMode = ref(false);

    const searchTrackingNumber = async (number) => {
      if(number.input.length >= 3) {
        number.dealerId = dealerId;
        window.showHideMainLoader(true);
        await axios
          .post(axios.defaults.baseUrl + "searchTwilioTrackingNumber", number, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          })
          .then((response) => {
            if(response.data.status == 'success') {
              searchedTrackingNumbers.value = response.data.data;
            }else {
              window.toast.fire({
                icon: "warning",
                title: response.data.data
              });
            }
          })
          .catch((error) => {
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again.",
              });
            }
          });
        window.showHideMainLoader(false);
      }
    };

    const selectedTrackingNumber = async (selectedNumber) => {
      window.showHideMainLoader(true);
      let redirectToNumber = redirectTo.value;
      await axios
        .post(axios.defaults.baseUrl + "buyTwilioTrackingNumber", {
            selectedNumber,
            type,
            dealerId,
            redirectToNumber
          }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          console.log(response);
            window.toast.fire({
              icon: "success",
              title: "Tracking number added successfully",
            });
            getTwilioTrackingNumber();
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    }

    const getTwilioTrackingNumber = async () => {
      window.showHideMainLoader(true);
      await axios
        .post(axios.defaults.baseUrl + "getTwilioTrackingNumber", {
            type,
            dealerId
          }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if(response.data.status == 'success') {
            editMode.value = true;
            numberId.value = response.data.data.id;
            redirectTo.value = response.data.data.redirect_to;
            sendEmail.value = response.data.data.send_email;
            recordingConsent.value = response.data.data.recording_consent;
            trackingNumberFromDB.value = response.data.data.twilio_phone_friendly;
            setTimeout(() => {
              $("#trackingNumberFromDB").val(response.data.data.twilio_phone_friendly);
            });
            $("#sendEmail")
                .select2()
                .val(sendEmail.value)
                .trigger("change");
            $("#recordingConsent")
                .select2()
                .val( recordingConsent.value)
                .trigger("change");
          }else {
            editMode.value = false;
          }
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    }

    const updateTrackingNumber = async () => {
      window.showHideMainLoader(true);
      let redirectToNumber = redirectTo.value;
      let sendEmailCheck = sendEmail.value;
      let recordingConsentCheck = recordingConsent.value;
      let id = numberId.value;
      await axios
        .post(axios.defaults.baseUrl + "updateTrackingNumber", {
            redirectToNumber,
            type,
            dealerId,
            sendEmailCheck,
            id,
            recordingConsentCheck
          }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          console.log(response);
            window.toast.fire({
              icon: "success",
              title: "Tracking number updated successfully",
            });
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    }

    onMounted(() => {
      getTwilioTrackingNumber();
      $(".select2").select2({
        placeholder: "Select",
        allowClear: true,
      });
    });
    return {
      searchTrackingNumber,
      searchedTrackingNumbers,
      selectedTrackingNumber,
      redirectTo,
      sendEmail,
      recordingConsent,
      editMode,
      updateTrackingNumber
    };
  },
};
</script>