<template>
  <Dashboard>
    <section>
      <!-- inventory-header -->
      <div class="inventory-header lead-header">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="leads-btn">
              <div class="btns-group">
                <a class="cus-btn" href="javascript:" @click="resetFilters"
                >Reset</a
                >
              </div>
              <div class="search-box">
                <input
                    class="reportrange form-control daterangebackground"
                    type="text"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-6">
            <div class="vehicle-area">
              <div class="search-box">
                <input
                    v-model="keyword"
                    class="form-control"
                    placeholder="Search Leads"
                    type="search"
                    @keyup="getLeads"
                />
                <img
                    alt=""
                    class="img-fluid search-icon"
                    src="@/assets/img/search.svg"
                />
              </div>
              <div class="export">
                <a class="export-btn" href="javascript:" @click="exportLeads"
                ><img
                    alt=""
                    class="img-fluid"
                    src="@/assets/img/file-excel-solid.svg"
                />
                  Export</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- inventory-header -->

      <!-- leads-table -->
      <div class="table-area">
        <table id="leadsTable" class="display bottommargin5">
          <thead>
          <tr>
            <th style="width: 200px">Form Name</th>
            <th class="all">Date / Time</th>
            <th class="">Assign To</th>
            <th class="all">Status</th>
            <th class="">Note</th>
            <th class=" " style="width: 80px">action</th>
          </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <!-- leads-table -->
      <!-- Webiste Leads details modal -->
      <div
          v-if="showPopup"
          id="websiteLeadsModal"
          aria-hidden="true"
          aria-labelledby="staticBackdropLabel"
          class="modal fade"
          tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="staticBackdropLabel" class="modal-title">Lead Details</h5>
              <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="leadDetailsContainer" class="mytable">
                <table class="table">
                  <tbody>
                  <tr>
                    <td>Lead Date</td>
                    <td>
                      <span class="dot"></span>
                      {{ LeadDetail.createdTime }}
                    </td>
                  </tr>
                  <tr>
                    <td>Form Name</td>
                    <td>
                      <span class="dot"></span>
                      {{ LeadDetail.form_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>Campaign Name</td>
                    <td>
                      <span class="dot"></span>
                      {{ LeadDetail.campaign_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>Ad Name</td>
                    <td>
                      <span class="dot"></span>
                      {{ LeadDetail.ad_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>Page Id</td>
                    <td>
                      <span class="dot"></span>
                      {{ LeadDetail.page_id }}
                    </td>
                  </tr>
                  <tr
                      v-for="(value, name, index) in LeadDetail.otherData"
                      :key="index"
                  >
                    <td>{{ name }}</td>
                    <td>
                      <span class="dot"></span>
                      {{ value }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <div class="cus-btn text-right">
                <button class="cancle-btn" type="submit" @click="printLead">
                  Print
                </button>
                <button class="send-btn" data-dismiss="modal" type="submit">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Website Leads details modal -->


      <!-- Assign to  modal -->
      <div
           id="AssignToModal"
          aria-hidden="true"
          aria-labelledby="AssignToModalLabel"
          class="modal select-modal fade"
          tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">

              <h5 id="AssignToModalLabel" class="modal-title">
                Assign to
              </h5>
              <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form class="myform">
              <div class="modal-body">
                <div class="form-row">


                  <div id="userType" class="form-group col-md-12">
                    <label>Assign To</label>
                    <select
                        v-model="assignedToId"
                        class="form-control    "
                    >
                      <option
                          v-for="user in users"
                          :key="user.id"
                          :value="user.id"
                      >
                        {{ user.first_name }} {{ user.last_name }}
                      </option>
                    </select>
                  </div>

                </div>
              </div>
              <div class="modal-footer">
                <div class="cus-btn text-right">
                  <button class="cancle-btn" data-dismiss="modal" type="button">
                    Close
                  </button>

                  <button

                      class="send-btn"
                      type="button"
                      @click.prevent="handelAssignLeads"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Assign to modal -->


      <!-- Update  Status  modal -->
      <div
           id="statusModal"
          aria-hidden="true"
          aria-labelledby="statusModalLabel"
          class="modal select-modal fade"
          tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">

              <h5 id="statusModalLabel" class="modal-title">
                Update Status
              </h5>
              <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form class="myform">
              <div class="modal-body">
                <div class="form-row">


                  <div id="status" class="form-group col-md-12">
                    <label>Status</label>
                    <select
                        v-model="leadStatusValue"
                        class="form-control    "
                    >
                      <option
                          v-for="(status,index) in [

                              'Active','Contacted','Scheduled','Sold','Lost','Other',
                          ]"
                          :key="index"
                          :value="status.toLowerCase()"
                      >
                        {{ status }}
                      </option>
                    </select>
                  </div>

                  <div id="note" class="form-group col-md-12">
                    <label>Note</label>
                    <textarea v-model="leadStatusNote" class="form-control"></textarea>
                  </div>

                </div>
              </div>
              <div class="modal-footer">
                <div class="cus-btn text-right">
                  <button class="cancle-btn" data-dismiss="modal" type="button">
                    Close
                  </button>

                  <button

                      class="send-btn"
                      type="button"
                      @click.prevent="handelUpdateStatus"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Update  Status  modal -->
    </section>
  </Dashboard>
</template>
<script>
import $ from "jquery";
import {onMounted, ref} from "vue";
import axios from "axios";
import "daterangepicker";
import Dashboard from "./../../../layouts/Dashboard.vue";
import moment from "moment";
import getUsers from "@/composeables/getUsers";

export default {
  components: {
    Dashboard,
  },
  setup() {
    let keyword = ref("");
    let startDate = ref(moment().subtract(29, "days"));
    let endDate = ref(moment());
    let assignedToId = ref(0);
    let assignedToLeadId = ref(0);
    let showAssignPopup = ref(false);


    let leadStatusValue = ref("");
    let leadStatusNote = ref("");
    let leadStatusId = ref(0);
    let showLeadStatusPopup = ref(false);

    const {loadUsers, users} = getUsers();
    loadUsers();
    $(document).on("click", ".viewLeadDetails", function () {
      openLeadDetailsModal($(this).data("id"));
    });
    $(document).on("click", ".assignedToModel", function () {

      assignedToId.value = $(this).data("assignedtoid");
      assignedToLeadId.value = $(this).data("id");
       $("#AssignToModal").modal("show");
    });


    $(document).on("click", ".updateStatusBtn", function () {
      console.log('111')
      leadStatusValue.value = $(this).data("status");
      leadStatusNote.value = $(this).data("note");
      leadStatusId.value = $(this).data("id");
       $("#statusModal").modal("show");
    });


    const printLead = () => {
      $("#printLead").remove();
      const modal = document.getElementById("leadDetailsContainer");
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("printLead");

      if (!section) {
        section = document.createElement("div");
        section.id = "printLead";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      // Small delay of 0.5s
      window.moveTo(0, 0);
      window.resizeTo(640, 480);
      setTimeout(() => {
        window.print();
      });
    };

    // Website Leads Details Modal Open
    const openLeadDetailsModal = (id) => {
      $(".loaders").show();
      axios
          .post(
              axios.defaults.baseUrl + "getFacebookLeadDetail",
              {
                id,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            $(".loaders").hide();
            LeadDetail.value = response.data;
            showPopup.value = true;
            setTimeout(() => {
              $("#websiteLeadsModal").modal("show");
            });
          })
          .catch((error) => {
            error.value = "No Lead Data found";
          });
    };

    const cb = (start, end) => {
      startDate.value = start.format("YYYY-MM-DD");
      endDate.value = end.format("YYYY-MM-DD");
      getLeads();
      $(".reportrange span").html(
          start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    };

    const resetFilters = () => {
      startDate.value = moment().subtract(29, "days");
      endDate.value = moment();
      dateRangePickerInit();
    };
    let LeadDetail = ref(null);
    let showPopup = ref(false);

    const getLeads = async () => {
      if ($.fn.DataTable.isDataTable("#leadsTable")) {
        $("#leadsTable").DataTable().clear().destroy();
      }
      await $("#leadsTable")
          .addClass("nowrap")
          .dataTable({
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            dom: "rtiplf",
            searching: false,
            bInfo: false,
            pageLength: 50,
            ajax: {
              url: axios.defaults.baseUrl + "getFacebookLeads",
              data: function (d) {
                d.search["value"] = keyword.value;
                d.fromDate = startDate.value;
                d.toDate = endDate.value;
              },
              type: "post",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            },
            columns: [
              {data: "form_name"},
              {data: "created_date"},
              {data: "assigned_to_name"},
              {data: "status"},
              {data: "note"},
              {data: "actions"},
            ],
            order: [],
            columnDefs: [{targets: [2, 5], orderable: false}],
          });
    };

    const dateRangePickerInit = async () => {
      $(".reportrange").daterangepicker(
          {
            startDate: startDate.value,
            endDate: endDate.value,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          cb
      );
      cb(startDate.value, endDate.value);
    };

    const exportLeads = async () => {
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "exportFacebookLeads",
              {
                responseType: "arraybuffer",
                fromDate: startDate.value,
                toDate: endDate.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if (response.data.status != "error") {
              window.showHideMainLoader(false);
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", "leads.csv");
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          });
      window.showHideMainLoader(false);
    };

    onMounted(() => {
      dateRangePickerInit();
    });


    const handelAssignLeads = async () => {
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "assignFacebookLead",
              {
                lead_id: assignedToLeadId.value,
                assigned_to_id: assignedToId.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if (!response.data.success) {
              window.showHideMainLoader(false);

              if (response.data.message.lead_id) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.lead_id[0],
                });
              }
              if (response.data.message.assigned_to_id) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.assigned_to_id[0],
                });
              }


            } else {

              $("#leadsTable").DataTable().ajax.reload();
              window.showHideMainLoader(false);

              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });

              $("#AssignToModal").modal("hide");


            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again",
              });
            }
          });
    }
    const handelUpdateStatus = async () => {
      window.showHideMainLoader(true);
      await axios
          .post(
              axios.defaults.baseUrl + "updateStatusFacebookLead",
              {
                lead_id: leadStatusId.value,
                note: leadStatusNote.value,
                status: leadStatusValue.value,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              }
          )
          .then((response) => {
            if (!response.data.success) {
              window.showHideMainLoader(false);

              if (response.data.message.lead_id) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.lead_id[0],
                });
              }
              if (response.data.message.assigned_to_id) {
                window.toast.fire({
                  icon: "error",
                  title: response.data.message.assigned_to_id[0],
                });
              }


            } else {

              $("#leadsTable").DataTable().ajax.reload();
              window.showHideMainLoader(false);

              window.toast.fire({
                icon: "success",
                title: response.data.message,
              });

              $("#statusModal").modal("hide");


            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            if (error) {
              window.toast.fire({
                icon: "error",
                title: "Something went wrong, Please try again",
              });
            }
          });
    }
    return {
      keyword,
      LeadDetail,
      getLeads,
      showPopup,
      resetFilters,
      printLead,
      exportLeads,
      assignedToId,
      assignedToLeadId,
      handelAssignLeads,
      users,
      showAssignPopup,

      leadStatusValue,
      leadStatusId,
      showLeadStatusPopup,
      leadStatusNote,
      handelUpdateStatus,
    };
  },
};
</script>