<template>
  <div>
    <!-- loader -->
    <div class="loaders">
      <div class="loader"></div>
    </div>
    <!-- loader -->
    <!-- login-area -->
    <section class="login-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-lg-7">
            <div class="row">
              <div class="login-left"></div>
            </div>
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="row">
              <div class="login-right">
                <div class="login">
                  <div
                    class="login-logo wow fadeInRight"
                    ata-wow-duration="1s"
                    data-wow-delay=".5s"
                  >
                    <img
                      class="img-fluid loginlogoimg"
                      src="@/assets/img/logo.svg"
                      alt="logo"
                    />
                    <h2>Log Into Your Account</h2>
                  </div>
                  <form
                    @submit.prevent="login"
                    class="wow fadeInRight"
                    ata-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label>
                          <p class="label-txt label-active">Email Address</p>
                          <img
                            class="img-fluid log-icon"
                            src="@/assets/img/Account.svg"
                            alt=""
                          />
                          <input
                            type="email"
                            v-model="form.email"
                            class="input input-active"
                            required
                          />
                        </label>
                      </div>
                      <div class="form-group col-md-12">
                        <label>
                          <p class="label-txt label-active">Password</p>
                          <img
                            class="img-fluid log-icon"
                            src="@/assets/img/lock.svg"
                            alt=""
                          />
                          <input
                            v-bind:type="pType"
                            v-model="form.password"
                            class="input input-active"
                            required
                          />
                          <img
                            class="img-fluid show-eye"
                            src="@/assets/img/Eye.svg"
                            alt=""
                            @click="showPass"
                          />
                          <img
                            class="img-fluid hide-eye"
                            src="@/assets/img/eyeclose.svg"
                            alt=""
                            @click="hidePass"
                            style="display: none"
                          />
                        </label>
                      </div>
                      <!-- <div class="form-group col-md-12">
                      <label class="checks">
                        <input type="checkbox" /> Remember Me
                        <span class="checkmark"></span>
                      </label>
                      <a class="forgot" href="#">Forgot Password?</a>
                    </div> -->
                    </div>
                    <button type="submit" class="send-btn">Log In</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- login-area -->
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import { WOW } from "wowjs";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        dealer: this.$route.query.dealer
      },
      pType: "password",
      errorMessage: "",
    };
  },
  methods: {
    login() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "login", this.form)
        .then((response) => {
              if (!response.data.success) {
                if (response.data.message.email) {
                  window.toast.fire({
                    icon: "error",
                    title: response.data.message.email[0],
                  });
                }else if (response.data.message.password) {
                  window.toast.fire({
                    icon: "error",
                    title: response.data.message.password[0],
                  });
                }else {
                  window.toast.fire({
                    icon: "error",
                    title: response.data.message,
                  });
                }
              }else {
                localStorage.setItem("auth_token", response.data.access_token);
                localStorage.setItem("user_id", response.data.user.id);
                localStorage.setItem("first_name", response.data.user.first_name);
                localStorage.setItem("last_name", response.data.user.last_name);
                localStorage.setItem("permissions", response.data.user.permissions);
                
                  localStorage.setItem("user_type", response.data.user.user_main_role);
                if(response.data.user.user_main_role == 'dealer') {
                  localStorage.setItem("dealer_id", response.data.dealer.id);
                  window.location.href = '/dashboard';
                  //this.$router.push("/dashboard");
                }else {
                  localStorage.setItem("dealer_id", null);
                  window.location.href = '/dashboard/manage-dealers'; 
                  //this.$router.push('/dashboard/manage-dealers');
                }
              }
        })
        .catch(() => {
          localStorage.removeItem("auth_token");
          window.location.href = '/?'+ new Date().getTime();
          window.toast.fire({
            icon: "error",
            title: "Something went wrong, Please try again",
          });
        });
    },
    showPass() {
      this.pType = "text";
      $(".hide-eye").show();
      $(".show-eye").hide();
    },
    hidePass() {
      this.pType = "password";
      $(".hide-eye").hide();
      $(".show-eye").show();
    },
  },
  mounted() {
    if(this.$route.query.superLogin == 'yes') {
      this.login();
    }
    // Loader Fade Out
    $(".loaders").fadeOut();
    //   //wow js
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
      scrollContainer: null,
      resetAnimation: true,
    });
    wow.init();
    // Login Fields Focus Effect
    $(".input").focus(function () {
      $(this).parent().find(".label-txt").addClass("label-active");
      $(this).addClass("input-active");
    });
    $(".input").focusout(function () {
      if ($(this).val() == "") {
        $(this).parent().find(".label-txt").removeClass("label-active");
        $(this).removeClass("input-active");
      }
    });
    // show password
    // $(".hide-eye").hide();
    // $(".show-eye").click(function () {
    //   $(".hide-eye").show();
    //   $(".show-eye").hide();
    // });
    // $(".hide-eye").click(function () {
    //   $(".hide-eye").hide();
    //   $(".show-eye").show();
    // });
    // Check if user is login or not;
    if(this.$route.query.superLogin != 'yes') {
      if (localStorage.getItem("auth_token")) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/");
      }
    }else {
      this.$router.push("/");
    }
    
  },
};
</script>
