<template>
  <Dashboard>
    <!-- menu-builder -->
    <div class="menu-builder">
      <div class="row">
        <div class="col-md-6">
          <div class="menu-text">
            <h4>Menu Builder</h4>
            <p>
              All changes are saved immediately and reflected on the site live.
              Please use this tool with caution. Following are Some Default URLS
            </p>

            <h5>
              Used Inventory: <span>{{ websiteRoutes.used }}</span>
            </h5>
            <h5>
              New Inventory: <span>{{ websiteRoutes.new }}</span>
            </h5>
            <h5>
              Pre Approved:
              <span>{{ websiteRoutes.secure_credit_application }}</span>
            </h5>
            <h5>
              Class name for button in menu: <span>button-active-bg</span>
            </h5>
          </div>
        </div>

        <div class="col-md-6">
          <div class="menu-text">
            <a class="cus-btn" href="javascript:;" @click="openAddModal"
              ><img
                class="img-fluid"
                src="@/assets/img/plus-solid.svg"
                alt=""
              />
              Add New Link</a
            >
            <Tree :value="treeData" @change="ondragend">
              <template v-slot="{ node, path, tree }">
                <p>
                  <b @click="tree.toggleFold(node, path)">
                    {{ node.$folded ? "+" : "-" }}
                  </b>
                  {{ node.label }}
                </p>
                <p>
                  <span class="editmenu"
                    ><img
                      class="img-fluid"
                      src="@/assets/img/edit-white.svg"
                      alt=""
                    /><a
                      href="javascript:;"
                      class="clickablecontent"
                      @click="editMenu(node)"
                      >&nbsp;</a
                    ></span
                  >
                  <span class="deletemenu"
                    ><img
                      class="img-fluid"
                      src="@/assets/img/trash-white.svg"
                      alt=""
                    /><a
                      href="javascript:;"
                      class="clickablecontent"
                      @click="deleteMenu(node)"
                      >&nbsp;</a
                    ></span
                  >
                </p>
              </template>
            </Tree>
          </div>
        </div>
      </div>
    </div>

    <!-- add edit menu modal -->
    <div
      class="modal select-modal fade"
      id="menuModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="staticBackdropLabel">
              Edit Menu Item
            </h5>
            <h5 v-else class="modal-title" id="staticBackdropLabel">
              Add Menu Item
            </h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="myform">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Label</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="menuItem.label"
                    required
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Url</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="menuItem.uri"
                    required
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>Heading: (H1)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="menuItem.heading"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Class Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="menuItem.class_name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Or Select page</label>
                  <select
                    class="form-control tags1"
                    v-model="menuItem.pages"
                    id="selectPage"
                  >
                    <option value="">None</option>
                    <option
                      v-for="page in cmspages"
                      :key="page.id"
                      :value="page.id"
                      :data-label="page.label"
                      :data-url="page.slug"
                      :data-metatitle="page.meta_title"
                      :data-metadescription="page.meta_description"
                    >
                      {{ page.label }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label>Show under</label>
                  <select
                    class="form-control tags1"
                    v-model="menuItem.parent_id"
                  >
                    <option value="0">None</option>
                    <option
                      v-for="menu in treeData"
                      :key="menu.id"
                      :value="menu.id"
                    >
                      {{ menu.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label>Target</label>
                  <select class="form-control tags1" v-model="menuItem.target">
                    <option value="">None (Same Window)</option>
                    <option value="_blank">New Window</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label>Order number</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    v-model="menuItem.order_number"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Meta title</label>
                  <input
                    type="text"
                    class="form-control"
                    required
                    maxlength="70"
                    placeholder=""
                    v-model="menuItem.meta_title"
                  />
                  <small
                    >Maximum 70 characters. Please do not use any special
                    characters</small
                  >
                </div>
                <div class="form-group col-md-12">
                  <label>Meta description</label>
                  <textarea
                    class="form-control"
                    required
                    maxlength="300"
                    placeholder=""
                    rows="3"
                    v-model="menuItem.meta_description"
                  >
                  </textarea>
                  <small
                    >Maximum 300 characters. Please do not use any special
                    characters</small
                  >
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button
                v-if="editMode"
                type="button"
                class="send-btn"
                @click="updateMenuItem"
              >
                Save
              </button>
              <button
                v-else
                type="button"
                class="send-btn"
                @click="addMenuItem"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add edit menu modal -->
    <!-- delete menu Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div
              class="
                delete-modal
                d-flex
                flex-columns
                justify-content-center
                align-items-center
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewbox="0 0 36 36"
                fill="none"
                class="d-block"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 3C10.1074 3 9.70605 3.1377 9.42188 3.42188C9.1377 3.70605 9 4.10742 9 4.5V5.25H3.75V6.75H4.57031L6 20.3203L6.07031 21H17.9297L18 20.3203L19.4297 6.75H20.25V5.25H15V4.5C15 4.10742 14.8623 3.70605 14.5781 3.42188C14.2939 3.1377 13.8926 3 13.5 3H10.5ZM10.5 4.5H13.5V5.25H10.5V4.5ZM6.09375 6.75H17.9062L16.5703 19.5H7.42969L6.09375 6.75ZM9 9V17.25H10.5V9H9ZM11.25 9V17.25H12.75V9H11.25ZM13.5 9V17.25H15V9H13.5Z"
                  fill="#2A4385"
                />
              </svg>
              <h2>Are you sure you want to delete this Menu item?</h2>
            </div>
          </div>
          <div class="modal-footer">
            <div class="cus-btn text-right">
              <button type="submit" @click="closeModal" class="cancle-btn">
                Cancel
              </button>
              <button type="button" class="send-btn" @click="deleteMenuItem">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete menu Modal -->
    <!-- menu-builder -->
  </Dashboard>
</template>
<script>
import $ from "jquery";
import "he-tree-vue/dist/he-tree-vue.css";
import { Tree, Fold, Draggable } from "he-tree-vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import Dashboard from "./../../layouts/Dashboard.vue";
import getCmsPages from "./../../composeables/getCmsPages";
export default {
  components: {
    Dashboard,
    Tree: Tree.mixPlugins([Fold, Draggable]),
  },
  setup() {
    let treeData = ref([]);
    let menuItem = ref({});
    let websiteRoutes = ref({});
    let editMode = ref(false);

    const closeModal = () => {
      menuItem.value = {};
      editMode.value = false;
      $("#menuModal").modal("hide");
      $("#deleteModal").modal("hide");
    };

    const openAddModal = () => {
      menuItem.value = {};
      editMode.value = false;
      $("#menuModal").modal("show");
    };

    const { cmspages, cmsPagesError, loadCmsPages } = getCmsPages();
    loadCmsPages();

    const ondragend = async () => {
      let rearrangedMenuItems = treeData.value;
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "rearrangeWebsiteMenu",
          rearrangedMenuItems,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          window.toast.fire({
            icon: "success",
            title: "Menu item updated successfully",
          });
          getWebsiteMenu();
          closeModal();
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const getWebsiteMenu = async () => {
      window.showHideMainLoader(true);
      await axios
        .get(axios.defaults.baseUrl + "getWebsiteMenu", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          treeData.value = response.data;
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const getWebsiteRoutes = async () => {
      window.showHideMainLoader(true);
      await axios
        .get(axios.defaults.baseUrl + "getDealerRoutes", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          websiteRoutes.value = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const addMenuItem = async () => {
      window.showHideMainLoader(true);
      let menuItemData = menuItem.value;
      await axios
        .post(axios.defaults.baseUrl + "addWebsiteMenu", menuItemData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          console.log(response);
          window.toast.fire({
            icon: "success",
            title: "Menu item added successfully",
          });
          getWebsiteMenu();
          closeModal();
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    const updateMenuItem = async () => {
      window.showHideMainLoader(true);
      let menuItemData = menuItem.value;
      await axios
        .post(
          axios.defaults.baseUrl + "updateWebsiteMenu/" + menuItemData.id,
          menuItemData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          window.toast.fire({
            icon: "success",
            title: "Menu item updated successfully",
          });
          getWebsiteMenu();
          closeModal();
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    $(document).on("change", "#selectPage", function () {
      if (menuItem.value.pages != "") {
        menuItem.value.label = $(this).find(":selected").attr("data-label");
        menuItem.value.uri = $(this).find(":selected").attr("data-url");
        menuItem.value.meta_title = $(this)
          .find(":selected")
          .attr("data-metatitle");
        menuItem.value.meta_description = $(this)
          .find(":selected")
          .attr("data-metadescription");
      }
    });

    const editMenu = async (menu) => {
      menuItem.value = menu;
      editMode.value = true;
      $("#menuModal").modal("show");
    };

    const deleteMenu = async (menu) => {
      menuItem.value = menu;
      $("#deleteModal").modal("show");
    };

    const deleteMenuItem = async () => {
      window.showHideMainLoader(true);
      await axios
        .delete(
          axios.defaults.baseUrl + "deleteWebsiteMenu/" + menuItem.value.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          window.toast.fire({
            icon: "success",
            title: "Menu item deleted successfully",
          });
          getWebsiteMenu();
          closeModal();
        })
        .catch((error) => {
          if (error) {
            window.toast.fire({
              icon: "error",
              title: "Something went wrong, Please try again.",
            });
          }
        });
      window.showHideMainLoader(false);
    };

    onMounted(() => {
      getWebsiteMenu();
      getWebsiteRoutes();
    });

    return {
      treeData,
      ondragend,
      editMenu,
      deleteMenu,
      cmspages,
      cmsPagesError,
      menuItem,
      editMode,
      closeModal,
      openAddModal,
      addMenuItem,
      updateMenuItem,
      deleteMenuItem,
      getWebsiteRoutes,
      websiteRoutes,
    };
  },
};
</script>